// api.js 파일

/**
 * 초대장을 저장하는 함수.
 * @param {string} dynamicValue - 동적인 값 (URL에 사용됨).
 * @param {Object} requestData - 요청에 포함될 데이터 객체.
 * @returns {Promise<Response>} Fetch 요청에 대한 응답.
 */

export function saveInvitation(dynamicValue, requestData) {
    return fetch(`/api_SaveInvitation/${dynamicValue}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(requestData)
    });
}
