// 멀티 이미지를 업로드 하는 api
// 첫번째 파라미터에는 템플릿URL 경로를 넣어줘야합니다.
function uploadImagesToServerAPI(url, formData) {
    return fetch(url, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('요청에 문제가 발생했습니다.');
            }
            return response.json();
        });
}

export default uploadImagesToServerAPI;