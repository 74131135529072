export function displayDecoration(decoration_section) {
    // 장식요소 상태값을 조회 후, display 처리
    let invitation_section_graphic = document.querySelectorAll('.invitation_section_graphic');
    console.log('상태값:', decoration_section.designDecoration_statusData);
    
    // 상태값에 따라 display 속성을 설정
    if (decoration_section.designDecoration_statusData === 0) {
        // 상태값이 0일 때, display none
        invitation_section_graphic.forEach(element => {
            element.style.display = 'none';
        });
    } else if (decoration_section.designDecoration_statusData === 1) {
        // 상태값이 1일 때, display block
        invitation_section_graphic.forEach(element => {
            element.style.display = 'block';
        });
    } else {
        // 상태값이 0 또는 1이 아닌 경우, 기본 처리 (선택 사항)
        invitation_section_graphic.forEach(element => {
            element.style.display = 'block'; // 기본값 처리
        });
    }
}
