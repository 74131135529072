import { observer, observeElements } from '../../../utils/scrollAnimation.js';

export function previewTemplates() {
    const ScrollPrevent = document.body;
    let Dimmed = document.getElementById('PreviewDimmed');
    let PreviewPageTarget = document.querySelector('.PreviewPage');
    let sideContents = document.querySelectorAll('.side_contents');
    let DeleteBtn = document.querySelector('.close-button');
    let sideContentsArray = Array.from(sideContents).sort((a, b) => {
        return a.getAttribute('data-order') - b.getAttribute('data-order');
    });

    let PreviewBtn = document.querySelector(".ProgressTemporarySave"); // 미리보기 버튼
    if (PreviewBtn) {
        PreviewBtn.addEventListener('click', function () {
            PreviewPageTarget.textContent = ''; // 기존의 자식 요소들을 제거
            sideContentsArray.forEach(function (item) {
                let cloneItem = item.cloneNode(true);

                // 뷰포트 너비에 따라 조건부 스타일 적용
                if (window.innerWidth <= 560) {
                    cloneItem.style.top = "0";
                    cloneItem.style.maxHeight = "100vh";
                    cloneItem.style.zIndex = 10003;
                } else {
                    cloneItem.style.zIndex = 10003;
                    cloneItem.style.top = "5%";
                    cloneItem.style.maxHeight = "85vh";
                }

                PreviewPageTarget.appendChild(cloneItem); // 클론된 요소를 PreviewPageTarget에 추가
            });

            Dimmed.classList.toggle('is-active');
            DeleteBtn.style.display = "block";
            ScrollPrevent.style.overflow = "hidden";

            // Intersection Observer를 사용하여 요소들을 관찰
            let fadeinParents = document.querySelectorAll('.side_contents');
            observeElements(fadeinParents);

            let fadeinElements = document.querySelectorAll('.side_contents > *');
            observeElements(fadeinElements);

            let OrderSection = document.querySelectorAll('.OrderSection > *');
            observeElements(OrderSection);
        });
    }

    function toggleElements() {
        Dimmed.classList.toggle('is-active');
        ScrollPrevent.style.overflowY = "scroll";
        DeleteBtn.style.display = "none";

        let sideTemplates = document.querySelectorAll('.side_contents');
        sideTemplates.forEach(function (item) {
            item.style = ''; // 스타일 초기화
        });

        // 클론된 요소들을 제거
        Array.from(PreviewPageTarget.querySelectorAll('.side_contents')).forEach(function (item) {
            item.remove();
        });
    }

    if (DeleteBtn) {
        DeleteBtn.addEventListener("click", toggleElements);
    }
}
