export function checkSession() {
    return new Promise((resolve, reject) => {
      console.log('세션 확인 요청을 보냅니다.');
      fetch('/api_Auth/checkSession', {
        credentials: 'include' // 쿠키를 포함하도록 설정
      })
      .then(response => {
        console.log('세션 확인 응답을 받았습니다:', response);
        return response.json();
      })
      .then(data => {
        console.log('세션 확인 데이터:', data);
        if (data.sessionExpired) {
          alert('로그인 세션이 만료되었습니다. 재로그인 후 이용해주세요.');
          window.location.href = '/api_Auth/login';
          reject('세션 만료');
        } else {
          console.log('세션이 유효합니다.');
          resolve('세션 유효');
        }
      })
      .catch(error => {
        console.error('세션 확인 중 오류가 발생했습니다:', error);
        reject(error);
      });
    });
  }
  