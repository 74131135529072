export function kakaopay_link_alert() {
    // 클래스 'kakao_link_advice'를 가진 모든 요소를 선택합니다.
    const adviceLinks = document.querySelectorAll('.kakao_link_advice');

    // 각 advice link에 클릭 이벤트 리스너를 추가합니다.
    adviceLinks.forEach(link => {
        link.addEventListener('click', () => {
            // id가 'alert_kakaoPay'인 요소를 찾아 'is-active' 클래스를 토글합니다.
            const alertElement = document.getElementById('alert_kakaoPay');
            if (alertElement) {
                alertElement.classList.toggle('is-active');
            }
        });
    });

    // id가 'CloseAlert_kakaoPay'인 요소를 가져옵니다.
    const closeAlertButton = document.getElementById('CloseAlert_kakaoPay');

    // close alert 버튼에 클릭 이벤트 리스너를 추가합니다.
    if (closeAlertButton) {
        closeAlertButton.addEventListener('click', () => {
            // id가 'alert_kakaoPay'인 요소를 찾아 'is-active' 클래스를 토글합니다.
            const alertElement = document.getElementById('alert_kakaoPay');
            if (alertElement) {
                alertElement.classList.toggle('is-active');
            }
        });
    }
}
