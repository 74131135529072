import { updateInnerText } from "./update_inner_text";

// 날짜 포맷팅을 담당하는 유틸리티 함수
function formatDate(selectedDate) {
    const year = selectedDate.getFullYear();
    const formattedMonth = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const formattedDay = String(selectedDate.getDate()).padStart(2, '0');

    // 월을 영어 약어로 변환
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthInEnglish = monthNames[selectedDate.getMonth()];

    // 요일을 영어로 변환
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = dayNames[selectedDate.getDay()];

    return { year, formattedMonth, formattedDay, monthInEnglish, dayOfWeek };
}

export function design_simple_line_01_date_update(selectedDate) {
    // 날짜 포맷팅
    const { year, formattedMonth, formattedDay } = formatDate(selectedDate);
    const formattedDate = `${year}. ${formattedMonth}. ${formattedDay}`;

    // 기존 기능: 여러 요소에 YYYY. MM. DD 포맷 적용
    updateInnerText(document.querySelectorAll(".design_graphic_des"), formattedDate);
}

export function design_simple_line_02_date_update(selectedDate) {
    // 날짜 포맷팅
    const { dayOfWeek, formattedMonth, formattedDay, monthInEnglish } = formatDate(selectedDate);
    const formattedDayOfWeek = `${dayOfWeek}, ${monthInEnglish}`;

    // 개별 요소에 각각의 날짜 값 적용
    document.getElementById("design_simple_line_02_graphic_des_year").innerText = formattedDayOfWeek; // 요일로 변경
    document.getElementById("design_simple_line_02_graphic_des_month").innerText = formattedMonth;
    document.getElementById("design_simple_line_02_graphic_des_date").innerText = formattedDay;
}

export function design_simple_line_03_date_update(selectedDate) {
    // 날짜 포맷팅
    const { year, formattedMonth, formattedDay, monthInEnglish } = formatDate(selectedDate);

    // 새로운 기능: 개별 요소에 각각의 날짜 값 적용
    document.getElementById("design_simple_line_03_graphic_des_year").innerText = `${year}\n${monthInEnglish}`; // year와 month를 함께 업데이트
    document.getElementById("design_simple_line_03_graphic_des_month").innerText = formattedMonth;
    document.getElementById("design_simple_line_03_graphic_des_date").innerText = formattedDay;
}
