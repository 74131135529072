export function gallerySwiper(toggleSwitch_gallery_zoom_status) {
    const currentUrl = window.location.href;
    const pathArray = currentUrl.split('/');
    const disableMainUrl = `${pathArray[0]}//${pathArray[2]}/`; // 동작하지 않아야 할 URL
    const disableLoginUrl = `${pathArray[0]}//${pathArray[2]}/api_Auth/login`; // 동작하지 않아야 할 URL
    const disableMyPageUrl = `${pathArray[0]}//${pathArray[2]}/api_Mypage/${pathArray[4]}`; // 동작하지 않아야 할 URL

    if (currentUrl === disableMainUrl || currentUrl === disableLoginUrl || currentUrl === disableMyPageUrl) {
        return;
    }

    let thumbnailsContainers = document.querySelectorAll('.grid-container');
    let imageOverlay = document.getElementById("GalleryPreview");
    let closeButton = document.getElementById("GalleryPreviewExit");
    let swiperContainer = document.querySelector('.swiper-container');
    let swiperWrapper = swiperContainer.querySelector('.swiper-wrapper');

    var swiper = new Swiper(swiperContainer, {
        slidesPerView: 1,
        spaceBetween: 48,
        zoom: toggleSwitch_gallery_zoom_status === 0 ? {
            maxRatio: 3,
            minRatio: 1,
            toggle: true
        } : false, // 줌 기능 조건적으로 설정
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        },
        touchEventsTarget: 'container',
    });

    thumbnailsContainers.forEach(container => {
        container.addEventListener("click", function (e) {
            let imgItems = Array.from(container.querySelectorAll('.grid-item'));
            swiper.removeAllSlides(); // 모든 슬라이드 제거
            swiperWrapper.innerHTML = ''; // 슬라이드 래퍼 초기화

            let clickedIndex = 0;
            imgItems.forEach((item, index) => {
                if (item === e.target.closest('.grid-item')) {
                    clickedIndex = index;
                }
                let imgSrc = item.querySelector('img').getAttribute('src');
                let swiperSlide = document.createElement('div');
                swiperSlide.classList.add('swiper-slide');
                swiperSlide.innerHTML = `
                    <div class="swiper-zoom-container">
                        <img src="${imgSrc}" alt="Image">
                        <div class="img-overlay"></div> <!-- 이미지 오버레이 추가 -->
                    </div>`;
                swiperWrapper.appendChild(swiperSlide);
            });

            swiper.update(); // Swiper 업데이트
            swiper.slideTo(clickedIndex, 0);
            imageOverlay.style.display = "block";
            imageOverlay.style.zIndex = "10002";
            document.body.style.overflow = 'hidden'; // 스크롤 막기
            closeButton.style.display = "block";

            // 이미지 다운로드 방지
            if (toggleSwitch_gallery_zoom_status === 1) {
                document.querySelectorAll('.swiper-slide img').forEach(img => {
                    img.addEventListener('contextmenu', preventDefault);
                    img.addEventListener('mousedown', preventDefault);
                    img.addEventListener('touchstart', preventDefault);
                    img.addEventListener('click', preventDefault); // 추가된 코드
                    img.addEventListener('dblclick', preventDefault); // 추가된 코드
                    img.addEventListener('auxclick', preventDefault); // 추가된 코드
                    console.log("이미지 다운로드 방지 활성화됨"); // 콘솔 로그 추가
                });

                document.querySelectorAll('.img-overlay').forEach(overlay => {
                    overlay.style.display = 'block';
                });
            }
        });
    });

    if (closeButton) {
        closeButton.addEventListener("click", function () {
            imageOverlay.style.display = "none";
            document.body.style.overflow = 'auto'; // 스크롤 풀기

            // 이미지 다운로드 방지 해제
            if (toggleSwitch_gallery_zoom_status === 1) {
                document.querySelectorAll('.swiper-slide img').forEach(img => {
                    img.removeEventListener('contextmenu', preventDefault);
                    img.removeEventListener('mousedown', preventDefault);
                    img.removeEventListener('touchstart', preventDefault);
                    img.removeEventListener('click', preventDefault); // 추가된 코드
                    img.removeEventListener('dblclick', preventDefault); // 추가된 코드
                    img.removeEventListener('auxclick', preventDefault); // 추가된 코드
                    console.log("이미지 다운로드 방지 해제됨"); // 콘솔 로그 추가
                });

                document.querySelectorAll('.img-overlay').forEach(overlay => {
                    overlay.style.display = 'none';
                });
            }
        });
    }

    function preventDefault(e) {
        e.preventDefault();
        console.log("이벤트가 차단되었습니다."); // 콘솔 로그 추가
    }
}
