export function QNASlide(){
    let items = document.querySelectorAll('.QNA_list_item');

    items.forEach(function(item) {
        item.addEventListener('click', function() {
            // 여기서 this는 클릭된 .QNA_list_item을 가리킵니다.
            let description = this.nextElementSibling; // .QNA_list_description 요소를 가리킵니다.
            if (description.classList.contains('slide-active')) {
                description.classList.remove('slide-active');
            } else {
                description.classList.add('slide-active');
            }
    
            // .arrow 클래스명을 가진 요소를 찾아서 처리합니다.
            let arrow = this.querySelector('.arrow'); // .arrow 요소를 가리킵니다.
            if (arrow) { // .arrow 요소가 있으면
                arrow.classList.toggle('rotate'); // rotate 클래스를 토글합니다.
            }
        });
    });
}