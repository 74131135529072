export function upload_video_thumbnail(dynamicValue) {
    const videoPlayer = document.getElementById('video_player');
    const originalThumbnailUrl = "../../../Resource/templates/controls/video/placeholder_img_th_upload.svg"; // 원래 썸네일 URL
    // 썸네일 업로드 이벤트 처리
    const thumbnailInput = document.getElementById('thumbnail_upload_btn');
    thumbnailInput.addEventListener('change', function (event) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('thumbnail', file);

        fetch(`/api_video_thumbnail_upload/${dynamicValue}`, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log(data);
                const thumbnailUrl = data.fileUrls;
                const thumbnailImage = document.getElementById('thumbnail_preview');
                if (thumbnailImage) {
                    thumbnailImage.src = thumbnailUrl;
                    thumbnailImage.hidden = false;
                }
                
                // 비디오의 포스터(썸네일) 이미지 설정
                videoPlayer.poster = thumbnailUrl;
                const videos = document.querySelectorAll('#uploaded_video_list video');
                videos.forEach(video => {
                    video.poster = thumbnailUrl;
                });

                // video_insert_wrap에 추가된 비디오의 썸네일도 변경
                const insertedVideo = document.querySelector('.video_insert_wrap video');
                if (insertedVideo) {
                    insertedVideo.poster = thumbnailUrl;
                }

                // placeholder 이미지 src 대체
                const placeholderImg = document.querySelector('.video_upload_placeholder_img');
                if (placeholderImg) {
                    placeholderImg.src = thumbnailUrl;
                }
            } else {
                console.error('Thumbnail upload failed:', data.error);
            }
        })
        .catch(error => {
            console.error('Error uploading thumbnail:', error);
        });
    });

    // 썸네일 삭제 이벤트 처리
    const deleteThumbnailButton = document.querySelector('.delete_thumbnail_file_wrap');
    if (deleteThumbnailButton) {
        deleteThumbnailButton.addEventListener('click', function () {
            fetch(`/api_video_thumbnail_upload/${dynamicValue}`, {
                method: 'DELETE'
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const thumbnailImage = document.getElementById('thumbnail_preview');
                    if (thumbnailImage) {
                        thumbnailImage.src = originalThumbnailUrl;
                        thumbnailImage.hidden = true;
                    }

                    // 비디오의 포스터(썸네일) 이미지를 원래 URL로 되돌림
                    videoPlayer.poster = originalThumbnailUrl;
                    const videos = document.querySelectorAll('#uploaded_video_list video');
                    videos.forEach(video => {
                        video.poster = originalThumbnailUrl;
                    });

                    // video_insert_wrap에 추가된 비디오의 썸네일도 원래 URL로 되돌림
                    const insertedVideo = document.querySelector('.video_insert_wrap video');
                    if (insertedVideo) {
                        insertedVideo.poster = "";
                    }

                    // placeholder 이미지 src도 원래 URL로 되돌림
                    const placeholderImg = document.querySelector('.video_upload_placeholder_img');
                    if (placeholderImg) {
                        placeholderImg.src = originalThumbnailUrl;
                    }

                    // 썸네일 입력 필드 초기화
                    thumbnailInput.value = '';
                    console.log('Thumbnail input reset.'); // 디버깅 로그 추가
                } else {
                    console.error('Thumbnail deletion failed:', data.message);
                }
            })
            .catch(error => {
                console.error('Error deleting thumbnail:', error);
            });
        });
    }
}
