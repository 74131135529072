export function menuSlide() {

    let menuList = document.querySelector('.header_menuList');
    let mobileMenu = document.querySelector('.mobileMenu');
    let BGdimmed = document.querySelector("#menuBG.BgDimmed");

    function checkWindowSize() {
        if (window.innerWidth >= 890) {
            BGdimmed.style.display = "none";
            menuList.style.transform = "translateY(-464px)";

            // mobileMenu에 'is-active' 클래스가 있다면 제거
            if (mobileMenu.classList.contains('is-active')) {
                mobileMenu.classList.remove('is-active');
            }

            // 스크롤 활성화
            // document.documentElement.style.overflow = 'auto';
            document.body.style.overflow = 'auto';
        }
    }

    // BGdimmed 요소를 클릭했을 때의 동작을 정의
    if (BGdimmed) {
        BGdimmed.style.cursor = "pointer";
        BGdimmed.addEventListener('click', () => {
            if (menuList.style.transform === "translateY(-64px)") {
                menuList.style.transform = "translateY(-464px)";
                BGdimmed.style.display = "none";
                mobileMenu.classList.remove('is-active');

                // 스크롤 활성화
                // document.documentElement.style.overflow = 'auto';
                document.body.style.overflow = 'auto';
            }
        });
    }

    if (mobileMenu) {
        mobileMenu.addEventListener('click', () => {
            mobileMenu.classList.toggle('is-active');

            // 현재 transform 값을 검사하여 토글 로직 적용
            if (menuList.style.transform === "translateY(-64px)") {
                menuList.style.transform = "translateY(-464px)";
                BGdimmed.style.display = "none";

                // 스크롤 활성화
                document.documentElement.style.overflow = 'auto';
                document.body.style.overflow = 'auto';
            } else {
                menuList.style.transform = "translateY(-64px)";
                BGdimmed.style.display = "block";
                BGdimmed.style.zIndex = "1";

                // 스크롤 비활성화
                document.documentElement.style.overflow = 'hidden';
                document.body.style.overflow = 'hidden';
            }
        });

        window.addEventListener('resize', checkWindowSize);
        checkWindowSize();  // 초기 로딩 시 실행
    }
}