// 날짜를 렌더링하고, 표시하는 캘린더 함수

export function updateCalendar(selectedDate) {
    // 선택된 날짜의 연도와 월을 구합니다.
    let SelectYear = selectedDate.getFullYear();
    let SelectMonth = selectedDate.getMonth();

    // 선택된 월의 첫째 날과 마지막 날을 구합니다.
    var SelectfirstDay = new Date(SelectYear, SelectMonth, 1);
    var SelectlastDay = new Date(SelectYear, SelectMonth + 1, 0);

    // 선택된 월의 첫째 날이 무슨 요일인지 구합니다 (0=일요일, 1=월요일, ...).
    var firstDayOfWeek = SelectfirstDay.getDay();

    // 이전 달의 마지막 날을 구합니다.
    var prevMonthLastDay = new Date(SelectYear, SelectMonth, 0);
    var prevMonthLastDate = prevMonthLastDay.getDate();

    // 다음 달의 첫째 날을 구합니다.
    var nextMonthFirstDay = new Date(SelectYear, SelectMonth + 1, 1);

    // 캘린더 HTML 요소를 찾고 내부를 비웁니다.
    var calendar = document.querySelector('.dates');
    calendar.innerHTML = '';

    // 이전 달의 마지막 주를 캘린더에 추가합니다.
    for (var i = firstDayOfWeek - 1; i >= 0; i--) {
        var date = prevMonthLastDate - i;
        calendar.innerHTML += '<div class="day prev disable">' + date + '</div>';
    }

    // 현재 달의 날짜를 캘린더에 추가합니다.
    for (var i = 1; i <= SelectlastDay.getDate(); i++) {
        var dayOfWeek = new Date(SelectYear, SelectMonth, i).getDay();
        var className = 'day current';
        if (dayOfWeek == 0) {
            className += ' sunday';
        } else if (dayOfWeek == 6) {
            className += ' saturday';
        }
        calendar.innerHTML += '<div class="' + className + '">' + i + '</div>';
    }

    // 다음 달의 첫 주를 캘린더에 추가합니다.
    for (var i = 1; i <= 6 - SelectlastDay.getDay(); i++) {
        var date = i;
        calendar.innerHTML += '<div class="day next disable">' + date + '</div>';
    }

    // 오늘 날짜를 표시하는 코드를 추가합니다.
    var today = new Date();
    if (today.getFullYear() === SelectYear && today.getMonth() === SelectMonth) {
        let todayElement = calendar.querySelector(`.day.current:nth-child(${today.getDate() + firstDayOfWeek})`);
        if (todayElement) {
            todayElement.classList.add('today');
        }
    }
}
