function template_accountInfo(account_status) {

    // 계좌 유무 체크후, flex or none 처리
    document.querySelector('.AccountSection').style.display = (account_status === 0) ? 'none' : 'flex';


    // 삭제 기능 추가 함수
    function addDeleteFunctionality(deleteButton) {
        deleteButton.addEventListener('click', function () {
            let accountInput = deleteButton.closest('.AccountInputs');
            if (accountInput && accountInput.parentElement) {
                let accordionIndex = [...accountInput.parentElement.children].indexOf(accountInput);
                if (accordionIndex > -1) {

                    if (accordionIndex < 1) {
                        alert('이 계좌는 삭제하실 수 없습니다.');
                        return;
                    }
                    // 계정 입력 영역의 부모 그룹 클래스 확인
                    let parentGroup = accountInput.closest('.GroomAccountInputGroups') || accountInput.closest('.BrideAccountInputGroups');

                    let accordionTitleId = "";

                    if (parentGroup) {

                        // 부모 그룹의 클래스에 따라 accordionTitleId 설정
                        if (parentGroup.classList.contains('GroomAccountInputGroups')) {
                            accordionTitleId = 'accordion-title-groom';
                        } else if (parentGroup.classList.contains('BrideAccountInputGroups')) {
                            accordionTitleId = 'accordion-title-bride';
                        }

                        // 아코디언 영역에서도 동일한 섹션을 제거해야 함
                        let accordionContent = document.querySelector(`#${accordionTitleId}`).nextElementSibling;
                        if (accordionContent) {
                            let targetAccordion = accordionContent.children[accordionIndex];
                            if (targetAccordion) {
                                targetAccordion.remove();
                            }
                        }
                    }

                    accountInput.remove();
                }
            }
        });
    }

    const deleteButtons = document.querySelectorAll('.DeleteAccountBtn');
    deleteButtons.forEach(button => {
        addDeleteFunctionality(button);
    });


    // 계정 추가 기능을 처리하는 함수
    function handleAccountAddition(buttonId, accountInputGroupClass, accordionTitleId) {
        let accountBtn = document.getElementById(buttonId);

        // 삭제 기능을 추가하는 내부 함수 (위와 동일)
        function addDeleteFunctionality(deleteButton) {
            deleteButton.addEventListener('click', function () {
                let accountInput = deleteButton.closest('.AccountInputs');
                if (accountInput && accountInput.parentElement) {
                    let accordionIndex = [...accountInput.parentElement.children].indexOf(accountInput);
                    if (accordionIndex > -1) {
                        accountInput.remove();

                        // 아코디언 영역에서도 동일한 섹션을 제거해야 함
                        let accordionContent = document.querySelector(`#${accordionTitleId}`).nextElementSibling;
                        if (accordionContent) {
                            let targetAccordion = accordionContent.children[accordionIndex];
                            if (targetAccordion) {
                                targetAccordion.remove();
                            }
                        }
                    }
                }
            });
        }


        if (accountBtn) {
            accountBtn.addEventListener("click", function () {
                // 선택된 계정 입력 영역들을 찾음
                let inputAreas = document.querySelectorAll(accountInputGroupClass);
                inputAreas.forEach(function (inputArea) {

                    // 해당 입력 영역의 첫번째 AccountInputs만을 찾아 복제합니다.
                    let nameBoxSource = inputArea.querySelector('.AccountInputs');
                    if (nameBoxSource) {
                        let nameBox = nameBoxSource.cloneNode(true); // AccountInputs를 복제
                        nameBox.querySelectorAll('input').forEach(function (inputElement) {
                            if (inputElement.value === "신랑") {
                                inputElement.value = ""; // 값을 지움
                                inputElement.placeholder = "호칭"; // placeholder를 원하는 기본 값으로 변경
                            }
                            if (inputElement.value === "신부") {
                                inputElement.value = ""; // 값을 지움
                                inputElement.placeholder = "호칭"; // placeholder를 원하는 기본 값으로 변경
                            }
                        });

                        inputArea.appendChild(nameBox); // 새로운 계정을 계정 그룹에 추가

                        // 삭제 버튼에 이벤트 리스너 추가
                        let deleteBtns = nameBox.querySelectorAll('.DeleteAccountBtn');
                        deleteBtns.forEach(addDeleteFunctionality);
                    }

                    // 선택된 아코디언과 해당하는 계정 그룹을 찾음
                    let selectedAccordion = document.querySelector(`.accordion-title#${accordionTitleId}`);
                    let accordionAccountsGroup = selectedAccordion.nextElementSibling;

                    // 해당 아코디언 그룹의 첫번째 accodian-inner만을 찾아 복제합니다.
                    let accordionAccountsSource = accordionAccountsGroup.querySelector('.accodian-inner');
                    if (accordionAccountsSource) {
                        let accordionAccounts = accordionAccountsSource.cloneNode(true);
                        accordionAccounts.querySelectorAll('*').forEach(function (innerElement) {
                            if (innerElement.innerHTML.includes("신랑")) {
                                innerElement.innerHTML = innerElement.innerHTML.replace("신랑", "호칭");
                            }
                            if (innerElement.innerHTML.includes("신부")) {
                                innerElement.innerHTML = innerElement.innerHTML.replace("신부", "호칭");
                            }
                        });

                        accordionAccountsGroup.appendChild(accordionAccounts); // 새로운 계정을 아코디언 그룹에 추가
                    }
                });
            });
        }
    }

    handleAccountAddition('groomAddAccountBtn', '.GroomAccountInputGroups', 'accordion-title-groom');
    handleAccountAddition('brideAddAccountBtn', '.BrideAccountInputGroups', 'accordion-title-bride');

    setupAccordion();


}

const toggleStatus = document.getElementById('account_status');
let toggle_contents_section = document.querySelector('.AccountSection');

if (toggleStatus) {
    toggleStatus.onclick = () => {
        console.log(toggle_contents_section);
        const isActive = toggleStatus.classList.toggle('active');
        toggle_contents_section.style.display = isActive ? 'flex' : 'none';
    };
}


export function setupAccordion(accountFoldStatus) {
    const toggleFold = document.getElementById('AccountFold');
    const accordions = document.querySelectorAll(".accordion-content");
    console.log('initial:' + accountFoldStatus);

    const toggleAccordionVisibility = (show) => {
        accordions.forEach(accordion => {
            $(accordion)[show ? 'slideDown' : 'slideUp']();
        });
    };

    // toggleFold가 존재하는 경우에만 클래스 상태를 확인하여 초기 상태를 결정
    const initialActiveState = toggleFold && toggleFold.classList.contains('active') || accountFoldStatus === 1;
    console.log('initialActiveState:', initialActiveState); // 디버깅 목적
    toggleAccordionVisibility(initialActiveState);

    if (toggleFold) {
        toggleFold.onclick = () => {
            const isActive = toggleFold.classList.toggle('active');
            toggleAccordionVisibility(isActive);
        };
    }

    const accordion = document.querySelector(".accordion");
    if (accordion) {
        accordion.addEventListener("click", function (e) {
            e.preventDefault();
            const target = e.target;
            if (target.classList.contains("accordion-title")) {
                const content = target.nextElementSibling;
                $(content).is(":visible") ? $(content).slideUp() : $(content).slideDown();
            }
        });
    }
}

    // 카카오페이에 값을 입력하지 않았을 때, 버튼을 숨기는 함수
        // 함수 정의: data-value에 따라 display 속성 변경
        const updateDisplay = (element) => {
            if (element.getAttribute('data-value') === "") {
                element.style.display = 'none';
            } else {
                element.style.display = 'block';
            }
        };

        // 초기 상태 업데이트
        document.querySelectorAll('.Btn_KaKaoPay_groom, .Btn_KaKaoPay_bride').forEach(element => {
            updateDisplay(element);
        });

        // MutationObserver를 사용하여 data-value 속성 변경 감지
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-value') {
                    updateDisplay(mutation.target);
                }
            });
        });

        // 감시 설정
        document.querySelectorAll('.Btn_KaKaoPay_groom, .Btn_KaKaoPay_bride').forEach(element => {
            observer.observe(element, { attributes: true });
        });


export default template_accountInfo;