// countdown.js 파일

let countdownInterval; // 전역 변수로 선언

// 카운트다운 함수
// updateCountdown 함수에서 시간대 처리를 보다 간결하게
export function updateCountdown(selectedDate) {
    let now = new Date(); // 현재 시간
    let timeLeft = selectedDate - now; // 남은 시간 계산

    if (timeLeft <= 0) {
        clearInterval(countdownInterval);
        document.getElementById("WeddingCounterWrap_Count_DAYS").innerText = '00';
        document.getElementById("WeddingCounterWrap_Count_HOUR").innerText = '00';
        document.getElementById("WeddingCounterWrap_Count_MIN").innerText = '00';
        document.getElementById("WeddingCounterWrap_Count_SEC").innerText = '00';
        return;
    }

    let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
    let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
    let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

    document.getElementById("WeddingCounterWrap_Count_DAYS").innerText = days;
    document.getElementById("WeddingCounterWrap_Count_HOUR").innerText = hours;
    document.getElementById("WeddingCounterWrap_Count_MIN").innerText = minutes;
    document.getElementById("WeddingCounterWrap_Count_SEC").innerText = seconds;
}


// 카운트다운 시작 함수
export function startCountdown(selectedDate) {
    console.log('날짜체크'+selectedDate);
    updateCountdown(selectedDate);
    countdownInterval = setInterval(() => updateCountdown(selectedDate), 1000);
}

export function handleDateInputChange() {

    // 셀렉트 박스에서 선택한 오후/오전, 시간, 분 가져오기
    let ampmSelect = document.getElementById("SelectAMPM");
    let hourSelect = document.getElementById("SelectTime");
    let minuteSelect = document.getElementById("SelectMinute");

    const dateInput = document.getElementById("date");
    const dateInputValue = dateInput.value;
    let dateArr = dateInputValue.split('-');
    
    // selectedDate를 한국 시간 자정으로 설정
    let selectedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], 0, 0, 0);
    selectedDate.setHours(selectedDate.getHours() + 9); // 한국 표준시 GMT+9 적용

    // 아래 로직은 기존과 동일
    let ampm = ampmSelect.value;
    let hourValue = parseInt(hourSelect.value, 10);
    let minuteValue = parseInt(minuteSelect.value, 10);

    if (ampm === '오후' && hourValue !== 12) {
        hourValue += 12;
    }
    if (ampm === '오전' && hourValue === 12) {
        hourValue = 0;
    }

    selectedDate.setHours(hourValue, minuteValue, 0, 0);

    // 화면에 표시할 시간 (12시간제로 변환)
    let displayHour = hourValue % 12 || 12; // 0시는 12시로 표시, 오후 12시는 그대로 12시
    let displayAMPM = hourValue < 12 ? '오전' : '오후'; // 12보다 작으면 오전, 그 외는 오후

    // 시와 분 뒤에 '시'와 '분'을 추가하고, 00분일 경우 표시하지 않음
    let minuteText = minuteValue === 0 ? '' : minuteValue.toString().padStart(2, '0') + '분';

    // 갱신된 값을 각각의 id에 innerText로 설정
    document.getElementById("CalAMPM").innerText = displayAMPM;
    document.getElementById("CalWeddingTime").innerText = displayHour + '시';
    document.getElementById("CalWeddingMinute").innerText = minuteText;

    // WeddingMinute 영역의 display 스타일을 조건에 따라 설정
    if (minuteValue === 0) {
        document.getElementById("CalWeddingMinute").style.display = 'none';
        document.getElementById("rsvp_CalWeddingMinute").style.display = 'none';
        document.getElementById("rsvp_popup_CalWeddingMinute").style.display = 'none';
    } else {
        document.getElementById("CalWeddingMinute").style.display = 'inline';
        document.getElementById("rsvp_CalWeddingMinute").style.display = 'inline';
        document.getElementById("rsvp_popup_CalWeddingMinute").style.display = 'inline';
    }

    console.log(selectedDate);




    if (countdownInterval) {
        clearInterval(countdownInterval);
    }

    updateCountdown(selectedDate);
    countdownInterval = setInterval(() => updateCountdown(selectedDate), 1000);
}
