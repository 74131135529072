



export function showImagePreview() {
    const currentUrl = window.location.href;
    const pathArray = currentUrl.split('/');
    const disableMainUrl = `${pathArray[0]}//${pathArray[2]}/`; // 동작하지 않아야 할 URL
    const disableLoginUrl = `${pathArray[0]}//${pathArray[2]}/api_Auth/login`; // 동작하지 않아야 할 URL
    const disableMyPageUrl = `${pathArray[0]}//${pathArray[2]}/api_Mypage/${pathArray[4]}`; // 동작하지 않아야 할 URL

    // 수정페이지 혹은 초대장 페이지일 경우, 해당 JS는 동작하지 않음
    if (currentUrl === disableMainUrl || currentUrl === disableLoginUrl || currentUrl === disableMyPageUrl) {
        return;
    }

    let thumbnailsContainers = document.querySelectorAll('.grid-container');
    let imageOverlay = document.getElementById("GalleryPreview");
    let closeButton = document.getElementById("GalleryPreviewExit");
    let swiperContainer = document.querySelector('.swiper-container');
    let swiperWrapper = swiperContainer.querySelector('.swiper-wrapper');

    var swiper = new Swiper(swiperContainer, {
        slidesPerView: 1,
        spaceBetween: 48,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        },
        touchEventsTarget: 'wrapper',
    });

    thumbnailsContainers.forEach(container => {
        container.addEventListener("click", function (e) {
            // 클릭된 요소의 자식 요소들 중에서 .grid-item만 가져오기
            let imgItems = Array.from(container.querySelectorAll('.grid-item'));

            // Clear all slides before adding new ones
            swiper.removeAllSlides();
            swiperWrapper.innerHTML = '';

            let clickedIndex = 0; // 클릭된 이미지의 인덱스를 저장하는 변수

            imgItems.forEach((item, index) => {
                // 클릭한 grid-item과 현재 반복 중인 Item이 일치하는지 확인
                if (item === e.target.closest('.grid-item')) {
                    clickedIndex = index; // 클릭된 이미지의 인덱스를 저장
                }
                let imgSrc = item.querySelector('img').getAttribute('src');
                let swiperSlide = document.createElement('div');
                swiperSlide.classList.add('swiper-slide');
                swiperSlide.innerHTML = `<img src="${imgSrc}" alt="Image">`;
                swiperWrapper.appendChild(swiperSlide);
            });

            swiper.update(); // Update Swiper after adding slides
            swiper.slideTo(clickedIndex, 0); // 클릭된 이미지의 위치로 슬라이드 이동

            imageOverlay.style.display = "block";
            imageOverlay.style.zIndex = "10001";
            closeButton.style.display = "block";
        });
    });

    if (closeButton) {
        closeButton.addEventListener("click", function () {
            imageOverlay.style.display = "none";
        });
    }

}