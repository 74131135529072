export function createKSTDate(dateArr) {
    // 입력받은 날짜 배열을 UTC 기준으로 변환
    let utcDate = new Date(Date.UTC(dateArr[0], dateArr[1] - 1, dateArr[2], 0, 0, 0));
    
    // 한국 시간 오프셋 (UTC + 9시간)
    let kstOffset = 9 * 60 * 60 * 1000;
    
    // UTC 날짜에 오프셋을 더하여 KST 날짜로 변환
    let selectedDate = new Date(utcDate.getTime() + kstOffset);
    
    console.log('현재 선택된 날짜: ' + selectedDate);
    
    return selectedDate;
}
