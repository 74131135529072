export function sideNav_folded() {
    const fontBtnItem = document.querySelector('.fold_button');
    const mobileControlsPreview = document.querySelector('.mobile_controls_preview');
    const template_control_mobile_edit = document.querySelector('.template_control_mobile_edit');
    const foldBtn = document.querySelector('.fold_button_wrap');
    const foldBtnIcon = document.querySelector('.fold_button_icon');
    const sideNav = document.querySelector('.right_contents');
    const dimmed = document.querySelector('.contents_dimmed_overlay');
    const body = document.body;
    const header = document.querySelector('.header');

    const toggleFold = () => {
        sideNav.classList.toggle('folded');
        foldBtn.classList.toggle('folded');
        foldBtnIcon.classList.toggle('folded');
        dimmed.classList.toggle('folded');

        if (window.innerWidth <= 560) {
            if (dimmed.classList.contains('folded')) {
                header.style.display = 'block';
                foldBtn.style.transform = 'translateY(-32px)';
            } else {
                header.style.display = 'none';
                foldBtn.style.transform = 'translateY(64px)';
            }
        }
    };

    fontBtnItem.addEventListener('click', toggleFold);
    mobileControlsPreview.addEventListener('click', toggleFold);
    template_control_mobile_edit.addEventListener('click', toggleFold);
}
