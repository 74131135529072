export async function previewTemplate() {
    const imgGridWrap_preview_buttons = document.querySelectorAll('.imgGridWrap_preview_button');
    const closebutton = document.querySelector('.close-button');
    const BgDimmed = document.querySelector('.BgDimmed');
    const loadingIndicator = document.createElement('div');

    loadingIndicator.innerHTML = '<div class="loading">로딩 중...</div>';
    loadingIndicator.style.display = 'none';
    document.body.appendChild(loadingIndicator);

    // template 값에 따른 URL 매핑
    const templateUrls = {
        DesignArch: '/api_GetInvitation/o8eaQpjeVrAn1nPCTKe895',
        DesignRadius: '/api_GetInvitation/xu1KdMFbzRZduady3cTGpU',
        DesignSimple: '/api_GetInvitation/mHKGZVYn92GRTSp2oGsCeq',
        DesignTogether: '/api_GetInvitation/oa8vLtqUcUfXPt8DnZAwo3',
        DesignTrip: '/api_GetInvitation/ngPfJqy8grMvbi82GcnLzK',
        DesignSimple_line_01: '/api_GetInvitation/jmRxGpvPTcKb2dRakBxwcV',
        DesignSimple_line_02: '/api_GetInvitation/vzH78XU4RbxwkfRcmyzwTU',
        DesignSimple_line_03: '/api_GetInvitation/6NZ21pejS8GD5P7HFpCKev'
    };

    imgGridWrap_preview_buttons.forEach((button) => {
        button.addEventListener('click', () => {
            let template = button.getAttribute('data-value');
            // 매핑된 URL로 바로 이동
            const url = templateUrls[template];
            if (url) { // 유효한 template 값인 경우에만 이동
                window.location.href = url;
            }
        });
    });



    closebutton.addEventListener('click', () => {
        const sideContents = document.querySelector('.BgDimmedWrap .side_contents');
        if (sideContents) {
            sideContents.innerHTML = '';
        }
        BgDimmed.classList.remove('is-active');
        closebutton.style.display = "none";
        toggleBodyScroll();
    });

    function toggleBodyScroll() {
        const body = document.querySelector('body');
        body.style.overflow = body.style.overflow === "hidden" ? "auto" : "hidden";
    }
}
