export function invitationStyle() {

    function checkAndHideDot(firstNameId, lastNameId, dotId) {
        let firstName = document.getElementById(firstNameId).innerHTML.trim();
        let lastName = document.getElementById(lastNameId).innerHTML.trim();
        let dotElement = document.getElementById(dotId);
        let bride_parents_wrap = document.querySelector('.bride_parents_wrap');
        let groom_parents_wrap = document.querySelector('.groom_parents_wrap');
        
        if (firstName === '' && lastName === '') {
            if (dotElement) {
                dotElement.style.display = 'none';
                bride_parents_wrap.style.justifyContent = 'center';
                groom_parents_wrap.style.justifyContent = 'center';
            }
        }
    }

    // 각 가족 구성원에 대해 함수 호출
    checkAndHideDot('GroomMotherFirstName', 'GroomMotherLastName', 'dot_groomFamily');
    checkAndHideDot('GroomFatherFirstName', 'GroomFatherLastName', 'dot_groomFamily');
    checkAndHideDot('BrideFatherFirstName', 'BrideFatherLastName', 'dot_brideFamily');
    checkAndHideDot('BrideMotherFirstName', 'BrideMotherLastName', 'dot_brideFamily');
    

    
    // 초대장 템플릿에만 다른 속성 적용
    function applyStylesToElement(selector, styles) {
        const element = document.querySelector(selector);
        if (!element) {
            console.warn(`Element not found for selector: ${selector}`);
            return;
        }
        Object.assign(element.style, styles);
    }

    function updateStylesBasedOnWidth() {
        if (window.innerWidth <= 560) {
            applyStylesToElement('.side_contents', {
                width: "100%",
                maxWidth: "none"
            });
        } else {
            applyStylesToElement('.side_contents', {
                width: "auto", // 원래 width 값으로 되돌리기
                maxWidth: "400px" // 원래 maxWidth 값으로 되돌리기
            });
        }
    }

    // 초기 스타일 적용
    applyStylesToElement('.side_contents', {
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0%)",
        maxHeight: "none",
        borderRadius: "0",
        overflowX: "hidden",
        minWidth: "180px"
    });

    // 창 크기 변경시 스타일 업데이트
    window.addEventListener('resize', updateStylesBasedOnWidth);

    // 페이지 로드시 초기 스타일 업데이트
    updateStylesBasedOnWidth();

    // 배경색과 수평 스크롤 설정
    document.body.style.overflowX = "hidden";

    // 모바일 구간 높이 설정

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // resize
    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    })
}