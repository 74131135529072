export const backgroundMusicPlayer = {
    music: null, // 현재 재생 중인 Audio 객체
    playing: false, // 재생 상태
    btns: document.querySelectorAll(".AudioControl"), // 재생/일시정지 버튼
    musicPaths: {}, // 음악 파일 경로를 저장하는 객체

    // 서버에서 MP3 파일 목록을 가져오는 비동기 함수
    async fetchMusicPaths() {
        try {
            const response = await fetch('/api_GetBGM');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const bgmPaths = await response.json();
            bgmPaths.forEach((path, index) => {
                this.musicPaths[`BaseAudio_${index + 1}`] = path;
            });
        } catch (e) {
            console.error("Error fetching BGM: ", e);
        }
    },

    // 음악 재생/일시정지 토글 함수
    toggleMusic() {
        if (this.music) {
            this.playing = !this.playing;
            if (this.playing) {
                this.music.play();
            } else {
                this.music.pause();
            }
            this.updateButtonDisplays();
        }
    },

    // 재생/일시정지 버튼의 표시를 업데이트하는 함수
    updateButtonDisplays() {
        let buttonPauses = document.querySelectorAll('.audiobuttonpause');
        let buttonPlays = document.querySelectorAll('.audiobutton');
        buttonPauses.forEach(btn => btn.style.display = this.playing ? "none" : "block");
        buttonPlays.forEach(btn => btn.style.display = this.playing ? "block" : "none");
    },

    // 업로드한 음악과 선택한 음악에 대한 UI 상태 업데이트
    updateStylesAndIcon(forceDefault = false) {
        const musicIcon = document.querySelector('.BGM_upload_music');
        const musicInner = document.querySelector('.BGM_upload_music_wrap');
        if (forceDefault || !this.playing) {
            musicIcon.src = "../../../Resource/templates/controls/design/bgm/music-note-03.svg";
            musicInner.classList.remove('Active');
        } else if (this.playing) {
            musicIcon.src = "../../../Resource/templates/controls/design/bgm/playing.png";
            musicInner.classList.add('Active');
        }
    },

    // 이벤트 리스너 설정 및 초기화 함수
    setup(dynamicValue) {
        this.btns.forEach(btn => btn.addEventListener('click', this.toggleMusic.bind(this)));
        const bgmList = document.querySelectorAll('ul.TabListBGM li');
        bgmList.forEach(li => li.addEventListener('click', () => {
            this.handleBGMSelection(li);
        }));
        // Setup direct BGM upload feature
        this.setupBGMUpload(dynamicValue);

        // 비디오 플레이어의 재생 및 일시정지 이벤트 처리
        this.setupVideoSync();
    },

    // 비디오 플레이어와 배경 음악의 동기화를 설정하는 함수
    setupVideoSync() {
        this.videoElement = document.querySelector('.video_insert_wrap video'); // 비디오 요소 참조
        if (this.videoElement) {
            this.videoElement.addEventListener('play', () => {
                if (this.music && this.playing) {
                    this.music.pause();
                    this.playing = false;
                    console.log('비디오 재생: 배경 음악 일시정지');
                    this.updateButtonDisplays();
                }
            });

            this.videoElement.addEventListener('pause', () => {
                if (this.music && !this.playing) {
                    this.music.play();
                    this.playing = true;
                    console.log('비디오 일시정지: 배경 음악 재생');
                    this.updateButtonDisplays();
                }
            });

            this.videoElement.addEventListener('ended', () => {
                if (this.music && !this.playing) {
                    this.music.play();
                    this.playing = true;
                    console.log('비디오 종료: 배경 음악 재생');
                    this.updateButtonDisplays();
                }
            });
        }
    },

    // 배경음악 선택 핸들러
    handleBGMSelection(li) {
        const musicIcon = document.querySelector('.BGM_upload_music');
        const bgmList = document.querySelectorAll('ul.TabListBGM li');
        const musicInner = document.querySelector('.BGM_upload_music_wrap'); // musicInner 가져오기
    
        bgmList.forEach(el => el.classList.remove('Active'));
        li.classList.add('Active');  // 현재 선택된 항목에 Active 클래스 적용
        musicIcon.src = "../../../Resource/templates/controls/design/bgm/music-note-03.svg";
        // musicInner의 Active 클래스 제거
        musicInner.classList.remove('Active');
    
        // 다른 음악이 재생 중인지 확인하고 중지
        if (this.music) {
            this.music.pause();
            this.music = null;
        }
    
        const uploadedAudioPlayer = document.getElementById('audio_player');
        if (uploadedAudioPlayer && !uploadedAudioPlayer.paused) {
            uploadedAudioPlayer.pause();
        }
    
        const audioId = li.id;
        if (audioId === 'NoneAudio') {
            this.playing = false;
            this.btns.forEach(btn => btn.style.display = "none");
        } else {
            const audioPath = this.musicPaths[audioId];
            if (audioPath) {
                this.music = new Audio(audioPath);
                this.music.addEventListener('ended', this.replayMusic.bind(this));
                this.music.play();
                this.playing = true;
                this.btns.forEach(btn => btn.style.display = "flex");
            }
        }
    
        this.updateButtonDisplays();
    },

    // 클릭 시 오디오 재생을 설정하는 메서드
    setupClickToPlay() {
        const musicInner = document.querySelector('.BGM_upload_music_wrap');
        const audioPlayer = document.getElementById('audio_player');
        const bgmList = document.querySelectorAll('ul.TabListBGM li'); // bgmList 가져오기
    
        musicInner.addEventListener('click', () => {
            console.log("Music wrap clicked");
    
            // 다른 음악이 재생 중인지 확인하고 중지
            if (this.music && this.music !== audioPlayer) {
                this.music.pause();
                this.music = null;
            }
    
            // 다른 BGM 선택 사항들의 Active 클래스 제거
            bgmList.forEach(el => el.classList.remove('Active'));
    
            if (audioPlayer.paused) {
                audioPlayer.play();
                this.music = audioPlayer;
                this.playing = true;
            } else {
                audioPlayer.pause();
                this.playing = false;
            }
    
            this.updateButtonDisplays();
            this.updateStylesAndIcon(); // 여기에서 Active 클래스를 추가할 것
        });
    },

    // 오디오 재생을 재시작하는 메서드
    replayMusic() {
        this.music.play();
    },

    // Setup BGM Upload Feature
    setupBGMUpload(dynamicValue) {
        this.setupFileUpload(dynamicValue);
        this.setupDeleteAction();
        this.setupClickToPlay(dynamicValue);
    },

    setupFileUpload(dynamicValue) {
        const uploadInput = document.getElementById('BGM_upload_btn');
        uploadInput.addEventListener('change', async (event) => {
            const file = event.target.files[0];
            if (!file) return;

            if (file.size > 2097152) {
                alert('음원파일의 크기는 2MB를 초으할 수 없습니다.');
                event.target.value = '';
                return;
            }

            const formData = new FormData();
            formData.append('bgm', file);

            try {
                const response = await fetch(`/api_postBGM/${dynamicValue}`, {
                    method: 'POST',
                    body: formData
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                const uploadedUrl = result.url;

                const audioPlayer = document.getElementById('audio_player');
                audioPlayer.src = uploadedUrl;
                audioPlayer.hidden = false;
                document.querySelector('.BGM_upload_music_fileName').textContent = file.name;
                document.querySelector('.BGM_upload_list').classList.remove('bgm_hidden');

            } catch (error) {
                console.error("Error uploading the audio file:", error);
            }
        });
    },

    setupDeleteAction() {
        const deleteButton = document.querySelector('.delete_music_file_wrap');
        const uploadInput = document.getElementById('BGM_upload_btn');  // uploadInput을 여기서 정의합니다.

        deleteButton.addEventListener('click', () => {
            const audioPlayer = document.getElementById('audio_player');
            if (audioPlayer && !audioPlayer.paused) {
                audioPlayer.pause();
            }

            this.music = null;
            this.playing = false;
            audioPlayer.removeAttribute('src'); // 소스를 제거
            audioPlayer.load();                // 오디오를 리로드
            audioPlayer.hidden = true;         // 오디오 플레이어를 숨김

            document.querySelector('.BGM_upload_list').classList.add('bgm_hidden');
            document.querySelector('.BGM_upload_music_fileName').textContent = '';
            uploadInput.value = '';  // 파일 입력 필드를 리셋

            this.updateButtonDisplays();
            this.updateStylesAndIcon(true);
        });
    }
};
