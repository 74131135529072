export function tickerAnimation() {
    const container = document.getElementById('event_banner');
    const item = container.querySelector('.event_banner_wrap');
  
    for (let i = 0; i < 9; i++) {
        const clone = item.cloneNode(true);
        container.appendChild(clone);
    }
    const items = container.querySelectorAll('.event_banner_wrap');
  
    let gap;
    let itemWidthWithGap;
    let totalItemsWidth;
  
    function calculateWidthsAndPositions() {
        gap = window.innerWidth <= 560 ? 48 : 96;
        itemWidthWithGap = item.offsetWidth + gap;
        totalItemsWidth = itemWidthWithGap * items.length;
  
        items.forEach((item, index) => {
            item.style.position = 'absolute';
            item.style.left = `${index * itemWidthWithGap}px`;
        });
    }
  
    // 디바운싱 함수
    function debounce(func, wait) {
        let timeout;
        return function() {
            const context = this, args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
  
    // 창 크기 변경 시 재계산 로직에 디바운싱 적용
    window.addEventListener('resize', debounce(calculateWidthsAndPositions, 250));
  
    // 완전한 로드 후 너비 및 위치 계산
    window.onload = calculateWidthsAndPositions;
  
    let lastTime = 0;
    let speed = 0.04; // 속도 조절 변수
  
    container.addEventListener('mouseover', () => {
        speed = 0.02; // 마우스 오버 시 속도 감소
    });
  
    container.addEventListener('mouseout', () => {
        speed = 0.04; // 마우스 아웃 시 속도 복원
    });
  
    function updatePositions(time) {
        if (!lastTime) lastTime = time;
        const delta = time - lastTime;
  
        items.forEach(item => {
            let currentOffset = parseFloat(item.style.left, 10);
            currentOffset -= speed * delta;
            if (currentOffset <= -item.offsetWidth) {
                currentOffset += totalItemsWidth;
            }
            item.style.left = `${currentOffset}px`;
        });
  
        lastTime = time;
        requestAnimationFrame(updatePositions);
    }
  
    requestAnimationFrame(updatePositions);
}
