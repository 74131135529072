// 계좌번호 정보를 Post하는 api 함수
export function fetchAccountData(dynamicValue, accountDataInfo) {
    return fetch(`/api_Account/${dynamicValue}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: accountDataInfo
    })
    .then(response => {
        console.log(response);
        return response;
    })
    .catch(error => {
        console.error('Error:', error);
        throw error;
    });
}
