import { api_createTemplate } from '../api/api_createTemplate.js';
import { menuSlide } from './common_modules/menuslide.js';


    api_createTemplate(); // 템플릿 만들기를 공통으로 적용하는 모듈을 불러오는 함수
    menuSlide(); // 모바일 햄버거 메뉴를 적용하는 모듈 함수

    // 초기 스크롤 위치와 header 요소, 미디어 쿼리 설정
    let lastScrollTop = 0;
    const header = document.querySelector('.header');
    const mediaQuery = window.matchMedia('(max-width: 560px)');


    // // URL 체크 및 스크롤 방향에 따라 header 스타일 조정
    // function handleScroll() {
    //     const currentUrl = window.location.href;
    //     if (currentUrl.includes('/api_GetInvitation/')) return;
    //     if (currentUrl.includes('/api_CreateInvitation/')) return;
    //     if (currentUrl.includes('/api_EditInvitation/')) return;

    //     const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    //     header.style.transform = currentScroll > lastScrollTop ? 'translateY(-320px)' : '';
    //     lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    // }

    // // 미디어 쿼리에 따른 이벤트 리스너 조정
    // function handleMediaQueryChange(e) {
    //     const currentUrl = window.location.href; // currentUrl을 여기서 선언
    //     if (currentUrl.includes('/api_GetInvitation/')) return;
    //     if (currentUrl.includes('/api_CreateInvitation/')) return;
    //     if (currentUrl.includes('/api_EditInvitation/')) return;
        
    //     if (e.matches) {
    //         window.addEventListener('scroll', handleScroll, false);
    //     } else {
    //         window.removeEventListener('scroll', handleScroll, false);
    //         header.style.transform = '';
    //     }
    // }

    // // 초기 설정 및 미디어 쿼리 이벤트 리스너 추가
    // mediaQuery.addEventListener('change', handleMediaQueryChange);
    // handleMediaQueryChange(mediaQuery);
