// 마커를 전역변수로 배열에 담아서 불러옵니다.
import { markers } from '../../../utils/map/marker.js';
import { GetDBInfo } from '../../../api/api_dbInsert.js';

export async function setupMapsAndNavigation(currentUrl) {
    const templateId = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

    try {
        const responseData = await GetDBInfo(templateId);
        if (responseData) {
            const weddingMarker = responseData.weddingMarker;
            console.log('웨딩마커:', weddingMarker);
            const weddingAddress = responseData.weddingAddress;
            updateMaps(weddingMarker, weddingAddress);
        }
    } catch (error) {
        console.error('지도 설정 중 오류 발생:', error);
    }
}

function updateMaps(weddingMarker, weddingAddress) {
    if (weddingMarker && weddingMarker !== '') {
        const coords = JSON.parse(weddingMarker);
        updateMapsWithCoords(coords.lat, coords.lng);
    } else {
        updateMapsWithAddress(weddingAddress);
    }
}

function updateMapsWithCoords(lat, lng) {
    const coords = new kakao.maps.LatLng(lat, lng);

    // setTimeout을 사용하여 마커와 지도의 중심 업데이트에 1800ms 지연을 추가
    setTimeout(() => {
        markers.forEach(marker => {
            const map = marker.getMap();
            marker.setPosition(coords);
            map.setCenter(coords);
        });
    }, 1800); // 1800ms 지연
}

function updateMapsWithAddress(weddingAddress) {
    const address = weddingAddress === '' ? '서울 마포구 성산동 250-2' : weddingAddress;
    const geocoder = new kakao.maps.services.Geocoder();

    geocoder.addressSearch(address, function(result, status) {
        if (status === kakao.maps.services.Status.OK) {
            const coords = new kakao.maps.LatLng(result[0].y, result[0].x);

            // setTimeout을 사용하여 마커와 지도의 중심 업데이트에 1800ms 지연을 추가
            setTimeout(() => {
                markers.forEach(marker => {
                    const map = marker.getMap();
                    marker.setPosition(coords);
                    map.setCenter(coords);
                });
            }, 1800); // 1800ms 지연
        }
    });
}
