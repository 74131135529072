export function designSelect(product_ID){
    console.log(product_ID);
    let designTemplates = document.querySelectorAll('.TitleSection');
    
    designTemplates.forEach((template) => {
        if(template.getAttribute('data-value') === product_ID){
            template.style.display = 'block';
        } else {
            template.style.display = 'none';
        }
    });
}
