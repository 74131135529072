export function design_name_order(design_name_order_status) {

    const toggle_BrideGroomElements = document.querySelectorAll('.BrideGroom');

    // 초기 상태 설정
    if (design_name_order_status === 0) {
        toggle_BrideGroomElements.forEach(element => {
            element.style.flexDirection = 'row';
        });
    } else {
        toggle_BrideGroomElements.forEach(element => {
            element.style.flexDirection = 'row-reverse';
        });
    }
}
