export function gift_status(dbInfo) {

    const toggle_giftSection = document.getElementById('giftSection');
    const gift_floatingBtn = document.querySelector('.flower_floatingBtn');
    console.log(dbInfo.gift_status);
    
    // 초기 상태 설정
    if (dbInfo.gift_status === 1) {
        toggle_giftSection.style.display = 'flex';
        
        if (dbInfo.gift_flower_type === 'flower_banner') {
            if (gift_floatingBtn) {
                gift_floatingBtn.style.display = 'none';
            }
        } else {
            if (gift_floatingBtn) {
                gift_floatingBtn.style.display = 'flex';
            }
            toggle_giftSection.style.display = 'none';
        }
        
    } else {
        toggle_giftSection.style.display = 'none';
        if (gift_floatingBtn) {
            gift_floatingBtn.style.display = 'none';
        }
    }
}
