import { fetchAccountData } from '../../api/api_account.js'; // 계좌번호 정보를 POST하는 함수
import { insertIntoDB } from '../../api/api_dbInsert.js'; // 계좌번호 정보를 POST하는 함수
import { saveInvitation } from '../../api/api_saveInvitation.js'; // 계좌번호 정보를 POST하는 함수
import { handleSaveResponse } from '../../api/api_saveInvitation_modules/handleSaveResponse.js'; // 템플릿 저장시, 반환되는 결과값

import { showLoadingSpinner, hideLoadingSpinner } from '../../components/spinner.js';
import { dataCollector } from '../../views/template_modules/template_saveTemplates_modules/dataCollertor.js'; // 
import { template_design_data } from './template_saveTemplates_modules/template_design/template_design_data.js';
import { template_transportation_data } from './template_saveTemplates_modules/template_weddingInfo/template_transportation_data.js';
import { checkSession } from '../common_modules/checkSession.js';


export function saveTemplates(dynamicValue) {

    let saveButtons = document.querySelectorAll('.ProgressSave');
    let saveAlert = document.querySelector('.ModalSaveComplete');
    let dimmed = document.getElementById('SaveCompleteDimmed');
    let deleteBtn = document.querySelector('.close-button');
    const scrollPreventEvent = document.body;

    // 저장하기 클릭시 동작하는 코드 정리
    let toggleElements = () => {
        hideLoadingSpinner();
        dimmed.classList.toggle('is-active');
        saveAlert.classList.toggle('is-active');
        deleteBtn.style.display = 'none';
        scrollPreventEvent.style.overflowY = 'scroll';
    };

    if (saveButtons) {
        saveButtons.forEach(saveButton => {
            saveButton.addEventListener('click', () => {
                // 세션 상태를 확인하는 함수 호출
                checkSession().then(() => {
                    console.log("세션정보 정상");

                    const ModalGroups = document.querySelector('.ModalGroups');
                    const ModalGroupsData = ModalGroups ? ModalGroups.outerHTML : '';

                    // 좌측 사이드 템플릿과 이미지URL을 DB에 저장하는 함수
                    const sideContentsEl = document.querySelector('.side_contents');
                    const sideContents = sideContentsEl ? sideContentsEl.outerHTML : '';

                    showLoadingSpinner();

                    // 저장할 데이터를 가져오는 모듈 함수를 불러옵니다.
                    const DBData = dataCollector(dynamicValue);
                    const template_design_dataSet = template_design_data(dynamicValue);

                    // 서버로 전송할 데이터를 객체로 정의합니다.
                    const requestData = {
                        ModalGroups: ModalGroupsData,
                        sideContents: sideContents, // 변경된 HTML 코드를 전송
                        DBData: DBData,
                        template_design_data: template_design_dataSet,
                    };

                    // 계좌정보를 POST하는 API 모듈
                    let accountDataInfo = DBData.accountInfoData;
                    fetchAccountData(dynamicValue, accountDataInfo)
                        .then(response => {
                            console.log(response);
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });

                    // DB Post API 모듈
                    insertIntoDB(DBData)
                        .then(response => {
                            if (response.ok) {
                                console.log('데이터가 성공적으로 삽입되었습니다.');

                                // 초대장 저장 모듈 
                                saveInvitation(dynamicValue, requestData)
                                    .then(response => {
                                        toggleElements();
                                        handleSaveResponse(response, dynamicValue);
                                    }).catch(error => {
                                        console.log(error);
                                    });
                            } else {
                                console.error('데이터 삽입에 실패했습니다.');
                                handleSessionTimeout();
                            }
                        })
                        .catch(error => {
                            console.error('API 호출 중 오류가 발생했습니다:', error);
                            handleSessionTimeout();
                        });
                }).catch(error => {
                    console.error('세션 확인 중 오류가 발생했습니다:', error);
                    alert('로그인 세션이 만료되었습니다. 로그아웃 및 재로그인 후 재시도 해주세요.');
                    window.location.href = '/api_Auth/login';
                });
            });
        });
    }

    function handleSessionTimeout() {
        alert('로그인 세션이 만료되었습니다. 로그아웃 및 재로그인 후 재시도 해주세요.');

        // 서버에서 세션 정보를 가져오는 함수
        async function getSessionData() {
            try {
                const response = await fetch('/api_GetSession');
                if (response.ok) {
                    const data = await response.json();
                    // 세션 데이터가 존재하면 checkSession 함수 호출
                    if (data) {
                        // 1시간마다 세션 상태 확인
                        setInterval(checkSession, 3600000); // 3600000 밀리초 = 1시간
                    }
                    return data;
                } else {
                    throw new Error('Failed to fetch session data');
                }
            } catch (error) {
                console.error('Error fetching session data:', error);
                return null;
            }
        }

        getSessionData().then(sessionData => {
            if (!sessionData) {
                throw new Error('세션 데이터를 가져올 수 없습니다.');
            }

            const { naverAccessToken, naverEmail } = sessionData;

            fetch('/api_Auth/logout', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ naverAccessToken, naverEmail })
            })
                .then(response => {
                    console.log('로그아웃 응답 상태:', response.status); // 디버깅
                    return response.json();
                })
                .then(data => {
                    console.log('로그아웃 응답 데이터:', data); // 디버깅
                    if (data.success) {
                        console.log('로그아웃 성공');
                        // 서버 세션 삭제 요청
                        return fetch('/api_Auth/destroySession', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' }
                        });
                    } else {
                        console.log('로그아웃 실패');
                        throw new Error('로그아웃 실패');
                    }
                })
                .then(response => {
                    console.log('세션 삭제 응답 상태:', response.status); // 디버깅
                    return response.json();
                })
                .then(data => {
                    console.log('세션 삭제 응답 데이터:', data); // 디버깅
                    if (data.success) {
                        console.log('세션 삭제 성공');
                        sessionStorage.removeItem('naver_access_token');
                        sessionStorage.removeItem('naver_email');
                        location.replace('/');
                    } else {
                        alert('서버 세션 삭제 실패');
                    }
                })
                .catch(error => {
                    console.error('오류 발생:', error); // 디버깅
                })
                .finally(() => {
                    window.location.href = '/api_Auth/login';
                });
        });
    }




    let closeBtn = document.getElementById('ClosedView');
    closeBtn.addEventListener('click', () => {
        toggleElements();
    });
}
