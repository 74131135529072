// animationEffects.js
let observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
      entry.target.classList.add('is-visible');
    } else {
      entry.target.classList.remove('is-visible');
    }
  });
}, { threshold: 0.1 });

function observeElements(elements) {
  // 여기서 elements는 DOM 요소의 컬렉션
  elements.forEach(element => {
    observer.observe(element);
  });
}




export { observer, observeElements };

export function setupScrollAnimations() {
    let fadeinParents = document.querySelectorAll('.side_contents');
    observeElements(fadeinParents);
  
    let fadeinElements = document.querySelectorAll('.side_contents > *');
    observeElements(fadeinElements);
  
    let OrderSection = document.querySelectorAll('.OrderSection > *');
    observeElements(OrderSection);
}
