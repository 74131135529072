export function addressApply(templateId) {
  let kakaoCoords;  // 카카오맵에서 얻은 좌표를 저장할 변수

  fetch(`/api_DBInsert/${templateId}`, {
    method: 'GET'
  })
    .then(response => response.json())
    .then(data => {
      const weddingAddress = data.weddingAddress;
      const weddingMarker = data.weddingMarker;
      const defaultAddress = '서울 마포구 성산동 250-2';

      if (weddingMarker && weddingMarker !== '') {
        const markerCoords = JSON.parse(weddingMarker);
        kakaoCoords = new kakao.maps.LatLng(markerCoords.lat, markerCoords.lng);
      } else {
        const address = weddingAddress !== '' ? weddingAddress : defaultAddress;

        // 카카오맵 로딩 및 여러 지도 표시
        kakao.maps.load(() => {
          const geocoder = new kakao.maps.services.Geocoder();
          geocoder.addressSearch(address, function (result, status) {
            if (status === kakao.maps.services.Status.OK) {
              kakaoCoords = new kakao.maps.LatLng(result[0].y, result[0].x);
              applyKakaoMaps(kakaoCoords);
            }
          });
        });
      }

      if (kakaoCoords) {
        applyKakaoMaps(kakaoCoords);
      }

      function applyKakaoMaps(coords) {
        // .mapinfo 클래스를 가진 모든 요소 선택
        const mapContainers = document.querySelectorAll('.mapinfo');

        // 각 mapContainer 요소에 대해 지도 생성
        mapContainers.forEach((mapContainer, index) => {
          const mapOptions = {
            center: coords, // 중심 좌표 설정
            level: 3 // 지도 확대 수준
          };

          // 지도 생성
          const map = new kakao.maps.Map(mapContainer, mapOptions);

          // 마커 생성 및 지도에 표시
          const marker = new kakao.maps.Marker({
            map: map,
            position: coords
          });

          // 지도의 중심을 결과로 받은 좌표로 이동
          map.setCenter(coords);
        });
      }

      // 네이버 지도 길찾기
      const naverNav = document.getElementById('NaverNav');
      if (naverNav) {
        naverNav.addEventListener('click', () => {
          const geocoder = new kakao.maps.services.Geocoder();
          geocoder.addressSearch(weddingAddress, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
              const address = result[0].address_name;
              const encodedAddress = encodeURIComponent(address);
              const naverUrl = `https://map.naver.com/v5/search/${encodedAddress}`;
              window.open(naverUrl);
            } else {
              alert('주소를 찾을 수 없습니다.');
            }
          });
        });
      }

      // 카카오맵 길찾기
      const kakaoNav = document.getElementById('KakaoNav');
      if (kakaoNav) {
        kakaoNav.addEventListener('click', () => {
          const geocoder = new kakao.maps.services.Geocoder();
          geocoder.addressSearch(weddingAddress, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
              const address = result[0].address_name;
              const encodedAddress = encodeURIComponent(address);
              const kakaoUrl = `https://map.kakao.com/link/search/${encodedAddress}`;
              const isKakaoNavi = (function () {
                const ua = navigator.userAgent.toLowerCase();
                return ua.indexOf('kakaonavi') > -1;
              })();

              if (isKakaoNavi) {
                window.location.href = `kakaonavi://route?name=${encodedAddress}`;
              } else {
                window.open(kakaoUrl);
              }
            } else {
              alert('주소를 찾을 수 없습니다.');
            }
          });
        });
      }

    })
    .catch(error => {
      console.error('오류:', error);
    });
}
