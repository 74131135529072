// 메인 JavaScript 파일

// 템플릿 메뉴에 진입했을때, coachmark json파일을 가져옵니다.
import { getCoachmarkJson } from './template_modules/template_common_modules/coachmarkModules.js';
import { tooltip_box_appear } from './template_modules/template_common_modules/tooltipbox.js'; // 툴팁박스 로드

// 템플릿영역에서 헤더를 position relative시키는 모듈을 가져옵니다.
import { header } from '../views/template_modules/template_common_modules/header.js';
import { categorySelect } from '../views/template_modules/template_common_modules/categorySelect.js';

// 사이드네비 접기/펴기 기능
import { sideNav_folded } from './template_modules/template_common_modules/sideNav_folded.js';

// 템플릿 페이지 영역의 컨트롤 모듈들을 가져옵니다.
import template_accountInfo from './template_modules/template_accountInfo.js';
import { template_board } from './template_modules/template_board.js';

import template_coupleInfo from './template_modules/template_coupleInfo.js';
import template_design from './template_modules/template_design.js'; // 빈칸 제거
import template_gallery from './template_modules/template_gallery.js';
import template_invitationWords from './template_modules/template_invitationWords.js'; // 빈칸 제거
import template_styling from './template_modules/template_styling.js'; // 빈칸 제거
import template_marriageInfo from './template_modules/template_marriageInfo.js';
import template_orderItems from './template_modules/template_orderItems.js';
import { template_information } from './template_modules/template_information.js';
import { template_gift } from './template_modules/template_gift.js';
import { template_lastImg } from './template_modules/template_lastImg.js';


// 초대장에 스크롤 애니메이션을 적용하는 모듈을 가져옵니다.
import { setupScrollAnimations } from '../utils/scrollAnimation.js';
import { setupScrollEventListeners } from '../views/template_modules/template_common_modules/scrollEvent.js';

// 미리보기 버튼을 클릭시 동작하는 모듈을 가져옵니다.
import { previewTemplates } from '../views/template_modules/template_common_modules/templatePreview.js';

// 갤러리 스와이퍼 동작 모듈을 가져옵니다.
import { gallerySwiper } from './template_modules/template_common_modules/gallerySwiper.js';
// 갤러리 이미지 노출 모듈을 가져옵니다.
import { galleryAppear } from './template_modules/template_common_modules/galleryImgAppear.js';
// 템플릿에서 사용하는 인풋과 셀렉트박스들의 입력관련 로직 모듈을 가져옵니다.
import { inputMappingManager } from '../utils/inputMappingManager.js';
// 계좌번호 복사 로직 모듈을 가져옵니다.
import { copyAccount } from '../views/template_modules/template_common_modules/copyAccount.js'
import { handleKakaoPayLinks } from './template_modules/template_common_modules/copyKakaoPay.js';

// 템플릿에서 사용하는 인풋과 셀렉트박스들의 입력관련 로직 모듈을 가져옵니다.
import { saveTemplates } from '../views/template_modules/template_saveTemplates.js';

import { effectNoneApply } from './invitation_modules/effect_none_apply.js';

import { test } from '../../views/partials/titleTemplates/DesignArch/DesignArch.js'

import { GetDBInfo } from '../api/api_dbInsert.js';
import { fontSizeApply } from './template_modules/template_common_modules/font_size.js';

import { checkContactButton } from './template_modules/template_coupleInfo_modules/check_contact_button.js';
import { template_video_upload } from './template_modules/template_video_upload.js';
import { updateMarkerPosition } from '../utils/map/mapControl.js';
import { template_rsvp } from './template_modules/template_rsvp.js';
import { kakaopay_link_alert } from './template_modules/template_account_modules/account_kakaypay_alert.js';




// 현재 URL을 가져옵니다.
const currentUrl = window.location.href;

// URL을 체크하여 모듈 실행 여부를 결정합니다.
const pathArray = currentUrl.split('/');
const dynamicValue = pathArray[pathArray.length - 1];
const disableMainPageUrl = `${pathArray[0]}//${pathArray[2]}/`; // 동작하지 않아야 할 URL
const disableMyPageUrl = `${pathArray[0]}//${pathArray[2]}/api_Mypage`;
const disableAuth = `${pathArray[0]}//${pathArray[2]}/api_Auth/login`;
const disableUrl = `${pathArray[0]}//${pathArray[2]}/api_GetInvitation/${dynamicValue}`;
// 새로운 비활성화 URL 조건 추가
const disableCheckOutUrl = `${pathArray[0]}//${pathArray[2]}/api_getCheckOut/${pathArray[4]}`;


// 조건에 맞는 URL이면 함수 실행을 멈춤
if (currentUrl === disableCheckOutUrl || currentUrl === disableMainPageUrl || currentUrl === disableMyPageUrl || currentUrl === disableAuth || currentUrl === disableUrl) {
  // URL이 비활성화 조건에 맞으면 아무것도 하지 않음
} else {
  // DOM이 로드된 후에 각 모듈의 함수를 실행
  document.addEventListener('DOMContentLoaded', () => {

    const foldBtn = document.querySelector('.fold_button_wrap');
    const foldBtnIcon = document.querySelector('.fold_button_icon');
    const sideNav = document.querySelector('.right_contents');
    const dimmed = document.querySelector('.contents_dimmed_overlay');
    const header_component = document.querySelector('.header');

    if(window.innerWidth <= 560){
      sideNav.classList.toggle('folded');
      foldBtn.classList.toggle('folded');
      foldBtnIcon.classList.toggle('folded');
      dimmed.classList.toggle('folded');
    }

    function updateBodyOverflow() {
        if (window.innerWidth <= 560) {
            document.body.style.overflow = 'hidden';
            if(sideNav.classList.contains('folded')){
              header_component.style.display = 'block';
            }else{
              header_component.style.display = 'none';
            }
        } else {
            header_component.style.display = 'block';
            document.body.style.overflow = 'auto';
        }
    }

    // 초기 로드 시 한 번 실행
    updateBodyOverflow();

    // 브라우저 크기 변경 시 실행
    window.addEventListener('resize', () => {
        updateBodyOverflow();
    });




    // 공통 모듈 로드
    tooltip_box_appear(); //툴팁박스 로드
    getCoachmarkJson();// 코치마크 JSON파일 로드
    galleryAppear(); // 갤러리에 이미지 존재시, 노출 모듈
    sideNav_folded() // 사이드네비 접기 펴기 기능 모듈
    header(); //헤더 position relative 적용
    categorySelect(); // 카테고리 선택 js 모듈 활성화
    setupScrollAnimations(); // 템플릿 모듈이 로드된 후, 스크롤 애니메이션 적용 처리
    setupScrollEventListeners(); //인풋 클릭시 해당 위치로 이동하는 스크롤 적용 처리
    copyAccount(); // 계좌번호 복사 처리
    handleKakaoPayLinks(); //카카오페이 링크 이동
    kakaopay_link_alert(); //링크 복사방법 alert
    
    inputMappingManager.init(); // 인풋과 셀렉트박스들의 모든 입력 로직을 관리 및 처리합니다.

    effectNoneApply(); // 이펙트 미적용시 초대장에 적용


    // 비동기적으로 DB에서 정보를 가져오고, 결과가 도착하면 폰트 크기를 적용합니다.
    GetDBInfo(dynamicValue).then(dbInfo => {
      fontSizeApply(dbInfo.font_size);  // 폰트 크기 적용
      gallerySwiper(dbInfo.toggleSwitch_gallery_zoom_status); //갤러리 미리보기 스와이퍼 처리
      // 템플릿 뷰 로직 모듈 로드
      template_accountInfo(dbInfo.account_status);
      template_board(dynamicValue);
      template_coupleInfo();
      const decorationSection = dbInfo?.decoration_section || {
        designDecoration: 'label',
        designDecoration_statusData: 1
      };
      template_design(dynamicValue, dbInfo.design_name_order_status, decorationSection);
      template_invitationWords();
      template_styling();
      template_marriageInfo(dbInfo, currentUrl, dynamicValue);
      template_information(dynamicValue);
      template_video_upload(dynamicValue);
      template_orderItems();
      template_gallery(dbInfo, dbInfo.toggleSwitch_gallery_zoom_status);
      checkContactButton(dbInfo.wedding_contact);
      template_gift(dbInfo, dynamicValue);
      template_rsvp(dynamicValue);
      template_lastImg(dynamicValue);

      console.log('로드시 마커 인포' + dbInfo.weddingMarker);

    }).catch(error => {
      console.error('DB 정보를 가져오는데 실패했습니다:', error);
    });

    saveTemplates(dynamicValue); // 템플릿ID를 인자로 넣어, 저장버튼 클릭시 템플릿을 초대장으로 저장합니다.

  });

}

