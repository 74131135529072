export function information_apply(information_status) {
    const informationSections = document.querySelectorAll('.informationSection');

    // information_status가 1이면 display를 flex로, 0이면 none으로 설정
    const displayStyle = information_status === 1 ? 'flex' : 'none';

    informationSections.forEach(section => {
        section.style.display = displayStyle;

        // 각 section 내의 .informationSection_swiper_item_img img 요소를 찾음
        const swiperImages = section.querySelectorAll('.informationSection_swiper_item_img img');

        swiperImages.forEach(swiperImage => {
            // 이미지 src를 콘솔에 출력하여 확인
            console.log(`Image src: ${swiperImage.src}`);

            // 이미지 src가 유효하지 않으면 부모 요소를 숨김
            if (!swiperImage.src || swiperImage.src.endsWith("about:blank") || swiperImage.src === window.location.href || swiperImage.src === "") {
                console.log("Hiding element because src is invalid or empty.");
                swiperImage.closest('.informationSection_swiper_item_img').style.display = 'none';
            }
        });
    });
}
