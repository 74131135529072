import { updateCalendar } from './updateCalendar.js';
import { createKSTDate } from './createKSTDate.js';
import { updateDateFormat_template } from '../../views/template_modules/template_marriageInfo_modules/updateDateFormat_template.js';
import { design_simple_line_01_date_update } from './titleGraphic_date_update.js';
import { design_simple_line_02_date_update } from './titleGraphic_date_update.js';
import { design_simple_line_03_date_update } from './titleGraphic_date_update.js';
import { updateInnerText } from './update_inner_text.js';

export function dateChange() {
    let dateInput = document.getElementById("date");
    let dateArr = dateInput.value.split('-');

    let selectedDate = createKSTDate(dateArr);

    updateCalendar(selectedDate);  // updateCalendar 함수 호출

    let days = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];

    let dayName = days[selectedDate.getDay()];
    let month = `${parseInt(dateArr[1], 10)}`;
    let day = `${parseInt(dateArr[2], 10)}`;

    console.log('month:', month);
    console.log('day:', day);
    console.log('selecteddate', selectedDate)

    document.getElementById("textDate_calendar_month").innerText = month;
    document.getElementById("textDate_calendar_day").innerText = day;
    document.getElementById("CalWeddingDayTitle").innerText = dayName;
    document.getElementById("RSVP_CalWeddingDayTitle").innerText = dayName;
    document.getElementById("RSVP_POPUP_CalWeddingDayTitle").innerText = dayName;

    updateInnerText(document.querySelectorAll(".rsvp_month"), month);
    updateInnerText(document.querySelectorAll(".rsvp_day"), day);
    updateInnerText(document.querySelectorAll(".RSVP_WeddingDay"), dayName);

    design_simple_line_01_date_update(selectedDate); // titleGraphic_date_update 함수 호출
    design_simple_line_02_date_update(selectedDate); // titleGraphic_date_update 함수 호출
    design_simple_line_03_date_update(selectedDate); // titleGraphic_date_update 함수 호출

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let dDay = Math.floor((selectedDate - today) / (1000 * 60 * 60 * 24));
    document.getElementById('dday').innerText = `${dDay}일`;

    let currentMonthDates = document.querySelectorAll('.dates .current');
    currentMonthDates.forEach(date => {
        date.classList.remove('today');
    });

    currentMonthDates[parseInt(dateArr[2]) - 1].classList.add('today');

    // 날짜 형식 업데이트
    const dateFormatElement = document.querySelector('.titleImg_item_contents_item_button_weddingNotationMethod_wrap.active[data-value]');

    if (dateFormatElement) {
        const dataValue = dateFormatElement.getAttribute('data-value');
        
        // 필요한 경우 updateDateFormat 함수를 수정하여 dataValue를 인자로 전달
        updateDateFormat_template(dataValue);
    }
}
