const effectTabHandler = {
    // 초기화 함수
    init() {
        this.setupEventListeners();
    },

    // 이벤트 리스너 설정 함수
    setupEventListeners() {
        // 모든 이펙트 탭에 대한 클릭 이벤트 리스너 추가
        const effectTabs = document.querySelectorAll('ul.TabListEffect li');
        effectTabs.forEach(tab => {
            tab.addEventListener('click', this.handleEffectTabClick.bind(this));
        });
    },

    // 이펙트 탭 클릭 핸들러
    handleEffectTabClick(event) {
        const effectTabs = document.querySelectorAll('ul.TabListEffect li');
        // 모든 탭의 'Active' 클래스 제거
        effectTabs.forEach(tab => tab.classList.remove('Active'));
        // 클릭된 탭에 'Active' 클래스 추가
        event.currentTarget.classList.add('Active');

        // .effects 클래스를 가진 모든 요소 선택
        const effectImages = document.querySelectorAll('.effects');
        effectImages.forEach(effectImage => {
            switch (event.currentTarget.id) {
                case 'effect_none':
                    // 'NoneEffect' 선택 시 모든 이미지 숨김
                    effectImage.src = "";
                    effectImage.style.display = "none";
                    break;
                case 'effect_cherryblossom':
                    // 'CherryblossomEffect' 선택 시 해당 이미지 표시
                    effectImage.src = "../Resource/assets/Effect/effect_cherryblossom.png";
                    effectImage.style.display = "block";
                    break;
                case 'effect_snow':
                    // 'SnowEffect' 선택 시 해당 이미지 표시
                    effectImage.src = "../Resource/assets/Effect/effect_snow.png";
                    effectImage.style.display = "block";
                    console.log('effect');
                    break;
                default:
                    console.log('null');
            }
        });
    }

};

export { effectTabHandler };
