import { invitationStyle } from './invitation_modules/invitationStyle.js';
import { setupScrollAnimations } from '../utils/scrollAnimation.js';
import { setupAccordion } from '../views/template_modules/template_accountInfo.js';
import { dateChangeInvitation } from '../utils/calendar/dateChangeInvitation.js';
import { copyAccount } from '../views/template_modules/template_common_modules/copyAccount.js'
import { gallerySwiper } from './template_modules/template_common_modules/gallerySwiper.js';
import { template_board } from './template_modules/template_board.js';
import { checkOut } from './invitation_modules/checkout.js'
import { musicControl } from './invitation_modules/musicControl.js';
import { shareKakao } from '../utils/kakaoShare.js'
import { addressApply } from './invitation_modules/addressControl.js';
import { GetDBInfo } from '../api/api_dbInsert.js';
import { copyLink } from './invitation_modules/copyLink.js';
import { designSelect } from './invitation_modules/designSelect.js';
import { invitation_template_design } from './invitation_modules/invitation_template_design.js'
import { galleryAppear } from './template_modules/template_common_modules/galleryImgAppear.js';
import { moreBtnStatus } from './template_modules/template_gallery.js';
import { showMoreImages } from './template_modules/template_gallery.js';
import { effectNoneApply } from './invitation_modules/effect_none_apply.js';
import { fontSizeApply } from './template_modules/template_common_modules/font_size.js';
import { fontStyle_apply } from './template_modules/template_common_modules/font_style.js';
import { information_apply } from './invitation_modules/information_apply.js';
import { setupAccountInfo_Apply } from './invitation_modules/setupAccountInfo.js';
import { contacts } from './invitation_modules/inviteGreetings/contact.js';
import { initializeContactButtons } from './template_modules/template_coupleInfo_modules/init_contact_button.js';
import { checkContactButton } from './template_modules/template_coupleInfo_modules/check_contact_button.js';
import { design_name_order } from './invitation_modules/design_info/design_name_order_status.js';
import { video_status } from './invitation_modules/video_info/video_status.js';
import { gift_status } from './invitation_modules/gift_info/gift_status.js';
import { gift_alert } from './template_modules/template_giftInfo_modules/gift_alert.js';
import { rsvp_alert } from './template_modules/template_rsvp_modules/rsvp_alert.js';
import { rsvp_toggle_dimmed } from './template_modules/template_rsvp_modules/rsvp_popup.js';
import { handleKakaoPayLinks } from './template_modules/template_common_modules/copyKakaoPay.js';
import { displayDecoration } from './template_modules/template_design_modules/displayDecoration.js';
import { updateDateFormat } from './template_modules/template_marriageInfo_modules/updateDateFormat.js';
import { galleryTypeAdaption } from './template_modules/template_gallery_modules/gallery_type_adaption.js';

document.addEventListener("DOMContentLoaded", async function () {
  // 카카오 SDK 초기화

  const currentUrl = window.location.href;
  const pathArray = currentUrl.split('/');
  const templateId = pathArray[pathArray.length - 1]; // 마지막 요소인 동적인 값
  const disableMainPageUrl = `${pathArray[0]}//${pathArray[2]}/`; // 동작하지 않아야 할 URL
  const disableCreateInvitationUrl = `${pathArray[0]}//${pathArray[2]}/api_CreateTemplate/${templateId}`; // 동작하지 않아야 할 URL
  const disableEditInvitationUrl = `${pathArray[0]}//${pathArray[2]}/api_EditInvitation/${templateId}`; // 동작하지 않아야 할 URL
  const disableMyPageUrl = `${pathArray[0]}//${pathArray[2]}/api_Mypage`; // 동작하지 않아야 할 URL
  const disableAuth = `${pathArray[0]}//${pathArray[2]}/api_Auth/login`; // 동작하지 않아야 할 URL
  const disableCheckOutUrl = `${pathArray[0]}//${pathArray[2]}/api_getCheckOut/${pathArray[4]}`;

  if (currentUrl === disableCheckOutUrl || currentUrl === disableCreateInvitationUrl || currentUrl === disableEditInvitationUrl || currentUrl === disableMainPageUrl || currentUrl === disableMyPageUrl || currentUrl === disableAuth) {
    return;
  } else {
    
    Kakao.init('aead73005a77433d268644553628caba');

    effectNoneApply(); // 이펙트 미적용시 초대장에 적용

    invitation_template_design(); // 초대장 템플릿 디자인별 모듈 로드

    copyLink(currentUrl); // 링크 복사 모듈
    addressApply(templateId);
    copyAccount(); // 계좌번호 복사 처리
    handleKakaoPayLinks(); //카카오페이 링크 이동
    checkOut(templateId) // 결제로직을 호출하는 모듈

    // .weddingAddressCopy 클래스를 가진 요소를 찾습니다.
    let addressCopy = document.querySelector('.weddingAddressCopy');

    // 'WeddingAddress' 아이디를 가진 요소에서 텍스트를 읽어옵니다.
    let addressInfo = document.getElementById('WeddingAddress').innerText;

    // addressCopy 요소에 클릭 이벤트 리스너를 추가합니다.
    addressCopy.addEventListener('click', () => {
      // 클립보드에 addressInfo 값을 복사합니다.
      navigator.clipboard.writeText(addressInfo).then(() => {
        // 성공적으로 복사되면, 사용자에게 알림을 띄웁니다.
        alert('주소를 복사했습니다.');
      }).catch(err => {
        // 복사에 실패했을 경우, 오류 메시지를 콘솔에 출력합니다.
        console.error('주소를 복사하는데 실패했습니다.', err);
      });
    });


    console.log(templateId);
    // 청첩장 공통요소
    invitationStyle(); //청첩장 동적 스타일 적용
    galleryAppear(); // 갤러리에 이미지 존재시, display block시켜주는 함수



    // DB에서 정보 가져와서 콘솔에 출력
    try {
      const dbInfo = await GetDBInfo(templateId); // 동적인 값으로 DB 조회
      const decorationSection = dbInfo?.decoration_section || {
        designDecoration: 'label',
        designDecoration_statusData: 1
      };
      displayDecoration(decorationSection);

      let dataFormatValue = dbInfo.weddingDate_format[0]['data-value'];

      designSelect(dbInfo.product_ID); // 선택된 상품을 display Block 시킴
      setupAccordion(dbInfo.account_fold_status); // 계좌번호 아코디언 애니메이션 적용
      dateChangeInvitation(templateId, dataFormatValue); // 서버로부터 예식날짜를 불러와서 청첩장에 렌더링 해주는 모듈 
      design_name_order(dbInfo.design_name_order_status);
      fontSizeApply(dbInfo.font_size)
      fontStyle_apply(dbInfo.font_type);
      console.log(dbInfo);
      setupAccountInfo_Apply(dbInfo.account_status);
      information_apply(dbInfo.information_status);
      gallerySwiper(dbInfo.toggleSwitch_gallery_zoom_status); //갤러리 스와이퍼 모듈 적용
      video_status(dbInfo.video_status);
      gift_status(dbInfo);
      gift_alert(templateId);

      checkContactButton(dbInfo.wedding_contact);
      rsvp_alert(templateId, dbInfo.rsvpStatusData); //RSVP 입력창
      rsvp_toggle_dimmed(dbInfo.rsvpPopupData); //RSVP 팝업창
      console.log(dbInfo);
      galleryTypeAdaption(dbInfo); // 갤러리 타입에 따른 스타일 적용



    } catch (error) {
      console.error('DB 정보 조회 중 오류 발생:', error);
    }

    setupScrollAnimations(); // 페이지가 로드된 후, 스크롤 애니메이션 적용 처리
    // 청첩장 컴포넌트별 모듈
    musicControl(templateId); // 음악 재생 모듈 로드
    template_board(templateId); // 방명록 조회/작성 처리

    // 푸터 공통요소
    let KakaoShare = document.getElementById('KakaoShareBtn');
    KakaoShare.addEventListener('click', () => shareKakao(templateId));

  }

  moreBtnStatus(document.getElementById('LoadMoreBtn'));

  showMoreImages('.grid-container', '#LoadMoreBtn');

  contacts(); // 초대사람에게 인사말 전달하는 모듈
  initializeContactButtons(); //연락처 클릭시 동작 함수 모듈
});






