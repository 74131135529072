export function checkOut(templateId) {
    const waterMarkDelBtns = document.querySelectorAll('.waterMarkDel');

    waterMarkDelBtns.forEach(btn => {
        btn.addEventListener('click', async () => {
            try {
                // 서버에서 세션 정보를 가져오는 함수
                async function getSessionData() {
                    console.log('세션 정보를 가져오는 중...'); // 디버깅
                    const response = await fetch('/api_GetSession');
                    if (response.ok) {
                        const data = await response.json();
                        console.log('세션 정보:', data); // 디버깅
                        return data;
                    } else {
                        throw new Error('Failed to fetch session data');
                    }
                }

                const sessionData = await getSessionData();
                const naverAccessToken = sessionData.naverAccessToken;
                const naverEmail = sessionData.naverEmail;

                console.log('워터마크 삭제 버튼 클릭됨');
                console.log('네이버 액세스 토큰:', naverAccessToken);
                console.log('네이버 이메일:', naverEmail);

                if (naverAccessToken && naverEmail) {
                    console.log('리디렉션 경로:', `/api_getCheckOut/?info=${encodeURIComponent(templateId)}`);
                    window.location.href = `/api_getCheckOut/?info=${encodeURIComponent(templateId)}`;
                } else {
                    console.log('로그인이 필요합니다. 로그인 페이지로 이동합니다.');
                    window.location.href = '/api_Auth/login';
                }
            } catch (error) {
                console.error('세션 정보를 가져오는 중 오류 발생:', error);
                // 오류가 발생했을 경우 로그인 페이지로 리디렉션
                window.location.href = '/api_Auth/login';
            }
        });
    });
}
