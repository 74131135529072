import { showLoadingSpinner, hideLoadingSpinner } from '../../components/spinner.js';
import uploadImagesToServerAPI from '../../api/api_multiImgUpload.js'
import deleteImageFromServer from '../../api/api_deleteMultiImg.js'
import { galleryTypeAdaption } from './template_gallery_modules/gallery_type_adaption.js';

// 템플릿 갤러리영역 모듈
function template_gallery(dbInfo, toggleSwitch_gallery_zoom_status) {

    // 모든 탭 요소를 가져옵니다.
    const galleryTabItems = document.querySelectorAll('.TabItemGallery');

    // grid-container 요소를 가져옵니다.
    const gridContainer = document.querySelector('.grid-container');

    // grid-item 및 grid-thumb 요소를 가져옵니다.
    const gridItems = document.querySelectorAll('.grid-item');
    const gridThumbs = document.querySelectorAll('.grid-thumb');

    // 각 탭에 클릭 이벤트 리스너를 추가합니다.
    galleryTabItems.forEach(item => {
        item.addEventListener('click', function () {
            // grid-item 및 grid-thumb 요소를 클릭 시점에 다시 가져옵니다.
            const gridItems = document.querySelectorAll('.grid-item');
            const gridThumbs = document.querySelectorAll('.grid-thumb');

            // 현재 활성화된(active) 탭에서 active 클래스를 제거합니다.
            galleryTabItems.forEach(tab => tab.classList.remove('Active'));

            // 클릭된 탭에 active 클래스를 추가합니다.
            this.classList.add('Active');

            // 클릭된 탭의 id가 'MasonryType'인지 확인합니다.
            if (this.id === 'MasonryType') {
                gridContainer.classList.add('MasonryType');
                gridItems.forEach(gridItem => gridItem.classList.add('MasonryTypeItem'));
                gridThumbs.forEach(gridThumb => gridThumb.classList.add('MasonryTypeThumb'));
            } else {
                gridContainer.classList.remove('MasonryType');
                gridItems.forEach(gridItem => gridItem.classList.remove('MasonryTypeItem'));
                gridThumbs.forEach(gridThumb => gridThumb.classList.remove('MasonryTypeThumb'));
            }

            showMoreImages('.grid-container', '#LoadMoreBtn');
        });
    });



    // 초기화나 기타 로직에 대한 코드가 있을 경우
    galleryTypeAdaption(dbInfo);




    // 드래그하여 다중 이미지 업로드
    let dropzone = document.querySelector(".ImgGroupUpload_Btn");
    let MAX_IMAGES = 30;
    let IMAGES_PER_LOAD = 9;
    let LoadMore = document.getElementById('LoadMoreBtn');
    let displayedImages = 0;

    if (dropzone) {
        dropzone.addEventListener("dragover", function (event) {
            event.preventDefault();
            event.stopPropagation();
            dropzone.style.backgroundColor = "#eff0f5";
        });

        dropzone.addEventListener("dragleave", function (event) {
            event.preventDefault();
            event.stopPropagation();
            dropzone.style.backgroundColor = "";
        });

        dropzone.addEventListener("drop", function (event) {
            event.preventDefault();
            event.stopPropagation();
            dropzone.style.backgroundColor = "";
            const files = Array.from(event.dataTransfer.files);
            handleImageUpload(files);
        });
    }

    // 버튼 눌러 다중 이미지 업로드
    $('body').on('change', '.user_picked_files', function (event) {
        const files = Array.from(event.target.files);

        if (files.length > 30) {
            alert("이미지는 30개까지 첨부하실 수 있습니다.");
            return;
        }

        handleImageUpload(files);
    });

    // 이미지 압축 함수
    function compressImage(file, callback) {
        const reader = new FileReader();
        reader.onload = event => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const MAX_WIDTH = 2000; // 최대 가로 크기
                const MAX_HEIGHT = 2000; // 최대 세로 크기
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(blob => {
                    const compressedFile = new File([blob], file.name, {
                        type: 'image/jpeg',
                        lastModified: Date.now()
                    });
                    callback(compressedFile);
                }, 'image/jpeg', 1); // JPEG 포맷으로 압축
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    }

    // 지원되는 이미지 파일 형식 배열
    const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png', 'image/gif'];

    // 이미지 업로드 처리 함수
    function handleImageUpload(files) {
        let fileArray = [];
        let imageUrls = [];
        // 기존 이미지의 수를 기준으로 index 초기화
        let index = document.querySelectorAll('.grid-item').length;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // 파일 형식 검사
            if (!SUPPORTED_IMAGE_FORMATS.includes(file.type)) {
                alert(`The file ${file.name} has an unsupported format. Please upload images in JPEG, PNG, or GIF format.`);
                continue;
            }

            if (!file.type.match('image.*')) {
                alert(`The file ${file.name} is not an image.`);
                continue;
            }

            showLoadingSpinner();
            displayedImages++;

            compressImage(file, function (compressedFile) {
                const readImg = new FileReader();
                readImg.onload = (function (theFile) {
                    return function (e) {
                        fileArray.push(compressedFile); // 압축된 파일 추가

                        if (fileArray.length === files.length) {
                            uploadImagesToServer(fileArray, imageUrls, function () {
                                // 이미지 URL을 사용하여 화면에 표시하는 로직
                                for (let i = 0; i < imageUrls.length; i++) {
                                    index++;
                                    const imageUrl = imageUrls[i];

                                    // 썸네일 업데이트
                                    const image = document.querySelector(".grid-thumb[data-index='" + (index - 1) + "']");
                                    if (image) {
                                        image.src = imageUrl;
                                    }

                                    // 갤러리 타이틀 영역 표시
                                    $('.GalleryTitleArea').show();

                                    // 업로드된 파일 목록에 추가
                                    const newMultiImgID = `multiimg_${Date.now()}_${index}`;
                                    const newMultiImg = $(
                                        `<li class='multiimg draggable-item' id='${newMultiImgID}' file='${imageUrl}' draggable='true'>
                                        <img class='img-thumb' src='${imageUrl}' />
                                        <div class='cvf_delete_image' id='deleteimg' file='${imageUrl}' title='Cancel'><img class='delete-btn' src='../Resource/assets/Icon/Delete.svg' /></div>
                                    </li>`
                                    );
                                    $('.cvf_uploaded_files').append(newMultiImg);

                                    // storedFiles에 새로 업로드된 파일 정보 추가
                                    storedFiles.push({ id: newMultiImgID, url: imageUrl });

                                    // 스크롤 가능한 파일 목록 설정
                                    $('.cvf_uploaded_files').css('overflow-x', 'scroll');
                                    $('.cvf_uploaded_files').css('overflow-y', 'hidden');

                                    // 그리드 컨테이너에 이미지 추가
                                    const gridContainer = document.querySelector('.grid-container');
                                    let newGridItem;

                                    if (gridContainer.classList.contains('MasonryType')) {
                                        newGridItem = $(
                                            `<li class='grid-item MasonryTypeItem' file='${imageUrl}' data-index='${index}'>
                                            <img class='grid-thumb MasonryTypeThumb' id='appendimg' src='${imageUrl}' />
                                        </li>`
                                        );
                                    } else {
                                        newGridItem = $(
                                            `<li class='grid-item' file='${imageUrl}' data-index='${index}'>
                                            <img class='grid-thumb' id='appendimg' src='${imageUrl}' />
                                        </li>`
                                        );
                                    }

                                    $('.grid-container').css('display', 'grid');
                                    $('.grid-container').append(newGridItem);
                                }

                                // 갤러리 영역 표시
                                document.querySelector('.GalleryArea').style.display = "flex";

                                // '더보기' 버튼 제어
                                if (displayedImages > 9) {
                                    LoadMore.style.display = 'block';
                                }

                                if (displayedImages >= MAX_IMAGES) {
                                    LoadMore.style.display = 'block';
                                    document.querySelectorAll('.grid-item').forEach((item, idx) => {
                                        if (idx >= 9) item.style.display = 'none';
                                    });
                                } else if (displayedImages >= IMAGES_PER_LOAD) {
                                    document.querySelectorAll('.grid-item').forEach((item, idx) => {
                                        if (idx >= 9) item.style.display = 'none';
                                    });
                                }

                                // 삭제 버튼에 마우스 오버 이벤트 추가
                                fileArray.forEach(addHoverDeleteButton);

                                // 삭제 버튼에 클릭 이벤트 추가
                                const deleteButtons = document.querySelectorAll('.cvf_delete_image');
                                deleteButtons.forEach(function (button) {
                                    button.addEventListener('click', deleteImage);
                                });

                                hideLoadingSpinner();
                                // 드래그 앤 드롭 초기화
                                initSortable(); // 여기서 호출하여 새로 추가된 이미지에 드래그 이벤트 추가
                            });
                        }
                    };
                })(compressedFile);
                readImg.readAsDataURL(compressedFile);
            });
        }
    }



    // 기존의 이미지 업로드 함수
    function uploadImagesToServer(fileArray, imageUrls, callback) {
        let formData = new FormData();

        for (let i = 0; i < fileArray.length; i++) {
            const file = fileArray[i];
            formData.append("images", file);
        }

        let templateID = window.location.pathname.split('/').pop().replace('template_', '').replace('.html', '');
        let templateURL = `/api_MultiImgUpload/${templateID}`;

        uploadImagesToServerAPI(templateURL, formData)
            .then(data => {
                let AWSresURL = data.fileUrls;
                imageUrls.push(...AWSresURL);
                callback();
            })
            .catch(error => console.error('Error:', error));
    }

    const toggleArea = document.querySelector('.cvf_uploaded_files');
    const contentsArea = document.querySelector('.grid-container');

    let storedFiles = [];

    // 드래그 이벤트 초기화
    const initSortable = () => {
        let draggedItem = null;
        const sensitivity = 150; // 순서 변경을 감지할 범위 (px)

        const startDrag = (event) => {
            draggedItem = event.target.closest('.draggable-item');
            draggedItem.classList.add('highlight');
            draggedItem.style.transition = 'none'; // 애니메이션 비활성화
        };

        const endDrag = (event) => {
            if (draggedItem) {
                draggedItem.classList.remove('highlight');
                draggedItem.style.transition = ''; // 애니메이션 복원
                draggedItem = null;
                updateOrder();
            }
        };

        toggleArea.addEventListener('dragstart', (event) => {
            startDrag(event);
        });

        toggleArea.addEventListener('dragend', (event) => {
            endDrag(event);
        });

        toggleArea.addEventListener('touchstart', (event) => {
            startDrag(event);
        });

        toggleArea.addEventListener('touchend', (event) => {
            endDrag(event);
        });

        toggleArea.addEventListener('dragover', (event) => {
            event.preventDefault();
            handleDragOver(event);
        });

        toggleArea.addEventListener('touchmove', (event) => {
            event.preventDefault();
            handleDragOver(event.touches[0]);
        });

        const handleDragOver = (event) => {
            if (!draggedItem) return;

            const rect = toggleArea.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            const targetElement = document.elementFromPoint(event.clientX, event.clientY);
            if (targetElement && targetElement !== draggedItem && targetElement.parentElement === toggleArea) {
                const targetRect = targetElement.getBoundingClientRect();
                const targetY = targetRect.top - rect.top;
                const targetHeight = targetElement.offsetHeight;

                // 대상 요소의 상단 및 하단 경계와 비교
                const topBoundary = targetY - sensitivity;
                const bottomBoundary = targetY + targetHeight + sensitivity;

                if (y > topBoundary && y < bottomBoundary) {
                    const nextElement = (targetElement === draggedItem.nextElementSibling) ? targetElement.nextElementSibling : targetElement;
                    toggleArea.insertBefore(draggedItem, nextElement);
                }
            }
        };
    };

    // 순서 업데이트 함수
    const updateOrder = () => {
        const currentOrder = Array.from(toggleArea.children).map(child => child.id);
        const newStoredFiles = [];

        contentsArea.innerHTML = '';

        currentOrder.forEach((id, index) => {
            const originalFile = storedFiles.find(file => file.id === id);
            if (originalFile) {
                newStoredFiles.push(originalFile);

                contentsArea.style.display = 'grid';

                const gridContainer = document.querySelector('.grid-container');
                let newGridItem;

                if (gridContainer.classList.contains('MasonryType')) {
                    newGridItem = `
                    <li class='grid-item MasonryTypeItem' file='${originalFile.url}' data-index='${index + 1}'>
                        <img class='grid-thumb MasonryTypeThumb' id='appendimg' src='${originalFile.url}' loading="lazy" alt="Lazy Loaded Image" />
                    </li>`;
                } else {
                    newGridItem = `
                    <li class='grid-item' file='${originalFile.url}' data-index='${index + 1}'>
                        <img class='grid-thumb' id='appendimg' src='${originalFile.url}' loading="lazy" alt="Lazy Loaded Image" />
                    </li>`;
                }

                contentsArea.insertAdjacentHTML('beforeend', newGridItem);
            }
        });

        storedFiles = newStoredFiles;
    };


    // 초기 파일 설정 (예제용)
    const items = toggleArea.querySelectorAll('.cvf_uploaded_files > *');
    items.forEach(item => {
        item.classList.add('draggable-item');
        item.setAttribute('draggable', true);
        item.id = item.id || `file${Date.now()}`;

        const url = item.querySelector('img').src; // 이미지 URL을 가져옴
        storedFiles.push({ id: item.id, url: url });
    });

    // 드래그 앤 드롭 초기화
    initSortable();

    // 초기 순서 설정
    updateOrder();


    // 호버시 삭제버튼 노출 함수 정리 (with event delegation)
    function addHoverDeleteButton() {
        const cvfUploadedFiles = document.querySelector('.cvf_uploaded_files');

        if (cvfUploadedFiles) {
            cvfUploadedFiles.addEventListener('mouseover', function (event) {
                const targetElement = event.target.closest('.multiimg');
                if (targetElement) {
                    const deleteButton = targetElement.querySelector('.cvf_delete_image');
                    if (deleteButton) {
                        deleteButton.style.opacity = '1';
                    }
                }
            });

            cvfUploadedFiles.addEventListener('mouseout', function (event) {
                const targetElement = event.target.closest('.multiimg');
                if (targetElement) {
                    const deleteButton = targetElement.querySelector('.cvf_delete_image');
                    if (deleteButton) {
                        deleteButton.style.opacity = '0';
                    }
                }
            });
        }
    }

    // Call the function after the elements are loaded
    addHoverDeleteButton();

    // 클릭 시 이미지 삭제 함수 (이벤트 위임 사용)
    function deleteImage(e) {
        let templateID = window.location.pathname.split('/').pop().replace('template_', '').replace('.html', '');
        e.preventDefault();
        const file = this.parentElement.getAttribute('file');
        const viewimg = document.querySelector(`.grid-item[file='${file}']`);
        if (!viewimg) {
            return; // 이미지가 없으면 함수를 종료합니다.
        }

        this.parentElement.remove();
        let deleteImgSelector = viewimg.querySelector('.grid-thumb');
        let deleteImgURL = deleteImgSelector.getAttribute('src');
        viewimg.remove();

        // DELETE api 요청을 보낸 후, 서버로 데이터 전송
        deleteImageFromServer(templateID, deleteImgURL)
            .then(data => {
                // 서버 응답 처리
                console.log('서버 응답:', data);
            })
            .catch(error => {
                console.error('서버 요청 오류:', error);
            });

        let GalleryImages = document.querySelectorAll('.grid-item');
        // 갤러리 이미지가 없는 경우 갤러리 영역 숨김 처리
        if (GalleryImages.length < 1) {
            document.querySelector('.GalleryArea').style.display = "none";
        }
    }

    // Attach deleteImage function to delete buttons with event delegation
    const cvfUploadedFiles = document.querySelector('.cvf_uploaded_files');
    if (cvfUploadedFiles) {
        cvfUploadedFiles.addEventListener('click', function (event) {
            const targetDeleteButton = event.target.closest('.cvf_delete_image');
            if (targetDeleteButton) {
                deleteImage.call(targetDeleteButton, event);
            }
        });
    }

    function compressImage(file, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var img = new Image();
            img.src = event.target.result;
            img.onload = function () {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                var MAX_WIDTH = 2000;
                var MAX_HEIGHT = 2000;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(function (blob) {
                    var compressedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: Date.now(),
                    });
                    callback(compressedFile);
                }, file.type, 1);
            };
        };
    }

    let toggleImageFocus = document.getElementById('toggleSwitch_gallery_zoom'); // 토글버튼
    console.log('토글테스트' + toggleSwitch_gallery_zoom_status);

    if (toggleImageFocus) {
        // 페이지 로드 시 toggleSwitch_gallery_zoom_status 값을 확인하여 클래스 조정
        if (toggleSwitch_gallery_zoom_status === 1) {
            toggleImageFocus.classList.add('active');
        } else if (toggleSwitch_gallery_zoom_status === 0) {
            toggleImageFocus.classList.remove('active');
        }
    }

    if (toggleImageFocus) {
        toggleImageFocus.onclick = () => {
            toggleImageFocus.classList.toggle('active');
        };
    }

    // 표시된 이미지 수에 따라 더보기 버튼 표시
    moreBtnStatus(LoadMore);
    showMoreImages('.grid-container', '#LoadMoreBtn');
}

export function moreBtnStatus(LoadMore) {
    const items = document.querySelectorAll('.grid-item');

    items.forEach((item, index) => {
        if (index >= 9) {
            item.style.display = 'none';
        } else {
            item.style.display = '';
        }
    });

    if (items.length > 9) {
        LoadMore.style.display = 'block';
    } else {
        LoadMore.style.display = 'none';
    }
}

export function showMoreImages(containerSelector, loadMoreBtnSelector, delay = 100) {
    setTimeout(() => {
        const containers = document.querySelectorAll(containerSelector);
        const loadMoreBtn = document.querySelector(loadMoreBtnSelector);

        containers.forEach(container => {
            // container.classList.contains() 사용하여 정확한 클래스 확인
            const isMasonryType = container.classList.contains('MasonryType');
            const perLoad = isMasonryType ? 8 : 9;
            let items = container.querySelectorAll('.grid-item');
            let displayed = 0;

            loadMoreBtn.addEventListener('click', () => {
                items.forEach((item, index) => {
                    if (index < displayed + perLoad) {
                        item.style.display = 'block';
                    }
                });

                displayed += perLoad;
                if (displayed >= items.length) {
                    loadMoreBtn.style.display = 'none';
                }
            });

            // 초기 상태 설정
            function init() {
                console.log('Initializing grid items display state');
                items.forEach((item, index) => {
                    if (index >= perLoad) {
                        item.style.display = 'none';
                    } else {
                        item.style.display = 'block';
                    }
                });

                displayed = perLoad;

                if (items.length > perLoad) {
                    loadMoreBtn.style.display = 'block';
                } else {
                    loadMoreBtn.style.display = 'none';
                }
            }

            init();
        });
    }, delay); // 150ms의 딜레이 적용
}









export default template_gallery;
