function isValidKakaoPayLink(link) {
    // 카카오페이 링크 형식인지 검사하는 정규표현식 (https://pay.kakao.com 및 qr.kakaopay.com 형식)
    const kakaoPayRegex = /^(https:\/\/pay\.kakao\.com\/.*|https:\/\/qr\.kakaopay\.com\/.*|qr\.kakaopay\.com\/.*)$/;
    return kakaoPayRegex.test(link);
}

export function handleKakaoPayLinks() {
    let groomButtons = document.querySelectorAll('.Btn_KaKaoPay_groom');
    let brideButtons = document.querySelectorAll('.Btn_KaKaoPay_bride');

    groomButtons.forEach(function (button) {
        button.addEventListener('click', function () {
            let kakaoPayLink = this.getAttribute('data-value').trim();
            console.log('클릭여부');
            if (kakaoPayLink && isValidKakaoPayLink(kakaoPayLink)) {
                if (!kakaoPayLink.startsWith('http')) {
                    kakaoPayLink = 'https://' + kakaoPayLink;
                }
                window.location.href = kakaoPayLink;
            } else {
                alert('올바른 카카오페이 링크가 아닙니다.');
            }
        });
    });

    brideButtons.forEach(function (button) {
        button.addEventListener('click', function () {
            let kakaoPayLink = this.getAttribute('data-value').trim();
            if (kakaoPayLink && isValidKakaoPayLink(kakaoPayLink)) {
                if (!kakaoPayLink.startsWith('http')) {
                    kakaoPayLink = 'https://' + kakaoPayLink;
                }
                window.location.href = kakaoPayLink;
            } else {
                alert('올바른 카카오페이 링크가 아닙니다.');
            }
        });
    });
}