/**
 * 특정 초대장을 가져오는 함수.
 * @param {string} dynamicValue - 동적인 값 (URL에 사용됨).
 * @returns {Promise<string>} Fetch 요청에 대한 응답으로 받은 HTML 문자열.
 */

export function getInvitation(dynamicValue) {
    return fetch(`/api_GetInvitation/${dynamicValue}`, {
        method: 'GET'
    })
    .then(response => response.text());
}
