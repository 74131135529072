// 카카오 공유하기 //
export function shareKakao(templateId) {

  fetch(`/api_DBInsert/${templateId}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      let titleImgURL = data.imageUrl;
      let imageUrl = data.kakaoimageUrl;
      let invitation_title = data.invitation_title;
      let weddingDateOrigin = data.weddingDate.trim();
      // 년도를 제거하고, 요일을 괄호 형태로 변환하면서 일자와 요일 사이의 불필요한 공백을 제거
      // 그리고 "04월 03일" 형태의 날짜를 "4월 3일"로 변환
      let weddingDate = weddingDateOrigin
        .replace(/(\d{4}년\s+)/, '') // 년도 제거
        .replace(/0?(\d+)월 0?(\d+)일\s*월요일/, '$1월 $2일 (월)') // 월과 일 앞의 0 제거 및 요일 변환
        .replace(/0?(\d+)월 0?(\d+)일\s*화요일/, '$1월 $2일 (화)')
        .replace(/0?(\d+)월 0?(\d+)일\s*수요일/, '$1월 $2일 (수)')
        .replace(/0?(\d+)월 0?(\d+)일\s*목요일/, '$1월 $2일 (목)')
        .replace(/0?(\d+)월 0?(\d+)일\s*금요일/, '$1월 $2일 (금)')
        .replace(/0?(\d+)월 0?(\d+)일\s*토요일/, '$1월 $2일 (토)')
        .replace(/0?(\d+)월 0?(\d+)일\s*일요일/, '$1월 $2일 (일)')
        // 요일이 지정되지 않은 경우를 위한 처리
        .replace(/0?(\d+)월 0?(\d+)일/, '$1월 $2일');
      
      console.log(weddingDate);
      
      // imageUrl이 빈 문자열이거나 null인 경우 titleImgURL로 대체
      if (!imageUrl || imageUrl === '') {
        imageUrl = titleImgURL;
      }

      let wedding_AMPM = data.wedding_AMPM;
      let wedding_time = data.wedding_time;
            // '00분'일 때 빈 문자열로 대체
      let wedding_minute = data.wedding_minute === '00분' ? '' : data.wedding_minute;
      let wedding_location = data.wedding_location ? data.wedding_location : '보테가마지오';
      let wedding_address = data.weddingAddress ? data.weddingAddress : '서울시 강남구 역삼동 123-45';
      let wedding_location_hall = data.wedding_location_hall ? data.wedding_location_hall : '로스타뇨홀';

      // Extract base URL from the current URL
      let baseURL = window.location.origin;
      let shareURL = `${baseURL}/api_GetInvitation/${templateId}`;
      console.log(shareURL);
      // 카카오 공유하기
      Kakao.Share.sendDefault({
        objectType: 'location',
        address: wedding_address,
        addressTitle: wedding_location_hall,
        content: {
          title: invitation_title,
          description: `${weddingDate} ${wedding_AMPM} ${wedding_time} ${wedding_minute}\n${wedding_location} ${wedding_location_hall}`,
          imageUrl: imageUrl,
          link: {
            mobileWebUrl: shareURL,
            webUrl: shareURL,
          },
        },
        buttons: [
          {
            title: '모바일 청첩장',
            link: {
              mobileWebUrl: shareURL,
              webUrl: shareURL,
            },
          },
        ],
      });
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error.message);
    });

}
