import { createKSTDate } from '../../../utils/calendar/createKSTDate.js';
import { markers } from '../../../utils/map/marker.js';
import { GetDBInfo } from '../../../api/api_dbInsert.js';

export function dataCollector(dynamicValue, marker) {

    // DB post 데이터 정리
    // 저장 버튼 클릭시 정적 파일 형성 Post 

    // // 데이터를 가져올 요소 정리
    // 네이버 ID
    const naverEmail = sessionStorage.getItem('naver_email');

    // |- 디자인 영역 탭

    // └─ 디자인 테마 JSON
    const product_ID_type = document.querySelector('.TabItemDesign.Active');
    const product_ID = product_ID_type.id;

    let ActiveBGMElementData; // 변수 선언

    const BGMElement = document.querySelector('.TabListBGM .Active');
    const BGMUploadMusicWrapActive = document.querySelector('.BGM_upload_music_wrap.Active');

    if (BGMElement) {
        // TabListBGM 클래스 하위에 Active 클래스가 있는 요소의 ID를 사용하여 객체 생성
        ActiveBGMElementData = BGMElement.id;
    } else if (BGMUploadMusicWrapActive) {
        // BGM_upload_music_wrap에 Active 클래스가 있으면 상위 요소를 찾아 audio_player의 src 정보를 객체에 저장
        const audioPlayerSrc = document.getElementById('audio_player').src;
        ActiveBGMElementData = audioPlayerSrc;
    }

    // └─ 디자인 장식 요소 정리
    let designDecoration = document.querySelector('.titleImg_item_contents_item_button_decoration_wrap.active').dataset.value;
    let designDecoration_status = document.getElementById('toggle_decoration');
    let designDecoration_statusData = designDecoration_status.classList.contains('is-active') ? 1 : 0;

    let designDecoration_jsonData = {
        designDecoration: designDecoration,
        designDecoration_statusData: designDecoration_statusData
    }

    const designDecorationDataString = JSON.stringify(designDecoration_jsonData, null, 2);

    console.log("디자인 장식 데이터: " + designDecorationDataString);



    // └─ 효과 JSON
    const EffectElement = document.querySelector('.TabItemEffect.Active');
    const ActiveEffectElementData = EffectElement.id;

    // └─ 서체 타입 JSON
    const Fontselect = document.querySelector('.custom-option-fontSelect.active');
    const FontselectData = Fontselect.getAttribute('data-value');

    // └─ 폰트 크기 JSON
    const FontSize = document.querySelector('.TabItemFont.Active');
    const FontSizeData = FontSize.value;

    // └─ 청첩장 타이틀 JSON
    const InvitationTitle = document.getElementById('InvitationTitleInput');
    const InvitationTitleData = InvitationTitle.value;

    // └─ 타이틀 이미지 SRC JSON
    const TitleUploadImg = document.getElementById('TitleImgUpload');
    const TitleUploadImgData = TitleUploadImg.src;

    // └─ 카카오 공유하기 이미지 SRC JSON
    const KaKaoShareImg = document.getElementById('kakao_upload_src');
    let KaKaoShareImgData = KaKaoShareImg.src;

    // └─ 링크 공유하기 이미지 SRC JSON
    const linkShareImg = document.getElementById('link_upload_src');
    let linkShareImgData = linkShareImg.src;

    // 특정 URL
    const defaultImgUrl = 'https://d3e52aew3drki.cloudfront.net/foment_design_template_Img_Storage/DesignArch/titleImg.webp';

    // KaKaoShareImg의 src가 특정 URL이면 TitleUploadImg의 src로 대체
    if (KaKaoShareImgData === defaultImgUrl) {
        KaKaoShareImgData = TitleUploadImgData;
        KaKaoShareImg.src = TitleUploadImgData;
    }

    // linkShareImg의 src가 특정 URL이면 TitleUploadImg의 src로 대체
    if (linkShareImgData === defaultImgUrl) {
        linkShareImgData = TitleUploadImgData;
        linkShareImg.src = TitleUploadImgData;
    }


    // |- 신랑 정보 탭

    // └─ 신랑 성함 JSON
    const GroomFirstName = document.getElementById('GroomFirstNameInput');
    const GroomLastName = document.getElementById('GroomLastNameInput');
    const SelectGroomRelationship = document.getElementById('SelectGroomRelationship');
    const GroomFirstNameData = GroomFirstName.value;
    const GroomLastNameData = GroomLastName.value;
    const SelectGroomRelationshipData = SelectGroomRelationship.value;

    // └─ 신랑 아버지 JSON
    const GroomFatherFirstName = document.getElementById('GroomFatherFirstNameInput');
    const GroomFatherLastName = document.getElementById('GroomFatherLastNameInput');
    const GroomFatherFirstNameData = GroomFatherFirstName.value;
    const GroomFatherLastNameData = GroomFatherLastName.value;
    const Groomfatherstatus = document.getElementById('Groomfatherstatus');
    const GroomfatherstatusData = Groomfatherstatus.checked;
    const Groomfatherstatustype = document.getElementById('Groomfatherstatustype');
    const GroomfatherstatustypeData = Groomfatherstatustype.value;

    // └─ 신랑 어머니 JSON
    const GroomMotherFirstName = document.getElementById('GroomMotherFirstNameInput');
    const GroomMotherLastName = document.getElementById('GroomMotherLastNameInput');
    const GroomMotherFirstNameData = GroomMotherFirstName.value;
    const GroomMotherLastNameData = GroomMotherLastName.value;
    const Groommotherstatus = document.getElementById('Groommotherstatus');
    const GroommotherstatusData = Groommotherstatus.checked;
    const Groommotherstatustype = document.getElementById('Groommotherstatustype');
    const GroommotherstatustypeData = Groommotherstatustype.value;

    // |- 신부 정보 탭

    // └─ 신랑 성함 JSON
    const BrideFirstName = document.getElementById('BrideFirstNameInput');
    const BrideLastName = document.getElementById('BrideLastNameInput');
    const SelectBrideRelationship = document.getElementById('SelectBrideRelationship');
    const BrideFirstNameData = BrideFirstName.value;
    const BrideLastNameData = BrideLastName.value;
    const SelectBrideRelationshipData = SelectBrideRelationship.value;

    // └─ 신랑 아버지 JSON
    const BrideFatherFirstName = document.getElementById('BrideFatherFirstNameInput');
    const BrideFatherLastName = document.getElementById('BrideFatherLastNameInput');
    const BrideFatherFirstNameData = BrideFatherFirstName.value;
    const BrideFatherLastNameData = BrideFatherLastName.value;
    const Bridefatherstatus = document.getElementById('Bridefatherstatus');
    const BridefatherstatusData = Bridefatherstatus.checked;
    const Bridefatherstatustype = document.getElementById('Bridefatherstatustype');
    const BridefatherstatustypeData = Bridefatherstatustype.value;

    // └─ 신랑 어머니 JSON
    const BrideMotherFirstName = document.getElementById('BrideMotherFirstNameInput');
    const BrideMotherLastName = document.getElementById('BrideMotherLastNameInput');
    const BrideMotherFirstNameData = BrideMotherFirstName.value;
    const BrideMotherLastNameData = BrideMotherLastName.value;
    const Bridemotherstatus = document.getElementById('Bridemotherstatus');
    const BridemotherstatusData = Bridemotherstatus.checked;
    const Bridemotherstatustype = document.getElementById('Bridemotherstatustype');
    const BridemotherstatustypeData = Bridemotherstatustype.value;


    // 연락처 데이터 수집 함수
    function collectData() {
        const brideContacts = [];
        const groomContacts = [];

        document.querySelectorAll('.checkselection_contacts_bride_item_wrap').forEach(item => {
            const index = item.getAttribute('data-index');
            const relation = item.querySelector('.selectbox_contact').value;
            const contact = item.querySelector('.TextBox').value;

            brideContacts.push({
                index,
                relation,
                contact
            });
        });

        document.querySelectorAll('.checkselection_contacts_groom_item_wrap').forEach(item => {
            const index = item.getAttribute('data-index');
            const relation = item.querySelector('.selectbox_contact').value;
            const contact = item.querySelector('.TextBox').value;

            groomContacts.push({
                index,
                relation,
                contact
            });
        });

        return { brideContacts, groomContacts };
    }

    const contactDataInfo = collectData();
    const contactData = JSON.stringify(contactDataInfo, null, 2)
    console.log('연락처 수집 테스트' + contactData);



    // |- 예식 정보 탭

    // └─ 예식 일자 JSON

    // dateInput의 값을 가져오기
    let dateInput = document.getElementById("date");
    let dateValue = dateInput.value;

    // 날짜 값을 분해하여 연, 월, 일을 추출
    let [year, month, day] = dateValue.split('-');

    // 월과 일을 원하는 형식으로 변환
    let WeddingDate = `${parseInt(month)}월 ${parseInt(day)}일`;

    // 한국 표준시의 오프셋을 적용하여 연도 계산
    let selectedDate = new Date(year, month - 1, day);
    selectedDate.setHours(selectedDate.getHours() + 9);  // 한국 표준시의 오프셋은 9시간
    let SelectYear = selectedDate.getFullYear();

    // 웨딩 날짜 제목을 가져오기
    const WeddingDay = document.getElementById("CalWeddingDayTitle").innerText.trim();

    // 최종 출력 형식으로 변환
    const WeddingDateData = `${SelectYear}년 ${WeddingDate} ${WeddingDay}`;
    console.log('웨딩데이터출력: ' + WeddingDateData);




    const DdayToggle = document.getElementById('DDay');

    // accountFoldStatusData 요소에서 'toggleSwitch' 클래스가 'active' 클래스를 포함하고 있는지 확인
    // 포함하고 있다면 1을, 그렇지 않다면 0을 반환
    let DdayToggleData = DdayToggle.classList.contains('active') ? 1 : 0;


    // 결과를 저장할 빈 배열을 생성
    let dateFormatResult = [];

    // 'titleImg_item_contents_item_button_weddingNotationMethod_wrap' 클래스를 가진 모든 요소를 찾음
    let elements = document.querySelectorAll('.titleImg_item_contents_item_button_weddingNotationMethod_wrap');

    // 요소들을 순회하며 'active' 클래스를 가진 요소를 찾음
    elements.forEach(element => {
        if (element.classList.contains('active')) {
            // 'active' 요소의 data-value 속성을 가져옴
            let dataValue = element.getAttribute('data-value');

            // data-value를 JSON 형식으로 dateFormatResult 배열에 추가
            dateFormatResult.push({ "data-value": dataValue });
        }
    });

    // dateFormatResult 배열을 JSON 형식으로 콘솔에 출력
    let dateFormatJsonString = JSON.stringify(dateFormatResult);
    console.log(dateFormatJsonString);



    // └─ 예식 시간 JSON
    const WeddingAMPM = document.getElementById('SelectAMPM');
    const WeddingTime = document.getElementById('SelectTime');
    const WeddingMinute = document.getElementById('SelectMinute');
    const WeddingAMPMData = WeddingAMPM.value;
    const WeddingTimeData = WeddingTime.value;
    const WeddingMinuteData = WeddingMinute.value;

    // └─ 예식장 명
    const WeddingLocation = document.getElementById('WeddingLocateTitleInput');
    const WeddingLocationData = WeddingLocation.value;

    // └─ 예식장 층과 홀
    const WeddingLocationHall = document.getElementById('WeddingHallInfoInput');
    const WeddingLocationHallData = WeddingLocationHall.value;

    // └─ 예식장 주소
    const WeddingAddress = document.getElementById('SearchAddressInput');
    const WeddingAddressData = WeddingAddress.value;

    // 마커의 좌표값을 가져옴

    // 비동기적으로 DB에서 정보를 가져오고, 결과가 도착하면 폰트 크기를 적용합니다.
    // 기본 값 설정
    let markerPositionData = { lat: 37.359531, lng: 127.105213 };

    // 비동기적으로 DB에서 정보를 가져오고, 결과가 도착하면 처리합니다.
    GetDBInfo(dynamicValue).then(dbInfo => {
        console.log('DB 정보:', JSON.stringify(dbInfo));

        // DB에서 가져온 weddingMarker 값을 파싱하여 객체로 변환
        const markerPosition = dbInfo.weddingMarker ? JSON.parse(dbInfo.weddingMarker) : null;

        // lat과 lng 값에 접근하여 markerPositionData 업데이트
        if (markerPosition) {
            markerPositionData.lat = markerPosition.lat;
            markerPositionData.lng = markerPosition.lng;
        }

        console.log('마커 좌표값 (JSON):', JSON.stringify(markerPositionData));

        // 여기서 마커 좌표를 사용하여 추가 작업을 수행할 수 있습니다.
        // 예: 마커를 지도에 추가하거나 UI를 업데이트하는 등

    }).catch(error => {
        console.error('DB 정보를 가져오는데 실패했습니다:', error);
    });

    // 기본 마커 배열 확인 및 처리
    if (markers && markers.length > 0) {
        const firstMarker = markers[0]; // 첫 번째 마커를 사용
        if (firstMarker) {
            const position = firstMarker.getPosition();
            markerPositionData = { lat: position.getLat(), lng: position.getLng() };
        } else {
            console.error('첫 번째 마커가 정의되지 않았습니다.');
        }
    } else {
        console.error('markers 배열이 비어 있거나 정의되지 않았습니다.');
    }

    console.log('마커 좌표값 (JSON):', JSON.stringify(markerPositionData));



    // 선택된 위치 정보 요소를 가져오기
    let locationInfo = document.querySelector('.locationInfo_item_contents_item_button_type.active').dataset.value;

    // location_info_map인지 여부를 확인
    let locationInfo_statusData = (locationInfo === 'location_info_map') ? 1 : 0;

    // 콘솔에 locationInfo_statusData 값 출력
    console.log("위치이미지 표시 여부: " + locationInfo_statusData);



    // |- 초대 글 탭

    // └─ 초대문구 타이틀 JSON
    const InviteTitle = document.getElementById('InviteTitleInput');
    const InviteTitleData = InviteTitle.value;

    // └─ 초대문구 본문 JSON
    // const InvitationBody = document.getElementById('TextBoxInput');
    // const InvitationBodyData = InvitationBody.value;


    // Quill 에디터의 내용을 HTML로 가져오기
    const invite_body_quill_editor = document.getElementById('invite_quill_editor');

    // 이미 초기화된 Quill 인스턴스 가져오기
    const quillInstance = Quill.find(invite_body_quill_editor);
    // Quill 에디터의 내용을 HTML로 가져오기
    const InvitationBodyData = quillInstance.root.innerHTML;

    console.log(InvitationBodyData);

    // |- 계좌 정보 탭

    // 계좌번호 로직
    function processAccountData(accountBody, groomOrBride) {
        if (accountBody) {
            let accordionTitle = accountBody.querySelector('.accordion-title').textContent.trim();
            let accodianInnerList = accountBody.querySelectorAll('.accodian-inner');

            let innerData = [];

            accodianInnerList.forEach(innerItem => {
                let bankNameTerms = innerItem.querySelector(`#${groomOrBride}_bank_nameTerms`).textContent.trim();
                let holderInfo = innerItem.querySelector(`#${groomOrBride}_holderinfo`).textContent.trim();
                let bankBankName = innerItem.querySelector(`#${groomOrBride}_bank_bankName`).textContent.trim();
                let bankAccountInfo = innerItem.querySelector(`#${groomOrBride}_bank_accountInfo`).textContent.trim();
                let kakaoPay_link = innerItem.querySelector(`.Btn_KaKaoPay_${groomOrBride}`).getAttribute('data-value').trim();

                let innerItemData = {
                    "bankNameTerms": bankNameTerms,
                    "holderInfo": holderInfo,
                    "bankBankName": bankBankName,
                    "bankAccountInfo": bankAccountInfo,
                    "kakaoPay_link": kakaoPay_link
                };

                innerData.push(innerItemData);
            });

            return {
                "accordionTitle": accordionTitle,
                "innerData": innerData
            };
        } else {
            console.log(`Element with ID '${groomOrBride}AccordionItem' not found.`);
            return null;
        }
    }


    let gallery_img_groups = document.querySelectorAll('.grid-thumb'); // img 태그를 직접 선택
    let galleryArray = []; // src 속성을 저장할 빈 배열 생성

    gallery_img_groups.forEach(gallery_img => {
        galleryArray.push(gallery_img.src); // 각 이미지의 src 속성을 배열에 추가
    });

    console.log(galleryArray); // src 속성 배열 출력


    // 'AccountFold' 아이디를 가진 요소를 찾아 accountFoldStatusData 변수에 저장
    let toggleSwitch_gallery_zoom = document.getElementById('toggleSwitch_gallery_zoom');

    // accountFoldStatusData 요소에서 'toggleSwitch' 클래스가 'active' 클래스를 포함하고 있는지 확인
    // 포함하고 있다면 1을, 그렇지 않다면 0을 반환
    let toggleSwitch_gallery_zoom_data = toggleSwitch_gallery_zoom.classList.contains('active') ? 1 : 0;




    let groomAccountBody = document.querySelector('#groomAccordionItem');
    let brideAccountBody = document.querySelector('#brideAccordionItem');

    let groomInnerData = processAccountData(groomAccountBody, 'groom');
    let brideInnerData = processAccountData(brideAccountBody, 'bride');

    let accountData = [];

    if (groomInnerData) {
        accountData.push(groomInnerData);
    }

    if (brideInnerData) {
        accountData.push(brideInnerData);
    }

    let accountInfoData = JSON.stringify(accountData, null, 2)

    // |- 이미지 갤러리 탭

    // └─ 갤러리 타입 JSON
    const GalleryType = document.querySelector('.TabItemGallery.Active');
    const GalleryTypeData = GalleryType.id;

    // |- 방명록 탭

    // └─ 방명록 비밀번호 JSON
    const BoardPassword = document.getElementById('boardpassword');
    const BoardPasswordData = BoardPassword.value;

    const board_toggle = document.getElementById('toggleSwitch_board');

    // accountFoldStatusData 요소에서 'toggleSwitch' 클래스가 'active' 클래스를 포함하고 있는지 확인
    // 포함하고 있다면 1을, 그렇지 않다면 0을 반환
    let board_toggle_data = board_toggle.classList.contains('active') ? 1 : 0;



    // |- 순서변경 탭

    // └─ 순서 변경 데이터 JSON
    const OrderTab = document.querySelector('.OrderSection');
    const OrderTabData = Array.from(OrderTab.querySelectorAll('.OrderSection > *')).map(el => {
        const obj = {};
        for (const attr of el.attributes) {
            obj[attr.name] = attr.value;
        }
        return obj;
    });

    // account 보여주기 / 말기 저장
    let accountStatus = document.getElementById('account_status');
    let accountStatusData = accountStatus.classList.contains('active') ? 1 : 0;
    console.log('..' + accountStatusData);

    // 'AccountFold' 아이디를 가진 요소를 찾아 accountFoldStatusData 변수에 저장
    let accountFoldStatus = document.getElementById('AccountFold');

    // accountFoldStatusData 요소에서 'toggleSwitch' 클래스가 'active' 클래스를 포함하고 있는지 확인
    // 포함하고 있다면 1을, 그렇지 않다면 0을 반환
    let accountFoldStatusData = accountFoldStatus.classList.contains('active') ? 1 : 0;
    console.log('..' + accountFoldStatusData);




    const allSelectBoxes = document.querySelectorAll('.selectbox_transportation');
    let transportationData = [];

    allSelectBoxes.forEach((selectBox) => {
        const transportationMethod = selectBox.options[selectBox.selectedIndex].text;
        const container = selectBox.closest('.checkselection_address_item');  // 가장 가까운 .checkselection_address_item 부모 요소 찾기
        const textBox = container ? container.querySelector('.TextBox') : null;  // 해당 컨테이너 내의 .TextBox 찾기
        const transportationDescription = textBox ? textBox.value : '';  // TextBox가 존재하면 그 value를 가져오기

        // .checkselection_address_item에서 data-index와 data-id 속성 값 가져오기
        const dataIndex = container ? container.getAttribute('data-index') : '';
        const dataId = container ? container.getAttribute('data-id') : '';

        transportationData.push({
            transportation_method_id: dataId,
            transportation_method: transportationMethod,
            transportation_description: transportationDescription,
            transportation_index: dataIndex,
        });
    });


    // 'AccountFold' 아이디를 가진 요소를 찾아 accountFoldStatusData 변수에 저장
    let informationStatus = document.getElementById('toggleSwitch_information');

    // accountFoldStatusData 요소에서 'toggleSwitch' 클래스가 'active' 클래스를 포함하고 있는지 확인
    // 포함하고 있다면 1을, 그렇지 않다면 0을 반환
    let informationStatusData = informationStatus.classList.contains('active') ? 1 : 0;


    // 안내사항 영역 데이터 수집
    // 안내사항 영역 데이터 수집
    let information_items = document.querySelectorAll('.section_information_contents_item');
    let information_data = Array.from(information_items).map(item => {
        let dataIndex = item.getAttribute('data-index');
        let title = item.querySelector('.section_information_contents_body_title_input').value;

        // 단일 Quill 에디터에 대한 description 수집
        let quillEditorElement = item.querySelector(`[id^="information_quill_editor_"]`);
        let description = '';
        if (quillEditorElement) {
            const quillInstance = Quill.find(quillEditorElement);
            if (quillInstance) {
                description = quillInstance.root.innerHTML; // Quill 에디터의 HTML 내용을 가져옵니다
            } else {
                console.warn(`Quill instance not found for editor ID: ${quillEditorElement.id}`);
            }
        }

        let imageUrl = item.querySelector('.section_information_contents_body_img').src;

        return {
            index: dataIndex,
            title: title,
            description: description,
            imageUrl: imageUrl
        };
    });

    console.log('인포데이터' + JSON.stringify(information_data)); // 디버깅용 콘솔 출력


    // 비디오 토글 상태 체크
    let videoStatus = document.getElementById('toggleSwitch_video_upload');
    let videoStatusData = videoStatus.classList.contains('active') ? 1 : 0;

    // 디자인 이름 순서
    let design_name_order_status = document.getElementById('toggle_design_name_wrapper');
    let design_name_order_status_data = design_name_order_status.classList.contains('name_groom') ? 0 : 1;

    // 비디오 토글 상태 체크
    let giftStatus = document.getElementById('toggleSwitch_gift');
    let giftStatusData = giftStatus.classList.contains('active') ? 1 : 0;

    // 'gift_item_contents_item_button_effect' 클래스를 가진 'active' 요소를 찾음
    let gift_flowerTypeData = document.querySelector('.gift_item_contents_item_button_effect.active')?.getAttribute('data-value');


    // rsvp 토글 상태 체크
    let rsvpStatus = document.getElementById('toggleSwitch_rsvp');
    let rsvpStatusData = rsvpStatus.classList.contains('active') ? 1 : 0;

    // rsvp 팝업 토글 상태 체크
    let rsvpPopupStatus = document.getElementById('rsvp_popup_status');
    let rsvpPopupStatusData = rsvpPopupStatus.classList.contains('active') ? 1 : 0;

    // 마무리 이미지 요소 정리
    let lastImg_img = document.querySelector('.section_lastImg_contents_body_img').src;
    let lastImg_bodyText = document.getElementById('input_lastImg').value;
    let lastImg_frameEffect = document.querySelector('.lastImg_item_contents_item_button_effect.active').dataset.value;
    let lastImg_frameRatio = document.querySelector('.lastImg_item_contents_item_button_ratio.active').dataset.value;
    let lastImg_status = document.getElementById('toggleSwitch_lastImg');
    let lastImg_statusData = lastImg_status.classList.contains('active') ? 1 : 0;

    const lastImgJsonData = {
        lastImg_img: lastImg_img,
        lastImg_bodyText: lastImg_bodyText,
        lastImg_frameEffect: lastImg_frameEffect,
        lastImg_frameRatio: lastImg_frameRatio,
        lastImg_statusData: lastImg_statusData
    };

    const lastImgDataString = JSON.stringify(lastImgJsonData, null, 2);

    console.log("마무리 이미지 데이터: " + lastImgDataString);




    // 청첩장 DB 저장 Data

    let DBData = {
        user_naver_ID: naverEmail,
        templateID: dynamicValue,
        product_ID: product_ID,
        BGM_type: ActiveBGMElementData,
        effect_type: ActiveEffectElementData,
        font_type: FontselectData,
        font_size: FontSizeData,
        invitation_title: InvitationTitleData,
        title_upload_img: TitleUploadImgData,
        kakao_share_img: KaKaoShareImgData,
        link_share_img: linkShareImgData,
        groom_first_name: GroomFirstNameData,
        groom_last_name: GroomLastNameData,
        select_groom_relationship: SelectGroomRelationshipData,
        groom_father_firstname: GroomFatherFirstNameData,
        groom_father_lastname: GroomFatherLastNameData,
        groom_father_status: GroomfatherstatusData,
        groom_father_status_type: GroomfatherstatustypeData,
        groom_mother_firstname: GroomMotherFirstNameData,
        groom_mother_lastname: GroomMotherLastNameData,
        groom_mother_status: GroommotherstatusData,
        groom_mother_status_type: GroommotherstatustypeData,
        bride_firstname: BrideFirstNameData,
        bride_lastname: BrideLastNameData,
        select_bride_relationship: SelectBrideRelationshipData,
        bride_father_firstname: BrideFatherFirstNameData,
        bride_father_lastname: BrideFatherLastNameData,
        bride_father_status: BridefatherstatusData,
        bride_father_status_type: BridefatherstatustypeData,
        bride_mother_firstname: BrideMotherFirstNameData,
        bride_mother_lastname: BrideMotherLastNameData,
        bride_mother_status: BridemotherstatusData,
        bride_mother_status_type: BridemotherstatustypeData,
        wedding_contact: contactData,
        wedding_date: WeddingDateData,
        dateFormatResult: dateFormatJsonString,
        dday_toggle: DdayToggleData,
        board_toggle: board_toggle_data,
        wedding_AMPM: WeddingAMPMData,
        wedding_time: WeddingTimeData,
        wedding_minute: WeddingMinuteData,
        wedding_location: WeddingLocationData,
        wedding_location_hall: WeddingLocationHallData,
        wedding_address: WeddingAddressData,
        wedding_address_marker_position: markerPositionData,
        invite_title: InviteTitleData,
        invite_body: InvitationBodyData,
        gallery_type: GalleryTypeData,
        gallery_img_groups: galleryArray,
        toggleSwitch_gallery_zoom: toggleSwitch_gallery_zoom_data,
        board_password: BoardPasswordData,
        order_tab: OrderTabData,
        accountStatusData: accountStatusData,
        accountFoldData: accountFoldStatusData,
        accountInfoData: accountInfoData,
        transportationData: transportationData,
        information_data: information_data,
        informationStatusData: informationStatusData,
        videoStatusData: videoStatusData,
        design_name_order_status_data: design_name_order_status_data,
        giftStatusData: giftStatusData,
        gift_flowerTypeData: gift_flowerTypeData,
        rsvpStatusData: rsvpStatusData,
        rsvpPopupStatusData: rsvpPopupStatusData,
        lastImgJsonData: lastImgDataString,
        designDecorationData: designDecorationDataString,
        locationImgInfoDataString: locationInfo_statusData
    }

    // 데이터 객체를 반환
    return DBData;
}
