export async function api_createTemplate() {
    const CreateBtns = document.querySelectorAll('.Create_InvitationBtn');

    CreateBtns.forEach(CreateBtn => {
        CreateBtn.addEventListener('click', async () => {
            const naverAccessToken = sessionStorage.getItem("naver_access_token");

            if (!naverAccessToken) {
                let productID = CreateBtn.getAttribute('data-value');
                // 로그인 전에 productID를 세션에 저장합니다.
                sessionStorage.setItem('pending_productID', productID);
                window.location.href = '/api_Auth/login';
                return;
            }

            // 로그인 정보가 있을 경우
            const naverEmailSession = sessionStorage.getItem('naver_email');
            let productID = sessionStorage.getItem('pending_productID') || CreateBtn.getAttribute('data-value');
            console.log(productID);

            try {
                const response = await fetch('/api_CreateTemplate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        naverEmailSession: naverEmailSession,
                        productID : productID
                    })
                });

                if (!response.ok) {
                    throw new Error('Create Template POST 요청에 실패했습니다.');
                }
                const responseData = await response.json();
                const templateID = responseData.templateID;
                
                // 생성된 템플릿 상세 페이지로 이동
                window.location.href = `/api_CreateTemplate/${templateID}`;

                console.log('템플릿 생성 성공, ID:', templateID);

            } catch (error) {
                console.error(error.message);
            }
        });
    });
}

export default api_createTemplate;
