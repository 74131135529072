export function upload_video_list(dynamicValue) {
    // 비디오 리스트 삽입
    document.getElementById('video_upload_btn').addEventListener('change', function (event) {
        const fileInput = event.target;
        const files = fileInput.files;
        const uploadedVideoList = document.getElementById('uploaded_video_list');
        const videoInsertWrap = document.querySelector('.video_insert_wrap');
        const video_upload_placeholder_img = document.querySelector('.video_upload_placeholder_img').src;
        const loaderWrap = document.querySelector('.loader_wrap');

        // 기존 비디오 영역 제거
        uploadedVideoList.innerHTML = '';
        videoInsertWrap.innerHTML = '';

        Array.from(files).forEach(file => {
            if (file.size > 7 * 1024 * 1024) {
                alert('파일 용량은 7MB를 초과할 수 없습니다.');
                return;
            }
                        // 로딩 표시
            loaderWrap.style.display = 'flex';
            console.log('Adding file: ', file.name); // 디버깅 로그 추가

            // 비디오 파일 업로드 요청
            const formData = new FormData();
            formData.append('video', file);

            fetch(`/api_video_file_upload/${dynamicValue}`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log('Video upload successful:', data);

                    const videoUploadListHTML = `
                    <div class="video_upload_list" data-video-url="${data.fileUrls[0]}">
                        <div class="video_upload_music_inner">
                            <div class="video_upload_file_wrap">
                                <img class="video_upload_file_icon"
                                    src="../../../Resource/templates/controls/design/bgm/music-note-03.svg" alt="">
                                <div class="video_upload_fileName">
                                    ${file.name}
                                </div>
                            </div>
                            <div class="delete_video_file_wrap">
                                <img class="delete_video_file"
                                    src="../../../Resource/templates/controls/design/bgm/delete-02.svg" alt="">
                            </div>
                        </div>
                    </div>
                    `;
                    uploadedVideoList.insertAdjacentHTML('beforeend', videoUploadListHTML);

                    const videoElement = document.createElement('video');
                    videoElement.controls = true;
                    videoElement.src = data.fileUrls[0];
                    videoElement.poster = video_upload_placeholder_img;
                    videoInsertWrap.appendChild(videoElement); // video_insert_wrap에 비디오 추가

                    // 비디오 일시정지 시 poster 이미지 설정
                    videoElement.addEventListener('pause', function () {
                        videoElement.poster = video_upload_placeholder_img;
                    });

                } else {
                    console.error('Video upload failed:', data.error);
                }
            })
            .catch(error => {
                console.error('Error uploading video:', error);
            })
            .finally(() => {
                // 로딩 표시 제거
                loaderWrap.style.display = 'none';
            });
        });

        // 파일 입력 필드를 초기화하여 동일 파일 다시 업로드 가능하게 함
        fileInput.value = '';
        console.log('File input reset.'); // 디버깅 로그 추가
    });

    document.getElementById('uploaded_video_list').addEventListener('click', function (event) {
        if (event.target.closest('.delete_video_file_wrap')) {
            const videoUploadList = event.target.closest('.video_upload_list');
            if (videoUploadList) {
                const videoUrl = videoUploadList.getAttribute('data-video-url');
                console.log('Deleting file: ', videoUploadList.querySelector('.video_upload_fileName').innerText); // 디버깅 로그 추가

                // 비디오 파일 삭제 요청
                fetch(`/api_video_file_upload/${dynamicValue}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ videoUrl: videoUrl })
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        console.log('Video delete successful:', data);
                        videoUploadList.remove();

                        // video_insert_wrap에 추가된 비디오 제거
                        const videoInsertWrap = document.querySelector('.video_insert_wrap');
                        videoInsertWrap.innerHTML = '';
                    } else {
                        console.error('Video delete failed:', data.error);
                    }
                })
                .catch(error => {
                    console.error('Error deleting video:', error);
                });
            }
        }
    });

    const videoInput = document.getElementById('video_upload_btn');

    // 비디오 업로드 이벤트 처리
    videoInput.addEventListener('change', function (event) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const url = URL.createObjectURL(file);
            const videoElement = document.createElement('video');
            videoElement.src = url;
            videoElement.controls = true;
            videoElement.style.display = 'block';
            document.getElementById('uploaded_video_list').appendChild(videoElement);

            // 썸네일 미리보기 설정
            videoElement.addEventListener('loadedmetadata', function () {
                if (videoElement.videoWidth && videoElement.videoHeight) {
                    const canvas = document.createElement('canvas');
                    canvas.width = videoElement.videoWidth;
                    canvas.height = videoElement.videoHeight;
                    const context = canvas.getContext('2d');
                    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                    const thumbnailUrl = canvas.toDataURL('image/png');
                    const thumbnailImage = document.getElementById('thumbnail_preview');
                    thumbnailImage.src = thumbnailUrl;
                    thumbnailImage.hidden = false;
                }
            });

            // pause 이벤트 리스너 추가
            videoElement.addEventListener('pause', function () {
                videoElement.poster = video_upload_placeholder_img;
            });
        }
    });

    // 비디오 삭제 이벤트 처리
    document.getElementById('uploaded_video_list').addEventListener('click', function (event) {
        if (event.target.tagName === 'VIDEO') {
            document.getElementById('uploaded_video_list').removeChild(event.target);
        }
    });
}
