
import { rsvp_alert } from "./template_rsvp_modules/rsvp_alert";
import { rsvp_toggle_dimmed } from "./template_rsvp_modules/rsvp_popup";

export function template_rsvp(dynamicValue) {
    // 디데이 토글 모듈
    let toggleDDay = document.getElementById('toggleSwitch_rsvp');
    if (toggleDDay) {

        // 새로운 코드 추가
        if (toggleDDay.classList.contains('active')) {
            let rsvpClass = document.getElementById("rsvp_section");
            let rsvpControl = document.querySelector('.section_rsvp_contents_wrap');
            let placeholder_rsvp = document.querySelector('.placeholder_rsvp');

            rsvpControl.style.display = "flex"
            rsvpClass.style.display = "flex";

            placeholder_rsvp.style.display = "none";
        }


        toggleDDay.onclick = () => {
            toggleDDay.classList.toggle('active');
            let rsvpClass = document.getElementById("rsvp_section");
            let rsvpControl = document.querySelector('.section_rsvp_contents_wrap');
            let placeholder_rsvp = document.querySelector('.placeholder_rsvp');


            if (rsvpClass.style.display === "") {
                rsvpClass.style.display = "flex";
                rsvpControl.style.display = "flex";
                if (placeholder_rsvp) {
                    placeholder_rsvp.style.display = "none";
                }
            } else if (rsvpClass.style.display === "none") {
                rsvpClass.style.display = "flex";
                rsvpControl.style.display = "flex";
                if (placeholder_rsvp) {
                    placeholder_rsvp.style.display = "none";
                }
            }
            else {
                rsvpClass.style.display = "none";
                rsvpControl.style.display = "none";
                if (placeholder_rsvp) {
                    placeholder_rsvp.style.display = "flex";
                }
            }
        }
    }


    
    let toggleDDayPopup = document.getElementById('rsvp_popup_status');
    if (toggleDDayPopup) {
    
        let sideContents = document.querySelector('.side_contents'); // sideContents 변수를 먼저 정의
        
        // 초기 상태 설정
        if (toggleDDayPopup.classList.contains('active')) {
            let rsvp_wrapper_dimmed = document.querySelector('.rsvp_wrapper_dimmed');
            rsvp_wrapper_dimmed.classList.add('is-active');
            sideContents.style.overflow = "hidden";
        }
    
        toggleDDayPopup.onclick = () => {
            toggleDDayPopup.classList.toggle('active');
            
            let rsvp_wrapper_dimmed = document.querySelector('.rsvp_wrapper_dimmed');
            
            // toggleDDayPopup에 active 클래스가 추가되어 있을 때만 is-active 클래스를 토글
            if (toggleDDayPopup.classList.contains('active')) {
                rsvp_wrapper_dimmed.classList.add('is-active');
                sideContents.style.overflow = "hidden";
            } else {
                rsvp_wrapper_dimmed.classList.remove('is-active');
                sideContents.style.overflow = "auto";
            }
    
        }
    }
    
    

    let templateID = dynamicValue;

    // rsvp 얼럿 띄우기
    rsvp_alert(templateID);


    const excel_download_buttons = document.querySelectorAll('.section_rsvp_contents_excel_download_buttons');

    excel_download_buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            var downloadUrl = `/api_rsvp/excel/${templateID}`;
            fetch(downloadUrl)
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = 'rsvp_attendance.xlsx';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch(error => console.error('Error downloading the file:', error));
        });
    });

    rsvp_toggle_dimmed();


}
