// GET DB 조회 함수
export function GetDBInfo(templateId) {
    return fetch(`/api_DBInsert/${templateId}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .catch(error => {
        console.error('오류:', error);
        throw error; // 오류를 던짐
    });
}


// POST DB 데이터 전송 함수
export function insertIntoDB(data) {
    return fetch('/api_DBInsert', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(data)
    });
}
