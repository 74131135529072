import { initializeQuill } from "./quillSetup";

const inputMappingManager = {
    // select box와 텍스트 컨테이너 매핑
    selectMappings: [
        { input: 'SelectGroomRelationship', targets: ['GroomRelationship'] },
        { input: 'SelectBrideRelationship', targets: ['BrideRelationship'] },
        { input: 'SelectAMPM', targets: ['AMPM', 'CalAMPM', 'rsvp_AMPM', 'rsvp_popup_AMPM'] },
        { input: 'SelectTime', targets: ['WeddingTime', 'CalWeddingTime', 'rsvp_CalWeddingTime', 'rsvp_popup_CalWeddingTime'] },
        { input: 'SelectMinute', targets: ['WeddingMinute', 'CalWeddingMinute', 'rsvp_CalWeddingMinute', 'rsvp_popup_CalWeddingMinute'] }
    ],

    // 입력 필드와 텍스트 컨테이너 매핑
    mappings: [
        { input: 'InvitationTitleInput', targets: ['kakaoTitle'], special: true },
        { input: 'GroomFirstNameInput', targets: ['GroomFirstName', 'GroomFirstName2'] },
        { input: 'GroomLastNameInput', targets: ['GroomLastName', 'GroomLastName2', 'GroomLastName3'] },
        { input: 'GroomFatherFirstNameInput', targets: ['GroomFatherFirstName'] },
        { input: 'GroomFatherLastNameInput', targets: ['GroomFatherLastName'] },
        { input: 'GroomMotherFirstNameInput', targets: ['GroomMotherFirstName'] },
        { input: 'GroomMotherLastNameInput', targets: ['GroomMotherLastName'] },
        { input: 'BrideFirstNameInput', targets: ['BrideFirstName', 'BrideFirstName2'] },
        { input: 'BrideLastNameInput', targets: ['BrideLastName', 'BrideLastName2', 'BrideLastName3'] },
        { input: 'BrideFatherFirstNameInput', targets: ['BrideFatherFirstName'] },
        { input: 'BrideFatherLastNameInput', targets: ['BrideFatherLastName'] },
        { input: 'BrideMotherFirstNameInput', targets: ['BrideMotherFirstName'] },
        { input: 'BrideMotherLastNameInput', targets: ['BrideMotherLastName'] },
        { input: 'InviteTitleInput', targets: ['InviteTitle'] },
        { input: 'TextBoxInput', targets: ['InviteBodyText'] },
        { input: 'custom-select-account-groom', targets: ['accordion-title-groom'], isData: true },
        { input: 'custom-select-account-bride', targets: ['accordion-title-bride'], isData: true },
        { input: 'WeddingLocateTitleInput', targets: ['WeddingLocateTitle', 'WeddingLocateTitleMap'] },
        { input: 'WeddingHallInfoInput', targets: ['WeddingHallInfo', 'WeddingHallInfoMap'] }

    ],

    // Quill 에디터와 관련된 매핑
    quillMappings: [
        { editorId: 'invite_quill_editor', targetId: 'InviteBodyText' }
    ],

    // 초기화 함수
    init() {
        this.attachSelectEventListeners();
        this.attachInputEventListeners();
        this.attachQuillEventListeners(); // Quill 에디터 이벤트 리스너 추가
    },

    // select 요소에 대한 이벤트 리스너 추가
    attachSelectEventListeners() {
        this.selectMappings.forEach(({ input, targets }) => {
            const inputElement = document.getElementById(input);
            const targetElements = targets.map(id => document.getElementById(id)).filter(el => el !== null);

            if (inputElement && targetElements.length > 0) {
                this.attachSelectEventListener(inputElement, targetElements);
            }
        });
    },

    // 일반 입력 필드에 대한 이벤트 리스너 추가
    attachInputEventListeners() {
        this.mappings.forEach(({ input, targets, isData, special }) => {
            const inputElements = document.querySelectorAll(`#${input}`);
            const targetElements = targets.flatMap(id => Array.from(document.querySelectorAll(`#${id}`))).filter(el => el !== null);

            inputElements.forEach(inputElement => {
                if (inputElement && targetElements.length > 0) {
                    if (special) {
                        // 특별한 케이스 처리
                        this.attachSpecialEventListener(inputElement, targetElements);
                    } else {
                        this.attachEventListener(inputElement, targetElements, isData);
                    }

                    // 부모 이름 필드 모니터링
                    if (['GroomFatherFirstNameInput', 'GroomFatherLastNameInput', 'GroomMotherFirstNameInput', 'GroomMotherLastNameInput', 'BrideFatherFirstNameInput', 'BrideFatherLastNameInput', 'BrideMotherFirstNameInput', 'BrideMotherLastNameInput'].includes(input)) {
                        this.monitorParentNames(inputElement);
                    }
                }
            });
        });
    },

    // Quill 에디터에 대한 이벤트 리스너 추가
    attachQuillEventListeners() {
        // 고정된 invite_quill_editor 매핑
        this.quillMappings.forEach(({ editorId, targetId }) => {
            initializeQuill(editorId, targetId);
        });

        // 동적 information_quill_editor_* 매핑
        const dynamicEditors = document.querySelectorAll('[id^="information_quill_editor_"]');
        dynamicEditors.forEach(editor => {
            const editorId = editor.id;
            const index = editorId.match(/\d+$/)[0];
            const targetId = `informationSection_swiper_item_description_${index}`;
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                initializeQuill(editorId, targetId);
            }
        });
    },

    // 부모 이름 필드를 모니터링하여 dot_brideFamily 요소의 표시 상태를 조정
    monitorParentNames(inputElement) {
        const dotGroomFamily = document.getElementById('dot_groomFamily');
        const dotBrideFamily = document.getElementById('dot_brideFamily');
        inputElement.addEventListener('keyup', function () {
            const groomFatherFirst = document.getElementById('GroomFatherFirstNameInput').value.trim();
            const groomFatherLast = document.getElementById('GroomFatherLastNameInput').value.trim();
            const groomMotherFirst = document.getElementById('GroomMotherFirstNameInput').value.trim();
            const groomMotherLast = document.getElementById('GroomMotherLastNameInput').value.trim();
            const brideFatherFirst = document.getElementById('BrideFatherFirstNameInput').value.trim();
            const brideFatherLast = document.getElementById('BrideFatherLastNameInput').value.trim();
            const brideMotherFirst = document.getElementById('BrideMotherFirstNameInput').value.trim();
            const brideMotherLast = document.getElementById('BrideMotherLastNameInput').value.trim();
            let bride_parents_wrap = document.querySelector('.bride_parents_wrap');
            let groom_parents_wrap = document.querySelector('.groom_parents_wrap');

            // Groom family display logic
            if ((groomFatherFirst || groomFatherLast) && (groomMotherFirst || groomMotherLast)) {
                dotGroomFamily.style.display = '';
                groom_parents_wrap.style.justifyContent = 'end';
            } else {
                dotGroomFamily.style.display = 'none';
                groom_parents_wrap.style.justifyContent = 'center';
            }

            // Bride family display logic
            if ((brideFatherFirst || brideFatherLast) && (brideMotherFirst || brideMotherLast)) {
                dotBrideFamily.style.display = '';
                bride_parents_wrap.style.justifyContent = 'end';
            } else {
                dotBrideFamily.style.display = 'none';
                bride_parents_wrap.style.justifyContent = 'center';
            }
        });
    },

    // select 요소에 대한 이벤트 리스너 로직
    attachSelectEventListener(inputElement, targetElements) {
        inputElement.addEventListener('change', function (e) {
            const selectedText = e.target.options[e.target.selectedIndex].text;
            let finalText = selectedText;

            // 신랑 신부 관계일 경우 텍스트 앞에 "의 "를 붙입니다.
            if (['SelectGroomRelationship', 'SelectBrideRelationship'].includes(e.target.id)) {
                finalText = selectedText;
            }

            // 대상 요소에 텍스트 설정
            targetElements.forEach(target => {
                target.innerText = finalText;
            });

            // 시간/분/오전오후의 값이 바뀔 때 design_simple_line_02_graphic_decoration_text_time 요소를 업데이트합니다.
            if (['SelectAMPM', 'SelectTime', 'SelectMinute'].includes(e.target.id)) {
                const ampm = document.getElementById('SelectAMPM').value;
                const time = document.getElementById('SelectTime').value.replace('시', '');
                const minute = document.getElementById('SelectMinute').value.replace('분', '');

                const formattedTime = `${time}:${minute}${ampm === '오전' ? 'am' : 'pm'}`;

                const timeDisplayElement = document.querySelector('.design_simple_line_02_graphic_decoration_text_time');
                if (timeDisplayElement) {
                    timeDisplayElement.innerText = formattedTime;
                }
            }
        });
    },


    // 일반 입력 필드에 대한 이벤트 리스너 로직
    attachEventListener(inputElement, targetElements, isData = false) {
        inputElement.addEventListener('keyup', function () {
            const value = this.value;
            targetElements.forEach(target => {
                target.innerText = value;
                if (isData) {
                    target.dataset.value = value;
                }
            });
        });
    },

    // 특별한 케이스에 대한 이벤트 리스너 로직
    attachSpecialEventListener(inputElement, targetElements) {
        inputElement.addEventListener('keyup', function () {
            document.title = this.value;
            targetElements[0].innerText = this.value;
        });
    }
};

export { inputMappingManager };
