export function template_transportation_data() {
    const allSelectBoxes = document.querySelectorAll('.selectbox_transportation');
    let transportationData = [];

    allSelectBoxes.forEach((selectBox) => {
        const transportationMethod = selectBox.options[selectBox.selectedIndex].text;
        const container = selectBox.closest('.checkselection_address_item');  // 가장 가까운 .checkselection_address_item 부모 요소 찾기
        const textBox = container ? container.querySelector('.TextBox') : null;  // 해당 컨테이너 내의 .TextBox 찾기
        const transportationDescription = textBox ? textBox.value : '';  // TextBox가 존재하면 그 value를 가져오기

        // .checkselection_address_item에서 data-index와 data-id 속성 값 가져오기
        const dataIndex = container ? container.getAttribute('data-index') : '';
        const dataId = container ? container.getAttribute('data-id') : '';

        transportationData.push({
            transportation_method_id: dataId,
            transportation_method: transportationMethod,
            transportation_description: transportationDescription,
            transportation_index: dataIndex,
        });
    });

    console.log('저장할 교통 수단 데이터:', transportationData);
    return transportationData;
}