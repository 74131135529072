// coachmarkFetcher.js

export let coachmarkData = null; // export를 추가하여 다른 모듈에서 접근 가능하게 함

async function fetchCoachmarkJson() {
    const url = '/api_GetCoachmarkJson'; // 요청할 URL
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        coachmarkData = jsonData; // 전역 변수에 데이터 저장
    } catch (error) {
        console.error('Error fetching JSON data:', error);
    }
}

export function getCoachmarkJson() {
    window.addEventListener('load', fetchCoachmarkJson);
}
