// 서체 변경 셀렉트박스 모듈
export function selectFontStyle() {
    const select = document.querySelector('.custom-option-fontSelect.active');
    const optionsContainer = document.querySelector('.custom-options');

    if (select) {
        const options = optionsContainer.querySelectorAll(".custom-option-fontSelect");

        options.forEach(option => option.addEventListener('click', selectOption));

        function selectOption() {
            const fontFamily = this.dataset.value;
            select.value = fontFamily;
            console.log(select.value);
            changeFontFamily(fontFamily);
            // 선택된 서체에만 active 클래스 추가
            options.forEach(option => {
                if (option.dataset.value === fontFamily) {
                    option.classList.add('active');
                } else {
                    option.classList.remove('active');
                }
            });
        }

    } else {
        return;
    }

    // 서체 변경 함수
    function changeFontFamily(fontFamily) {
        // 선택된 폰트 패밀리를 모든 요소에 적용
        const elements = document.querySelectorAll('.WeddingBodyText, .WeddingTitleText, .year-month, .sec_cal, .side_contents, .BtnIcon');
        elements.forEach(element => {
            element.style.fontFamily = fontFamily;
        });
    }

    // 초기 선택된 서체를 가져옴
    const selectedFont = select.getAttribute('data-value');
    console.log(selectedFont);

    // 선택된 서체를 적용
    changeFontFamily(selectedFont);
}
