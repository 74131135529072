export function template_lastImg(dynamicValue) {

    // 디데이 토글 모듈
    let toggleVideo = document.getElementById('toggleSwitch_lastImg');
    if (toggleVideo) {
        // 새로운 코드 추가
        if (toggleVideo.classList.contains('active')) {
            let lastImgClass = document.querySelectorAll(".section_lastImg_contents_wrap");
            let lastImgControl = document.getElementById('lastImgSection');
            let placeholder_lastImg = document.querySelector('.placeholder_lastImg');

            lastImgClass.forEach(lastImgClass => {
                lastImgClass.style.display = "flex";
            });
            lastImgControl.style.display = "flex";
            placeholder_lastImg.style.display = "none";

            // lastImg_section_graphic의 src가 비어있을 경우 height 설정
            let lastImgGraphic = document.querySelector('.lastImg_section_graphic');
            if (lastImgGraphic && !lastImgGraphic.src) {
                lastImgControl.style.height = "240px";
            }
        }

        toggleVideo.onclick = () => {
            toggleVideo.classList.toggle('active');
            let lastImgClass = document.querySelectorAll(".section_lastImg_contents_wrap");
            let lastImgControl = document.getElementById('lastImgSection');
            let placeholder_lastImg = document.querySelector('.placeholder_lastImg');

            lastImgClass.forEach(lastImgClass => {
                if (lastImgClass.style.display === "") {
                    lastImgClass.style.display = "flex";
                    lastImgControl.style.display = "flex";
                    placeholder_lastImg.style.display = "none";
                } else if (lastImgClass.style.display === "none") {
                    lastImgClass.style.display = "flex";
                    lastImgControl.style.display = "flex";
                    placeholder_lastImg.style.display = "none";
                } else {
                    lastImgClass.style.display = "none";
                    lastImgControl.style.display = "none";
                    placeholder_lastImg.style.display = "flex";
                }

                // lastImg_section_graphic의 src가 비어있을 경우 height 설정
                let lastImgGraphic = document.querySelector('.lastImg_section_graphic');
                if (lastImgGraphic && !lastImgGraphic.src && lastImgClass.style.display === "flex") {
                    lastImgControl.style.height = "240px";
                } else {
                    lastImgControl.style.height = ""; // 초기화
                }
            });
        }
    }


    // 선택지 클릭시 active 클래스 추가
    const addActiveClass = (elements, callback) => {
        elements.forEach(function (element) {
            element.addEventListener('click', function () {
                // 모든 클래스에서 'active' 제거
                elements.forEach(function (el) {
                    el.classList.remove('active');
                });

                // 현재 클릭한 클래스에 'active' 추가
                this.classList.add('active');

                // 콜백 함수 호출
                if (callback) {
                    callback(this);
                }
            });
        });
    };

    // lastImgSelection_effect와 lastImgSelection_ratio를 가져옵니다
    const lastImgSelection_effect = document.querySelectorAll('.lastImg_item_contents_item_button_effect');
    const lastImgSelection_ratio = document.querySelectorAll('.lastImg_item_contents_item_button_ratio');

    // lastImgSection_contents_wrap 요소를 가져옵니다
    const lastImgSectionContentsWrap = document.querySelector('.lastImgSection_contents_wrap');
    // lastImgSection_contents_wrap img 요소를 가져옵니다
    const lastImgSectionContentsImg = document.querySelector('.lastImgSection_contents_wrap img');

    // lastImgSection_effect_lower_wrap와 lastImgSection_effect_upper_wrap 요소를 가져옵니다
    const lastImgSectionEffectLowerWrap = document.querySelector('.lastImgSection_effect_lower_wrap');
    const lastImgSectionEffectUpperWrap = document.querySelector('.lastImgSection_effect_upper_wrap');

    // lastImgSelection_ratio에 대한 콜백 함수 정의
    const handleRatioChange = (element) => {
        const value = element.getAttribute('data-value');
        const lastImgSection = document.getElementById('lastImgSection');

        if (value === '고정') {
            if (lastImgSectionContentsWrap) {
                lastImgSectionContentsWrap.style.maxHeight = '240px';
            }
            if (lastImgSectionContentsImg) {
                lastImgSectionContentsImg.style.objectFit = 'cover';
                lastImgSectionContentsImg.style.height = '100%';
            }
        } else if (value === '원본비율 유지') {
            lastImgSection.style.height = "auto";
            if (lastImgSectionContentsWrap) {
                lastImgSectionContentsWrap.style.maxHeight = ''; // 원래대로 돌려줌
                lastImgSectionContentsWrap.style.height = "auto";
            }
            if (lastImgSectionContentsImg) {
                lastImgSectionContentsImg.style.objectFit = ''; // 원래대로 돌려줌
                lastImgSectionContentsImg.style.height = ''; // 원래대로 돌려줌
            }
        }
    };

    // lastImgSelection_effect에 대한 콜백 함수 정의
    const handleEffectChange = (element) => {
        const value = element.getAttribute('data-value');

        if (value === '없음') {
            if (lastImgSectionEffectLowerWrap) {
                lastImgSectionEffectLowerWrap.style.display = 'none';
            }
            if (lastImgSectionEffectUpperWrap) {
                lastImgSectionEffectUpperWrap.style.display = 'none';
            }
        } else if (value === '물결') {
            if (lastImgSectionEffectLowerWrap) {
                lastImgSectionEffectLowerWrap.style.display = 'block';
            }
            if (lastImgSectionEffectUpperWrap) {
                lastImgSectionEffectUpperWrap.style.display = 'block';
            }
        }
    };

    // addActiveClass 함수 호출
    addActiveClass(lastImgSelection_effect, handleEffectChange);
    addActiveClass(lastImgSelection_ratio, handleRatioChange);





    // lastImg_section_graphic을 클릭할 때
    document.querySelectorAll('.section_lastImg_contents_buttons_samples').forEach(function (button) {
        button.addEventListener('click', function () {
            document.querySelectorAll('#lastImg_view_dimmed').forEach(function (alert) {
                alert.classList.toggle('is-active');
            });
        });
    });

    // CloseAlert_lastImg를 클릭할 때
    document.getElementById('CloseAlert_lastImg').addEventListener('click', function () {
        document.getElementById('lastImg_view_dimmed').classList.toggle('is-active');
    });



    // 동적으로 생성되는 요소에 대한 이벤트 위임 설정
    // 동적으로 생성되는 요소에 대한 이벤트 위임 설정
    document.addEventListener('change', function (event) {
        if (event.target.classList.contains('lastImg-file-upload-input')) {
            console.log("이벤트가 발생한 실제 요소:", event.target);
            const dataIndex = event.target.getAttribute('data-index');
            console.log("이벤트가 발생한 input의 data-index:", dataIndex);

            if (dataIndex && event.target.files && event.target.files[0]) {
                const file = event.target.files[0];

                // 지원되는 이미지 형식만 허용
                if (file.type === 'image/jpeg' || file.type === 'image/png') {
                    compressAndUploadImage(file, dataIndex);
                } else {
                    console.error('지원되지 않는 이미지 형식입니다:', file.type);
                    alert('지원되지 않는 이미지 형식입니다. JPEG 또는 PNG 이미지만 업로드할 수 있습니다.');
                }
            }
        }
    });


    function compressAndUploadImage(file, dataIndex) {
        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const MAX_WIDTH = 1280; // 최대 가로 크기
                const MAX_HEIGHT = 1280; // 최대 세로 크기
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d'); // 수정된 부분
                ctx.drawImage(img, 0, 0, width, height);

                // Canvas를 Blob으로 변환 후 서버에 업로드
                canvas.toBlob(function (blob) {
                    const formData = new FormData();
                    formData.append('images', blob, file.name);

                    // 동적 URL 생성
                    const uploadUrl = `/api_lastImg_upload/${dynamicValue}`;

                    fetch(uploadUrl, {
                        method: 'POST',
                        body: formData
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success && data.fileUrls.length > 0) {
                                const imageUrl = data.fileUrls[0];
                                console.log(imageUrl);
                                updateImages(imageUrl);
                            } else {
                                console.error('이미지 업로드 실패:', data.message);
                                alert('이미지 업로드에 실패했습니다. 다시 시도해주세요.');
                            }
                        })
                        .catch(error => {
                            console.error('이미지 업로드 중 오류 발생:', error);
                            alert('이미지 업로드 중 오류가 발생했습니다. 인터넷 연결을 확인하고 다시 시도해주세요.');
                        });
                }, 'image/jpeg', 1);
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    }


    function updateImages(imageUrl) {
        // dataIndex를 기반으로 DOM에서 이미지 업데이트
        const contentImage = document.querySelector(`.section_lastImg_contents_body_img`);
        const contentInvitationImage = document.querySelector(`.lastImgSection_contents_img`);
        const lastImgSectionContentsImg = document.querySelector('.lastImgSection_contents_wrap img');
        const lastImgSection_contents_wrap = document.querySelector('.lastImgSection_contents_wrap');
        const lastImgSection = document.getElementById('lastImgSection');
        const activeButton = document.querySelector('.lastImg_item_contents_item_button_ratio.active');

        if (contentImage) {
            contentImage.src = imageUrl;
            contentInvitationImage.src = imageUrl;
            lastImgSectionContentsImg.style.display = "block";
            lastImgSection_contents_wrap.style.height = "auto";

            if (activeButton) {
                const dataValue = activeButton.getAttribute('data-value');
                if (dataValue === '원본비율 유지') {
                    lastImgSection.style.height = "auto";
                } else if (dataValue === '고정') {
                    lastImgSection.style.height = "240px";
                }
            }
        }
    }


    // ID가 input_lastImg인 입력 요소를 가져옵니다
    const inputBox = document.getElementById('input_lastImg');

    // ID가 lastImgSection_contents_body인 요소를 가져옵니다
    const displayElement = document.querySelector('.lastImgSection_contents_body');

    // 입력 요소에 input 이벤트 리스너를 추가합니다
    if (inputBox) {
        inputBox.addEventListener('input', function () {
            // 입력 값이 변경될 때마다 displayElement의 innerText를 업데이트합니다
            if (displayElement) {
                displayElement.innerText = inputBox.value;
            }
        });
    }


    // 모든 알림 본문 콘텐츠 항목을 가져옵니다
    const contentItems = document.querySelectorAll('.alertBody_contents_lastImg_samples_item');

    // 각 알림 본문 콘텐츠 항목에 이벤트 리스너를 추가합니다
    contentItems.forEach(contentItem => {
        contentItem.addEventListener('click', function () {
            // 클릭된 항목의 텍스트를 가져옵니다
            const text = contentItem.innerText.trim();

            // ID로 입력 상자를 찾아서 해당 값으로 설정합니다
            const inputBox = document.getElementById('input_lastImg');
            const lastImgSection_contents_body = document.querySelector('.lastImgSection_contents_body');
            if (inputBox) {
                inputBox.value = text;
                lastImgSection_contents_body.innerText = text;
            }

            // ID로 요소를 찾아서 'is-active' 클래스를 토글합니다
            const dimmedElement = document.getElementById('lastImg_view_dimmed');
            if (dimmedElement) {
                dimmedElement.classList.toggle('is-active');
            }
        });
    });




}