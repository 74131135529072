export function video_status(video_status) {

    const toggle_videoSection = document.getElementById('video_section');

    // 초기 상태 설정
    if (video_status === 1) {
        toggle_videoSection.style.display = 'flex';

    } else {
        toggle_videoSection.style.display = 'none';
    }
}
