export function copyLink(currentUrl) {
    // 청첩장 주소 복사
    let copyAddress = document.getElementById('InviteAddressCopyBtn');

    copyAddress.addEventListener('click', () => {
        let AddressInfo = currentUrl

        // 클립보드에 텍스트 복사
        navigator.clipboard.writeText(AddressInfo).then(() => {
            // 복사 성공시 alert 표시
            alert("주소가 복사되었습니다.");
        }).catch(err => {
            // 복사 실패시 에러 처리
            console.error('클립보드 복사에 실패했습니다.', err);
        });
    });
}