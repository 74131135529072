function template_orderItems() {

    const toggleArea = document.querySelector('.ToggleArea');
    const contentsArea = document.querySelector('.OrderSection');

    let draggedItem = null; // 드래그 중인 요소

    // 드래그 시작 시 처리
    const startDrag = (event) => {
        draggedItem = event.target.closest('.draggable-item');
        event.target.classList.add('highlight');
    };

    // 드래그 종료 시 처리
    const endDrag = (event) => {
        event.target.classList.remove('highlight');
        draggedItem = null;
        updateOrder();
    };

    // 순서 업데이트 함수
    const updateOrder = () => {
        const inputChildElements = Array.from(toggleArea.children);
        const contentsChildElements = Array.from(contentsArea.children);

        inputChildElements.forEach(inputChildElement => {
            const inputId = inputChildElement.dataset.id;
            const contentsChildElement = contentsChildElements.find(element => element.dataset.id === inputId);
            if (contentsChildElement) {
                contentsArea.appendChild(contentsChildElement);
            }
        });
    };

    // 드래그 이벤트 초기화
    toggleArea.querySelectorAll('.ToggleArea > *').forEach(item => {
        item.classList.add('draggable-item');
        item.setAttribute('draggable', true);
        item.addEventListener('dragstart', startDrag);
        item.addEventListener('dragend', endDrag);
        item.addEventListener('touchstart', startDrag);
        item.addEventListener('touchend', endDrag);

        // MenuIcon 요소에 draggable 속성 false 설정
        item.querySelectorAll('.MenuIcon').forEach(icon => {
            icon.setAttribute('draggable', false);
        });
    });

    // 드롭 영역 초기화
    toggleArea.addEventListener('dragover', (event) => {
        event.preventDefault();
        handleDragOver(event);
    });

    toggleArea.addEventListener('touchmove', (event) => {
        event.preventDefault();
        handleDragOver(event.touches[0]);
    });

    // 드래그 중 이벤트 처리
    const handleDragOver = (event) => {
        if (!draggedItem) return;

        const targetElement = document.elementFromPoint(event.clientX, event.clientY);
        if (targetElement && targetElement !== draggedItem && targetElement.parentElement === toggleArea) {
            const nextElement = (targetElement === draggedItem.nextElementSibling) ? targetElement.nextElementSibling : targetElement;
            toggleArea.insertBefore(draggedItem, nextElement);
        }
    };
}

export default template_orderItems;
