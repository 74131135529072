import { GetDBInfo } from "../../api/api_dbInsert";
import { initializeQuill } from "../../utils/quillSetup";

export function template_information(dynamicValue) {



    // 디데이 토글 모듈
    let toggleDDay = document.getElementById('toggleSwitch_information');
    if (toggleDDay) {
        toggleDDay.onclick = () => {
            toggleDDay.classList.toggle('active');
            let informationClass = document.querySelectorAll(".informationSection");
            let infromationControl = document.querySelector('.section_information_contents_wrap');
            let add_information = document.querySelector('.add_information');
            let placeholder_information = document.querySelector('.placeholder_information');

            informationClass.forEach(informationClass => {
                if (informationClass.style.display === "") {
                    informationClass.style.display = "flex";
                    infromationControl.style.display = "flex";
                    add_information.style.display = "block";
                    placeholder_information.style.display = "none"
                } else if (informationClass.style.display === "none") {
                    informationClass.style.display = "flex";
                    infromationControl.style.display = "flex";
                    add_information.style.display = "block";
                    placeholder_information.style.display = "none"
                }
                else {
                    informationClass.style.display = "none";
                    infromationControl.style.display = "none";
                    add_information.style.display = "none";
                    placeholder_information.style.display = "flex"
                }
            });
        }
    }



    const addButton = document.getElementById('add_information'); // 추가 버튼 선택
    const swiperWrap = document.querySelector('.informationSection_swiper_wrap'); // swiper_wrap 선택
    const contentsWrap = document.querySelector('.section_information_contents_wrap'); // contents_wrap 선택

    addButton.addEventListener('click', function () {
        const swiperItems = swiperWrap.querySelectorAll('.informationSection_swiper_item');
        const contentItems = contentsWrap.querySelectorAll('.section_information_contents_item');
        const nextIndex = Math.max(swiperItems.length, contentItems.length) + 1; // 새로운 data-index 계산

        // 새 swiper item HTML 생성
        const newSwiperItemHtml = `
            <div class="informationSection_swiper_item" data-index="${nextIndex}">
                <div class="WeddingSubtitleText" id="informationSection_swiper_item_title_${nextIndex}"></div>
                <div class="informationSection_swiper_item_img">
                    <img src="../../../Resource/templates/controls/information/example.png" alt="안내사항이미지">
                </div>
                <div class="informationSection_swiper_item_description_wrap">
                    <img class="quotes" src="../../../Resource/templates/controls/information/quotes.svg" alt="">
                    <div class="WeddingBodyText" id="informationSection_swiper_item_description_${nextIndex}">
                    </div>
                    <img class="quotes" src="../../../Resource/templates/controls/information/quotes_2.svg" alt="">
                </div>
            </div>
        `;

        // 새 contents item HTML 생성
        const newContentItemHtml = `
            <div class="section_information_contents_item" data-index="${nextIndex}">
                <div class="section_information_contents_header">
                    <span class="section_information_contents_title">안내사항</span>
                    <div class="delete_information">
                        <img class="delete_information_img" src="../Resource/templates/utility/delete.svg" alt="삭제">
                    </div>
                </div>
                <div class="section_information_contents_body_wrapper">
                    <input type="text" class="section_information_contents_body_title_input" placeholder="안내사항 입력" value="">
                    <div class="quill_editor_wrap">
                        <div class="quill_editor" id="information_quill_editor_${nextIndex}"></div>
                    </div>
                    <div class="section_information_contents_body_img_wrap">
                        <div class="section_information_contents_body_img_wrap_button_groups">
                            <div class="section_information_contents_body_img_wrap_button_item" data-index="${nextIndex}">
                                <img class="random_information_img" src="../../../Resource/templates/controls/information/shuffle-square.svg" alt="삭제">
                                <span>랜덤 이미지로 변경</span>
                            </div>
                            <div class="information_img_deleteBtn" id="linkShareImg_reset" data-index="${nextIndex}">
                            <img class="information_img_delete_icon" src="../../../Resource/templates/controls/information/delete.svg" alt="">
                        </div>
                        </div>
                        <img class="section_information_contents_body_img" src="../../../Resource/templates/controls/information/example.png" alt="">
                        <div class="information_img_addBtn" data-index="<%= info.index %>">이미지 추가 +</div>
                    </div>
                </div>
                <div class="section_information_contents_buttons_wrapper">
                    <div class="section_information_contents_buttons_upload_img">
                        <!-- 실제 파일을 선택하는 input 요소 -->
                        <input type="file" id="informtation_fileUpload_${nextIndex}" class="file-upload-input" data-index="${nextIndex}" style="display: none;" />
                        <label class="informtation_fileUpload" for="informtation_fileUpload_${nextIndex}">
                            <img class="section_information_contents_buttons_upload_img_resource" alt=""
                                src="../../../Resource/templates/controls/information/image-upload.svg">
                            <span class="section_information_contents_buttons_upload_img_text">사진 등록</span>
                        </label>
                    </div>
                    <div class="borderLine-vertical"></div>
                    <div class="section_information_contents_buttons_samples" data-index="${nextIndex}">
                        <img class="section_information_contents_buttons_samples_img_resource" alt=""
                            src="../../../Resource/templates/controls/information/text-square.svg">
                        <span class="section_information_contents_buttons_samples_text">샘플 템플릿</span>
                    </div>
                </div>
            </div>
        `;

        // 새로운 swiper item을 swiperWrap에 추가
        swiperWrap.insertAdjacentHTML('beforeend', newSwiperItemHtml);
        // 새로운 contents item을 contentsWrap에 추가
        contentsWrap.insertAdjacentHTML('beforeend', newContentItemHtml);

        // Quill 에디터 초기화 - 모듈로 분리된 함수 사용
        initializeQuill(`information_quill_editor_${nextIndex}`, `informationSection_swiper_item_description_${nextIndex}`);
    });

    // 이벤트 위임을 사용하여 title input에 대한 이벤트 리스너 설정
    contentsWrap.addEventListener('input', function (event) {
        const target = event.target;
        if (target.classList.contains('section_information_contents_body_title_input')) {
            updateInformation(target, '.WeddingSubtitleText');
        }
    });

    // 데이터를 업데이트하는 함수
    function updateInformation(element, targetClass) {
        const dataIndex = element.closest('.section_information_contents_item').getAttribute('data-index');
        const value = element.value;

        // data-index를 사용해 특정 swiper 아이템 선택
        const swiperItem = document.querySelector(`.informationSection_swiper_item[data-index="${dataIndex}"]`);
        if (swiperItem) {
            const targetElement = swiperItem.querySelector(targetClass);
            if (targetElement) {
                targetElement.textContent = value; // 텍스트 업데이트 (innerText 대신 textContent 사용)
            }
        }
    }



    // contentsWrap에 클릭 이벤트 리스너 추가
    contentsWrap.addEventListener('click', function (event) {
        if (event.target.classList.contains('delete_information_img') || event.target.parentNode.classList.contains('delete_information')) {
            const itemToRemove = event.target.closest('.section_information_contents_item');

            // 삭제할 항목의 인덱스를 캡처
            const removedIndex = itemToRemove.getAttribute('data-index');

            // 삭제할 항목 제거
            contentsWrap.removeChild(itemToRemove);

            // 모든 section_information_contents_item 및 관련 요소들의 data-index 및 data-value 업데이트
            document.querySelectorAll('.section_information_contents_item').forEach((item, index) => {
                item.setAttribute('data-index', index + 1);

                const fileUploadLabel = item.querySelector('.informtation_fileUpload');
                const fileUploadInput = item.querySelector('.file-upload-input');
                const sampleTemplateButton = item.querySelector('.section_information_contents_buttons_samples');
                const imageChangeButton = item.querySelector('.section_information_contents_body_img_wrap_button_item');  // 이미지 변경 버튼 선택

                if (fileUploadLabel) {
                    fileUploadLabel.setAttribute('for', `informtation_fileUpload_${index + 1}`);
                }
                if (fileUploadInput) {
                    fileUploadInput.setAttribute('id', `informtation_fileUpload_${index + 1}`);
                    fileUploadInput.setAttribute('data-index', index + 1);  // file-upload-input의 data-index 업데이트
                    fileUploadInput.removeAttribute('data-value');
                }
                if (sampleTemplateButton) {
                    sampleTemplateButton.setAttribute('data-index', index + 1);
                }
                if (imageChangeButton) {
                    imageChangeButton.setAttribute('data-index', index + 1);  // 이미지 변경 버튼의 data-index 업데이트
                }
            });

            // swiper 아이템 삭제 및 인덱스 업데이트
            const swiperItemToRemove = document.querySelector(`.informationSection_swiper_item[data-index="${removedIndex}"]`);
            if (swiperItemToRemove) {
                swiperWrap.removeChild(swiperItemToRemove);
            }

            document.querySelectorAll('.informationSection_swiper_item').forEach((item, index) => {
                item.setAttribute('data-index', index + 1);
            });
        }
    });








    // 동적으로 생성되는 요소에 대한 이벤트 위임 설정
    document.addEventListener('change', function (event) {
        if (event.target.classList.contains('file-upload-input')) {
            console.log("이벤트가 발생한 실제 요소:", event.target);
            const dataIndex = event.target.getAttribute('data-index');
            console.log("이벤트가 발생한 input의 data-index:", dataIndex);

            if (dataIndex && event.target.files && event.target.files[0]) {
                const file = event.target.files[0];

                // 지원되는 이미지 형식 확인
                const allowedTypes = ['image/jpeg', 'image/png'];
                if (allowedTypes.includes(file.type)) {
                    compressAndUploadImage(file, dataIndex);
                } else {
                    console.error('지원되지 않는 이미지 형식입니다:', file.type);
                    alert('지원되지 않는 이미지 형식입니다. JPEG 또는 PNG 이미지만 업로드할 수 있습니다.');
                }
            }
        }
    });

    function compressAndUploadImage(file, dataIndex) {
        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const MAX_WIDTH = 1280; // 최대 가로 크기
                const MAX_HEIGHT = 1280; // 최대 세로 크기
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d'); // 수정된 부분
                ctx.drawImage(img, 0, 0, width, height);

                // Canvas를 Blob으로 변환 후 서버에 업로드
                canvas.toBlob(function (blob) {
                    const formData = new FormData();
                    formData.append('images', blob, file.name);

                    // 동적 URL 생성
                    const uploadUrl = `/api_information_uploadImg/${dynamicValue}`; // dynamicValue는 미리 정의되어야 합니다.

                    fetch(uploadUrl, {
                        method: 'POST',
                        body: formData
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success && data.fileUrls.length > 0) {
                                const imageUrl = data.fileUrls[0];
                                updateImages(imageUrl, dataIndex);
                            } else {
                                console.error('이미지 업로드 실패:', data.message);
                                alert('이미지 업로드에 실패했습니다. 다시 시도해주세요.');
                            }
                        })
                        .catch(error => {
                            console.error('이미지 업로드 중 오류 발생:', error);
                            alert('이미지 업로드 중 오류가 발생했습니다. 인터넷 연결을 확인하고 다시 시도해주세요.');
                        });
                }, 'image/jpeg', 1);
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    }

    function updateImages(imageUrl, dataIndex) {
        // dataIndex를 기반으로 DOM에서 이미지 업데이트
        const contentImage = document.querySelector(`.section_information_contents_item[data-index="${dataIndex}"] .section_information_contents_body_img`);
        if (contentImage) {
            contentImage.src = imageUrl;
        }

        const swiperItemImage = document.querySelector(`.informationSection_swiper_item[data-index="${dataIndex}"] .informationSection_swiper_item_img img`);
        if (swiperItemImage) {
            swiperItemImage.src = imageUrl;
        }
    }











    let sampleButtonClickedIndex = null;  // 전역 변수로 클릭된 샘플 버튼의 index를 저장할 변수

    document.addEventListener('click', function (event) {
        let dimmed = document.getElementById('information_view_dimmed');

        // 클릭된 요소 또는 그 부모 요소 중 하나가 샘플 버튼인지 확인
        let target = event.target;
        while (target != null && !target.matches('.section_information_contents_buttons_samples')) {
            target = target.parentElement;
        }

        if (target && target.matches('.section_information_contents_buttons_samples')) {
            sampleButtonClickedIndex = target.getAttribute('data-index');  // 전역 변수에 index 저장
            console.log('Clicked index:', sampleButtonClickedIndex);
            dimmed.classList.toggle('is-active');
        }

        // alertBody_contents_information_samples_item을 클릭한 경우
        if (
            event.target.matches('.alertBody_contents_information_samples_item') ||
            event.target.closest('.alertBody_contents_information_samples_item')
        ) {
            const itemTarget = event.target.closest('.alertBody_contents_information_samples_item');
            console.log('선택된 item:', itemTarget.innerText);
            console.log('data-index:', sampleButtonClickedIndex);

            // Quill 에디터 요소 선택
            const quillEditorElement = document.querySelector(
                `.section_information_contents_item[data-index="${sampleButtonClickedIndex}"] [id^="information_quill_editor_"]`
            );
            const titleInput = document.querySelector(
                `.section_information_contents_item[data-index="${sampleButtonClickedIndex}"] .section_information_contents_body_title_input`
            );
            const contentsTitle = document.querySelector(
                `.informationSection_swiper_item[data-index="${sampleButtonClickedIndex}"] .WeddingSubtitleText`
            );
            const contentsBody = document.querySelector(
                `.informationSection_swiper_item[data-index="${sampleButtonClickedIndex}"] .WeddingBodyText`
            );

            if (quillEditorElement) {
                const quillInstance = Quill.find(quillEditorElement);
                if (quillInstance) {
                    // 원본 텍스트 가져오기
                    const rawText = itemTarget.innerText || '';

                    // 텍스트를 Quill Delta 형식으로 변환
                    const lines = rawText.split('\n').map(line => ({ insert: line + '\n' }));
                    const delta = { ops: lines };

                    // 기존 내용 지우기
                    quillInstance.setContents([]);

                    // 변환된 Delta를 Quill 에디터에 삽입
                    quillInstance.setContents(delta);

                    // contentsBody에도 동일한 내용 설정
                    contentsBody.innerText = rawText;

                    console.log('Quill 에디터에 입력된 값:', quillInstance.root.innerHTML);
                } else {
                    console.log('Quill 인스턴스를 찾을 수 없음');
                }
            } else {
                console.log('Quill 에디터 요소를 찾을 수 없음');
            }

            // 활성화된 alertBody_tab_item의 내용을 가져와서 titleInput에 입력
            const activeTab = document.querySelector('.alertBody_tab_item.active');
            if (titleInput && activeTab) {
                titleInput.value = activeTab.innerText;
                contentsTitle.innerText = activeTab.innerText;
                console.log('titleInput에 입력된 값:', titleInput.value);
            } else {
                console.log('titleInput 요소를 찾을 수 없음 또는 activeTab 요소가 없음');
            }

            dimmed.classList.toggle('is-active');
        }



        // CloseAlert 버튼이 클릭되었는지 확인
        if (event.target.matches('#CloseAlert_information') || event.target.closest('#CloseAlert_information')) {
            console.log('CloseAlert 버튼 클릭됨');
            dimmed.classList.toggle('is-active');
        }
    });













    // 안내사항 샘플문구 클릭시 로직
    var tabs = document.querySelectorAll('.alertBody_tab_item');
    var contents = document.querySelectorAll('.alertBody_contents_information_samples_wrap');

    tabs.forEach(function (tab) {
        tab.addEventListener('click', function () {
            // 모든 탭에서 'active' 클래스 제거
            tabs.forEach(function (item) {
                item.classList.remove('active');
            });

            // 모든 콘텐츠에서 'active' 클래스 제거
            contents.forEach(function (content) {
                content.classList.remove('active');
            });

            // 현재 클릭된 탭에만 'active' 클래스 추가
            this.classList.add('active');

            // 선택된 탭의 data-id에 일치하는 콘텐츠에 'active' 클래스 추가
            var activeTabId = this.getAttribute('data-id');
            var activeContent = document.querySelector(`.alertBody_contents_information_samples_wrap[data-id="${activeTabId}"]`);
            if (activeContent) {
                activeContent.classList.add('active');
            }
        });
    });

    // 모든 .section_information_contents_body_img_wrap 요소를 찾아 각 요소에 이벤트 리스너를 추가합니다.
    document.querySelectorAll('.section_information_contents_body_img_wrap').forEach(wrapper => {
        wrapper.addEventListener('mouseover', () => {
            // 마우스가 요소 위에 있을 때 내부의 .section_information_contents_body_img의 스타일을 변경합니다.
            const img = wrapper.querySelector('.section_information_contents_body_img');
            if (img) {
                img.style.filter = 'brightness(0.5)';
            }
        });

        wrapper.addEventListener('mouseout', () => {
            // 마우스가 요소에서 벗어날 때 스타일을 원래대로 돌립니다.
            const img = wrapper.querySelector('.section_information_contents_body_img');
            if (img) {
                img.style.filter = '';
            }
        });
    });


    const imageSources = [
        '../../../Resource/templates/controls/information/example.png',
        '../../../Resource/templates/controls/information/example1.png',
        '../../../Resource/templates/controls/information/example2.png'
    ];

    // 각 버튼에 대한 이미지 인덱스를 추적하기 위한 객체
    const currentIndexes = {};

    // 상위 요소인 .section_information_contents_wrap에 이벤트 리스너를 추가
    document.querySelector('.section_information_contents_wrap').addEventListener('click', function (event) {
        // 클릭된 요소가 .section_information_contents_body_img_wrap_button_item인지 확인
        const button = event.target.closest('.section_information_contents_body_img_wrap_button_item');
        if (button) {
            const index = button.getAttribute('data-index');

            // 해당 버튼의 현재 이미지 인덱스를 가져오고, 존재하지 않으면 0으로 초기화
            if (!currentIndexes.hasOwnProperty(index)) {
                currentIndexes[index] = 0;
            }

            // 같은 data-index를 가진 이미지 요소를 찾음
            const imageElement = document.querySelector(`.section_information_contents_item[data-index="${index}"] .section_information_contents_body_img`);
            const swiperImageElement = document.querySelector(`.informationSection_swiper_item[data-index="${index}"] .informationSection_swiper_item_img img`);
            if (imageElement && swiperImageElement) {
                // 다음 이미지로 순환
                currentIndexes[index] = (currentIndexes[index] + 1) % imageSources.length;
                const newSrc = imageSources[currentIndexes[index]];
                imageElement.src = newSrc;
                swiperImageElement.src = newSrc;  // 스와이퍼 아이템의 이미지도 변경
            }
        }
    });

    // 이미지 src가 비어 있거나 유효하지 않으면 display: none 처리하는 함수
    function hideElementIfSrcIsEmpty(imageElement, wrapperElement, addButton) {
        if (imageElement && (!imageElement.src || imageElement.src.endsWith("about:blank") || imageElement.src === window.location.href || imageElement.src === "")) {
            wrapperElement.style.display = "none";
            if (addButton) {
                addButton.style.display = "block";
            }
            imageElement.removeAttribute('src'); // src 속성을 제거하여 로컬 주소가 남지 않도록 처리
        }
    }

    // 상위 요소에 이벤트 리스너 추가
    document.querySelector('.section_information_contents_wrap').addEventListener('click', function (event) {
        // 클릭된 요소가 .information_img_deleteBtn인지 확인
        const deleteButton = event.target.closest('.information_img_deleteBtn');
        if (deleteButton) {
            // 해당 버튼의 data-index를 가져옴
            const index = deleteButton.getAttribute('data-index');

            // 같은 data-index를 가진 이미지 요소를 찾음
            const imageElement = document.querySelector(`.section_information_contents_item[data-index="${index}"] .section_information_contents_body_img`);
            const swiperImageElement = document.querySelector(`.informationSection_swiper_item[data-index="${index}"] .informationSection_swiper_item_img img`);

            if (imageElement) {
                // 이미지의 src를 빈 문자열로 설정하고 src 속성을 제거하여 이미지를 숨김
                imageElement.src = "";
                imageElement.removeAttribute('src');
                // 이미지의 부모 요소를 숨김
                imageElement.closest('.section_information_contents_body_img_wrap').style.display = "none";
            }

            if (swiperImageElement) {
                // 스와이퍼 이미지의 src를 빈 문자열로 설정하고 src 속성을 제거하여 이미지를 숨김
                swiperImageElement.src = "";
                swiperImageElement.removeAttribute('src');
                // 스와이퍼 이미지의 부모 요소를 숨김
                swiperImageElement.closest('.informationSection_swiper_item_img').style.display = "none";
            }

            // 해당 인덱스를 가진 .information_img_addBtn 버튼을 찾고 표시
            const addButton = document.querySelector(`.information_img_addBtn[data-index="${index}"]`);
            if (addButton) {
                addButton.style.display = "block";
            }
        }

        // 클릭된 요소가 .information_img_addBtn인지 확인
        const addButton = event.target.closest('.information_img_addBtn');
        if (addButton) {
            // 해당 버튼의 data-index를 가져옴
            const index = addButton.getAttribute('data-index');

            // 같은 data-index를 가진 이미지 요소를 찾음
            const imageElement = document.querySelector(`.section_information_contents_item[data-index="${index}"] .section_information_contents_body_img`);
            const swiperImageElement = document.querySelector(`.informationSection_swiper_item[data-index="${index}"] .informationSection_swiper_item_img img`);

            if (imageElement) {
                // 이미지의 src를 지정된 경로로 설정
                imageElement.src = "../../../Resource/templates/controls/information/example.png";
                // 이미지의 부모 요소를 표시
                imageElement.closest('.section_information_contents_body_img_wrap').style.display = "block";
            }

            if (swiperImageElement) {
                // 스와이퍼 이미지의 src를 지정된 경로로 설정
                swiperImageElement.src = "../../../Resource/templates/controls/information/example.png";
                // 스와이퍼 이미지의 부모 요소를 표시
                swiperImageElement.closest('.informationSection_swiper_item_img').style.display = "block";
            }

            // 이미지를 다시 추가했으므로 .information_img_addBtn 버튼을 숨김
            addButton.style.display = "none";
        }
    });

    // 페이지 로드 또는 특정 시점에서 src가 빈 문자열인 요소들을 숨김
    document.querySelectorAll('.section_information_contents_body_img, .informationSection_swiper_item_img img').forEach(img => {
        const wrapperElement = img.closest('.section_information_contents_body_img_wrap') || img.closest('.informationSection_swiper_item_img');
        const index = img.closest('[data-index]').getAttribute('data-index');
        const addButton = document.querySelector(`.information_img_addBtn[data-index="${index}"]`);
        hideElementIfSrcIsEmpty(img, wrapperElement, addButton);
    });












    // 비동기적으로 DB에서 정보를 가져오고, 결과가 도착하면 안내사항을 보여줄지 말지에 대한 로직을 작성합니다.
    GetDBInfo(dynamicValue).then(dbInfo => {
        console.log(dbInfo.information_status);

        // dbInfo.information_status가 1이면 display를 flex로, 아니면 none으로 설정
        const shouldDisplay = dbInfo.information_status === 1;
        const informationSections = document.querySelectorAll('.informationSection');
        const contentWraps = document.querySelectorAll('.section_information_contents_wrap');
        let placeholder_information = document.querySelector('.placeholder_information');
        let add_information = document.querySelector('.add_information');

        // 모든 informationSection과 contentWraps 요소의 display 속성 설정
        informationSections.forEach(section => {
            section.style.display = shouldDisplay ? 'flex' : 'none';
        });
        contentWraps.forEach(wrap => {
            wrap.style.display = shouldDisplay ? 'flex' : 'none';
        });

        placeholder_information.style.display = shouldDisplay ? 'none' : 'flex';

        // 안내사항 보여주기 on&off 토글 스위치 로직 추가
        let switch_information = document.getElementById('toggleSwitch_information');
        if (switch_information) {
            if (shouldDisplay) {
                switch_information.classList.add('active'); // active 클래스 추가
                add_information.style.display = 'block';
            } else {
                switch_information.classList.remove('active'); // active 클래스 제거
            }
        }
    }).catch(error => {
        console.error('DB 정보를 가져오는데 실패했습니다:', error);
    });




}