// 디자인 선택 함수 모듈
import { selectDesignTabs } from '../template_modules/template_design_modules/selectDesign.js';
import { backgroundMusicPlayer } from '../template_modules/template_design_modules/selectBGM.js';
import { effectTabHandler } from '../template_modules/template_design_modules/selectEffect.js';
import { fontSizeManager } from '../template_modules/template_design_modules/selectFontSize.js';
import { selectFontStyle } from '../template_modules/template_design_modules/selectFontStyle.js';
import { colorSelect } from './template_common_modules/colorSelect.js';
import { displayDecoration } from './template_design_modules/displayDecoration.js';

// 디자인 템플릿 모듈 내부의 코드들입니다.
function template_design(dynamicValue, design_name_order_status, decoration_section) {
    // 디자인 선택 함수 모듈 적용
    selectDesignTabs();
    // BGM 선택 함수 모듈 적용
    backgroundMusicPlayer.fetchMusicPaths();
    backgroundMusicPlayer.setup(dynamicValue);
    // backgroundMusicPlayer.setupClickToPlay(dynamicValue);
    // 이펙트 선택 함수 모듈 적용
    effectTabHandler.init();
    // 폰트 사이즈 선택 모듈 적용
    fontSizeManager.init();
    // 폰트 스타일 선택 모듈 적용
    selectFontStyle();
    colorSelect();

    document.querySelector('#range').addEventListener('input', function () {
        // 슬라이더의 현재 값 가져오기
        const sliderValue = this.value;

        // 슬라이더 값의 범위를 0에서 100까지로 설정
        const opacityValue = sliderValue / this.max;

        // `previewframe_background_opacity` 클래스를 가진 모든 요소를 선택
        const previewFrames = document.querySelectorAll('.previewframe_background_opacity');

        // 각 요소에 대해 opacity 값을 업데이트
        previewFrames.forEach(frame => {
            frame.style.opacity = opacityValue;
        });
    });

    // 템플릿 탭 활성화에 따른 표시 로직
    let selectedTemplate = document.querySelector('.TabListDesign');
    if (selectedTemplate) {
        let activeTab = selectedTemplate.querySelector('.TabItemDesign.Active');
        if (activeTab) {

            let templates = document.querySelectorAll('.TemplateSection');

            function hideAllTemplates() {
                templates.forEach(template => {
                    template.style.display = 'none';
                });
            }

            function showActiveTemplate(activeId) {
                hideAllTemplates();
                let activeTemplate = document.getElementById('Template_' + activeId);
                if (activeTemplate) {
                    activeTemplate.style.display = 'block';
                } else {
                    console.log('TemplateLoad : Error');
                }
            }

            showActiveTemplate(activeTab.id);
        }
    }

    const button = document.querySelector('.toggle_design_name_button');
    const wrapper = document.getElementById('toggle_design_name_wrapper');
    const toggle_design_name_wrap = document.querySelector('.toggle_design_name_wrap');
    const toggle_BrideGroomElements = document.querySelectorAll('.BrideGroom');

    // 초기 상태 설정
    if (design_name_order_status === 0) {
        toggle_design_name_wrap.style.flexDirection = 'row';
        toggle_BrideGroomElements.forEach(element => {
            element.style.flexDirection = 'row';
        });
    } else {
        toggle_design_name_wrap.style.flexDirection = 'row-reverse';
        toggle_BrideGroomElements.forEach(element => {
            element.style.flexDirection = 'row-reverse';
        });
    }

    const buttonImg = document.querySelector('.toggle_design_name_button_img');

    button.addEventListener('click', function () {
        wrapper.classList.toggle('name_groom');
    
        // 이미지 회전 애니메이션 추가
        buttonImg.classList.add('rotate');
    
        if (wrapper.classList.contains('name_groom')) {
            toggle_design_name_wrap.style.flexDirection = 'row';
            toggle_BrideGroomElements.forEach(element => {
                element.style.flexDirection = 'row';
            });
        } else {
            toggle_design_name_wrap.style.flexDirection = 'row-reverse';
            toggle_BrideGroomElements.forEach(element => {
                element.style.flexDirection = 'row-reverse';
            });
        }
    
        // 애니메이션이 끝난 후 rotate 클래스 제거
        setTimeout(() => {
            buttonImg.classList.remove('rotate');
        }, 500); // 애니메이션 지속 시간과 동일하게 설정
    });
    

    // highlight 위치 업데이트 함수 정의
    const updateHighlightPosition = (element) => {
        const highlight = document.querySelector('.titleImg_item_contents_item_buttons_wrap_highlight');
        const parent = document.querySelector('.titleImg_item_contents_decoration_item_buttons_wrap');

        if (!highlight || !parent) {
            return;
        }

        // Calculate left and top position based on element's offset within parent
        const elementRect = element.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        const leftPosition = elementRect.left - parentRect.left + parent.scrollLeft;
        const topPosition = elementRect.top - parentRect.top + parent.scrollTop;

        // Update highlight position and size
        highlight.style.width = `${elementRect.width}px`;
        highlight.style.height = `${elementRect.height}px`;
        highlight.style.left = `${leftPosition}px`;
        highlight.style.top = `${topPosition}px`;
    };

    // 선택지 클릭시 active 클래스 추가
    const addActiveClass = (elements, callback) => {
        elements.forEach(function (element) {
            element.addEventListener('click', function () {
                // 모든 클래스에서 'active' 제거
                elements.forEach(function (el) {
                    el.classList.remove('active');
                });

                // 현재 클릭한 클래스에 'active' 추가
                this.classList.add('active');

                // 콜백 함수 호출
                if (callback) {
                    callback(this);
                }
            });
        });
    };

    // dot 클래스에 active 추가 함수 정의
    const addDotActiveClass = (elements) => {
        elements.forEach(function (element) {
            const dot = element.querySelector('.titleImg_item_contents_item_button_decoration_dot');
            element.addEventListener('click', function () {
                // 모든 dot 요소에서 'active' 제거
                elements.forEach(function (el) {
                    const elDot = el.querySelector('.titleImg_item_contents_item_button_decoration_dot');
                    if (elDot) {
                        elDot.classList.remove('active');
                    }
                });

                // 현재 클릭한 요소의 dot에 'active' 추가
                if (dot) {
                    dot.classList.add('active');
                }
            });
        });
    };

    // 이미지 매칭 함수 정의
    const matchImages = (element) => {
        const value = element.getAttribute('data-value');
        console.log('Selected value:', value);

        const idList = [
            'decoration_account',
            'decoration_board',
            'decoration_calendar',
            'decoration_gallery',
            'decoration_information',
            'decoration_invitation',
            'decoration_location',
            'decoration_rsvp',
            'decoration_video'
        ];

        if (value === 'label') {
            // label일 경우에만 여러 개의 이미지 업데이트
            const imgElements = [
                { id: 'decoration_invitation', src: `../../../Resource/invitation/decoration/label/decoration_label.png` },
                { id: 'decoration_calendar', src: `../../../Resource/invitation/decoration/label/decoration_calendar.png` },
                { id: 'decoration_gallery', src: `../../../Resource/invitation/decoration/label/decoration_gallery.png` },
                { id: 'decoration_location', src: `../../../Resource/invitation/decoration/label/decoration_location.png` },
                { id: 'decoration_account', src: `../../../Resource/invitation/decoration/label/decoration_account.png` },
                { id: 'decoration_information', src: `../../../Resource/invitation/decoration/label/decoration_information.png` },
                { id: 'decoration_video', src: `../../../Resource/invitation/decoration/label/decoration_video.png` },
                { id: 'decoration_rsvp', src: `../../../Resource/invitation/decoration/label/decoration_rsvp.png` },
                { id: 'decoration_board', src: `../../../Resource/invitation/decoration/label/decoration_board.png` }
            ];

            imgElements.forEach(img => {
                const imgElement = document.getElementById(img.id);
                if (imgElement) {
                    console.log(`Updating ${img.id} to ${img.src}`);
                    imgElement.src = img.src;
                } else {
                    console.log(`Element with id ${img.id} not found`);
                }
            });
        } else {
            // 다른 경우에는 단일 이미지 업데이트
            const newSrc = `../../../Resource/invitation/decoration/${value}/decoration_${value}.png`;
            idList.forEach(id => {
                const imgElement = document.querySelector(`#${id}`);
                if (imgElement) {
                    console.log(`Updating ${id} to ${newSrc}`);
                    imgElement.src = newSrc;
                } else {
                    console.log(`Element with id ${id} not found`);
                }
            });
        }
    };

    // lastImgSelection_effect와 lastImgSelection_ratio를 가져옵니다
    const lastImgSelection_effect = document.querySelectorAll('.titleImg_item_contents_item_button_effect');
    // lastImgSection_effect_lower_wrap와 lastImgSection_effect_upper_wrap 요소를 가져옵니다
    const lastImgSectionEffectLowerWraps = document.querySelectorAll('.title_effect_lower_wrap');

    // lastImgSelection_effect에 대한 콜백 함수 정의
    const handleEffectChange = (element) => {
        const value = element.getAttribute('data-value');
        console.log('Effect selected:', value);

        if (value === '없음') {
            lastImgSectionEffectLowerWraps.forEach(wrap => {
                wrap.style.display = 'none';
            });
        } else if (value === '물결') {
            lastImgSectionEffectLowerWraps.forEach(wrap => {
                wrap.style.display = 'block';
            });
        }
    };

    // 버튼 선택 요소 가져오기
    let decoration_selection = document.querySelectorAll('.titleImg_item_contents_item_button_decoration_wrap');

    // 이벤트 리스너 추가
    addActiveClass(decoration_selection, (element) => {
        updateHighlightPosition(element);
        matchImages(element);
    });
    addDotActiveClass(decoration_selection);
    addActiveClass(lastImgSelection_effect, handleEffectChange);




    // MutationObserver 콜백 함수
    function observeCategoryWrap(mutationsList) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const target = mutation.target;
                if (target.classList.contains('active')) {
                    const activeElement = document.querySelector('.titleImg_item_contents_item_button_decoration_wrap.active');
                    if (activeElement) {
                        updateHighlightPosition(activeElement);
                    }
                }
            }
        }
    }

    // MutationObserver 설정
    const observer = new MutationObserver(observeCategoryWrap);

    // observer가 감시할 대상 요소
    const targetNode = document.getElementById('categoryWrap_item_design');

    // observer 옵션 설정
    const config = { attributes: true, attributeFilter: ['class'] };

    // observer 시작
    if (targetNode) {
        observer.observe(targetNode, config);
    } else {
        console.log('Target node not found.');
    }

    const activeElement = document.querySelector('.titleImg_item_contents_item_button_decoration_wrap.active');
    if (activeElement) {
        updateHighlightPosition(activeElement);
    }





    const toggleSwitch = document.getElementById('toggle_decoration');
    const toggleInput = document.getElementById('toggle_decoration_input');
    const onText = toggleSwitch.querySelector('.on-text');
    const offText = toggleSwitch.querySelector('.off-text');
    const invitationGraphics = document.querySelectorAll('.invitation_section_graphic');

    function updateTextDisplay() {
        if (toggleInput.checked) {
            onText.style.display = 'block';
            offText.style.display = 'none';
            invitationGraphics.forEach(element => {
                element.style.display = 'block';
            });
        } else {
            onText.style.display = 'none';
            offText.style.display = 'block';
            invitationGraphics.forEach(element => {
                element.style.display = 'none';
            });
        }
    }

    toggleInput.addEventListener('change', function () {
        toggleSwitch.classList.toggle('is-active', toggleInput.checked);
        updateTextDisplay();

        if (toggleInput.checked) {
            console.log('Toggle is ON');
        } else {
            console.log('Toggle is OFF');
        }
    });

    // 초기 상태 설정
    updateTextDisplay();

    // toggle-switch가 is-active인지 확인하고 맞으면 updateTextDisplay 호출
    if (toggleSwitch.classList.contains('is-active')) {
        console.log('toggle-switch has class is-active');
        toggleInput.checked = true; // toggleInput을 checked 상태로 설정
        updateTextDisplay();
    } else {
        console.log('toggle-switch does not have class is-active');
    }



    displayDecoration(decoration_section);


}

export default template_design;
