document.addEventListener("DOMContentLoaded", function () {
    const currentPath = window.location.pathname;

    // 메인 페이지에서만 실행
    if (currentPath === '/') {
        mainPageSetup();
    }
});

async function mainPageSetup() {
    // 페이지 기능 동적 로드 및 초기화
    const { previewTemplate } = await import('./main_modules/previewTemplate.js');
    const { tickerAnimation } = await import('./main_modules/ticker.js');
    const { setupScrollAnimations, observeElements } = await import('../utils/scrollAnimation.js');
    const { QNASlide } = await import('../components/QNASlide.js');
    const { eventTarget } = await import('./common_modules/eventTarget.js');

    previewTemplate();  // 청첩장 미리보기 기능을 활성화합니다.
    tickerAnimation();  // 티커 애니메이션을 활성화합니다.
    eventTarget();
    setupScrollAnimations();  // 스크롤 애니메이션을 설정합니다.
    QNASlide();  // Q&A 슬라이드 기능을 활성화합니다.

    setupDynamicContent(observeElements);  // 동적 컨텐츠를 설정합니다.

    window.addEventListener('load', adjustContentBasedOnWindowSize);
    window.addEventListener('resize', adjustContentBasedOnWindowSize);
}


function setupDynamicContent(observeElements) {
    adjustContentBasedOnWindowSize();  // 화면 크기에 따라 컨텐츠를 조정합니다.
    observeScrollElements(observeElements);  // 스크롤 요소를 관찰합니다.
}

function observeScrollElements(observeElements) {
    let titleImg = document.querySelectorAll('.mainContentSection > *');
    let contentsSection = document.querySelectorAll('.imgGridWrap_products > *');
    let titleDescriptionWrap = document.querySelectorAll('.titleDescriptionWrap');

    observeElements(titleImg);
    observeElements(contentsSection);
    observeElements(titleDescriptionWrap);
}

function adjustContentBasedOnWindowSize() {
    updateImageSource();
    adjustPreviewDisplay();
}

function updateImageSource() {
    const image = document.getElementById('titleImg');
    image.src = window.innerWidth <= 809 ? "../Resource/mainasset/mainTitleImg_mobile.svg" : "../Resource/mainasset/mainTitleImg_pc.svg";
}

function adjustPreviewDisplay() {
    let templateImg = document.getElementById('imgItem_template');
    let myPageImg = document.getElementById('imgItem_mobileInvitation_mypage');
    let windowWidth = window.innerWidth;

    templateImg.src = windowWidth <= 809 ? "../Resource/mainasset/imgItem_template_mobile.jpg" : "../Resource/mainasset/imgItem_template.jpg";
    myPageImg.src = windowWidth <= 809 ? "../Resource/mainasset/imgItem_mobileInvitation_mypage_mobile.jpg" : "../Resource/mainasset/imgItem_mobileInvitation_mypage.jpg";
}
