function getInputFields(selectors) {
    return selectors.flatMap(selector => {
        if (selector.startsWith('#')) {
            // ID 선택자일 경우
            const element = document.getElementById(selector.slice(1));
            return element ? [element] : [];
        } else if (selector.startsWith('.')) {
            // 클래스 선택자일 경우
            return Array.from(document.querySelectorAll(selector));
        } else {
            // 기타 선택자일 경우
            return Array.from(document.querySelectorAll(selector));
        }
    }).filter(input => input !== null);
}

function addScrollEventListener(inputs, targetSelector) {
    inputs.forEach(input => {
        input.addEventListener('click', () => {
            const targetElement = document.querySelector(targetSelector);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        });
    });
}

function addClassToggleListener(elementId, targetClass, targetSelector) {
    const targetElement = document.querySelector(targetSelector);
    if (!targetElement) return;

    const element = document.getElementById(elementId);
    if (!element) return;

    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                if (element.classList.contains(targetClass)) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        });
    });

    observer.observe(element, { attributes: true });
}

export function setupScrollEventListeners() {
    const groomInputIDs = [
        '#GroomFirstNameInput', '#GroomLastNameInput', '#SelectGroomRelationship',
        '#GroomFatherFirstNameInput', '#GroomFatherLastNameInput',
        '#GroomMotherFirstNameInput', '#GroomMotherLastNameInput',
        '#Groomfatherstatus', '#Groommotherstatus'
    ];
    const GroomInputs = getInputFields(groomInputIDs);
    addScrollEventListener(GroomInputs, '#scrollFamilyinfo');

    const brideInputIDs = [
        '#BrideFirstNameInput', '#BrideLastNameInput', '#SelectBrideRelationship',
        '#BrideFatherFirstNameInput', '#BrideFatherLastNameInput',
        '#BrideMotherFirstNameInput', '#BrideMotherLastNameInput',
        '#Bridefatherstatus', '#Bridemotherstatus'
    ];
    const BrideInputs = getInputFields(brideInputIDs);
    addScrollEventListener(BrideInputs, '#scrollFamilyinfo');

    const weddingInputIDs = [
        '#date', '#SelectAMPM', '#SelectTime', '#SelectMinute'
    ];
    const WeddingInputs = getInputFields(weddingInputIDs);
    addScrollEventListener(WeddingInputs, '#TextDateCalendar');

    const weddingInfoInputIDs = [
        '#WeddingLocateTitleInput', '#WeddingHallInfoInput', '#SearchAddressInput', '#SearchAddressBtn'
    ];
    const WeddingInfoInputs = getInputFields(weddingInfoInputIDs);
    addScrollEventListener(WeddingInfoInputs, '.LocationSection');

    const inviteInfoInputIDs = [
        '#InviteTitleInput', '#TextBoxInput', '#custom-select-container-invite'
    ];
    const InviteInfoInputs = getInputFields(inviteInfoInputIDs);
    addScrollEventListener(InviteInfoInputs, '.TabContent');

    const accountInfoInputIDs = [
        '#custom-select-account-groom', '#bank_nameTerms_Input',
        '#bank_holder_Input', '#bank_bankName_Input', '#bank_accountInfo_Input', '.input_kakaoPay_wrap'
    ];
    const AccountInfoInputs = getInputFields(accountInfoInputIDs);
    addScrollEventListener(AccountInfoInputs, '.AccountSection');

    const informationInputIDs = [
        '.section_information_contents_body_title_input', '#input_informtation'
    ];
    const informationInputs = getInputFields(informationInputIDs);
    addScrollEventListener(informationInputs, '.informationSection_swiper_item');

    const transportationInputIDs = [
        '#input_transportation'
    ];
    const transportationInputs = getInputFields(transportationInputIDs);
    addScrollEventListener(transportationInputs, '.transportation_method');

    const lastImgInputIDs = [
        '#input_lastImg'
    ];

    function addScrollEventListenerQuill(mappings) {
        mappings.forEach(({ editorId, targetSelector }) => {
            const inputElement = document.querySelector(editorId);
            const targetElement = document.querySelector(targetSelector);
    
            if (inputElement && targetElement) {
                inputElement.addEventListener('click', () => {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                });
            }
        });
    }
    
    // Quill 에디터와 대상 영역을 매핑
    const quillMappings = [
        { editorId: '#invite_quill_editor', targetSelector: '.InviteArea' },
        { editorId: '#information_quill_editor', targetSelector: '.informationSection' }
    ];
    
    // 이벤트 리스너 추가
    addScrollEventListenerQuill(quillMappings);



    const lastImgInputs = getInputFields(lastImgInputIDs);
    addScrollEventListener(lastImgInputs, '.lastImgSection');


    addClassToggleListener('toggleSwitch_rsvp', 'active', '.rsvp_section');
    addClassToggleListener('toggleSwitch_gift', 'active', '.giftSection');
    addClassToggleListener('toggleSwitch_board', 'active', '.BoardSection');
    addClassToggleListener('account_status', 'active', '.AccountSection');
    addClassToggleListener('toggleSwitch_lastImg', 'active', '.lastImgSection');
}
