// DOMContentLoaded 이벤트가 발생하면 실행되는 함수 등록
document.addEventListener('DOMContentLoaded', () => {

  const currentUrl = window.location.href;
  const pathArray = currentUrl.split('/');
  const templateId = pathArray[pathArray.length - 1]; // 마지막 요소인 동적인 값
  const disableInvitationUrl = `${pathArray[0]}//${pathArray[2]}/api_GetInvitation/${templateId}`;

  if (currentUrl === disableInvitationUrl) {
    return;
  } else {

    // 필요한 DOM 요소들을 선택하여 변수에 할당
    const loginInfos = document.querySelectorAll('.LoginInfo');
    const loginButtons = document.querySelectorAll('.loginName');
    const loginNameText = document.querySelector('.loginName');
    const logoutButtons = document.querySelectorAll('.LogOutBtn');
    const selectBoxLogin = document.querySelector('.selectBox_login');

    // 서버에서 세션 정보를 가져오는 함수
    async function getSessionData() {
      try {
        const response = await fetch('/api_GetSession');
        if (response.ok) {
          const data = await response.json();
          // 세션 데이터가 존재하면 checkSession 함수 호출
          if (data) {
            // 1시간마다 세션 상태 확인
            setInterval(checkSession, 3600000); // 3600000 밀리초 = 1시간
          }
          return data;
        } else {
          throw new Error('Failed to fetch session data');
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
        return null;
      }
    }

    // 세션 상태를 확인하는 함수
    function checkSession() {
      fetch('/api_Auth/checkSession')
        .then(response => response.json())
        .then(data => {
          if (data.sessionExpired) {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            window.location.href = '/api_Auth/login';
          } else {
            console.log('세션이 유효합니다.');
          }
        })
        .catch(error => {
          console.error('Error checking session:', error);
        });
    }



    // 로그인된 사용자의 UI를 업데이트하는 함수
    function updateUIForLoggedInUser(naverId) {
      // 로그인 정보 표시 업데이트
      loginInfos.forEach(info => info.innerText = 'MYPage');
      // 로그인 버튼 업데이트 및 선택 상자 토글 이벤트 연결
      loginButtons.forEach(btn => {
        btn.innerText = 'MYPage';
        btn.onclick = () => toggleSelectBox();
      });
      // 로그아웃 버튼 이벤트 연결
      logoutButtons.forEach(btn => {
        btn.innerText = 'LOGOUT';
        btn.onclick = logout;
      });
      // 마이페이지 표시
      document.querySelectorAll('.myPage').forEach(page => {
        page.style.display = 'block';
        page.innerHTML = 'MYPAGE';
      });
      // 로그인한 사용자 이름 표시
      loginNameText.innerText = 'MYPage';
    }

    // 로그아웃된 사용자의 UI를 업데이트하는 함수
    function updateUIForLoggedOutUser() {
      // 로그인 정보 숨김
      loginInfos.forEach(info => info.innerText = '');
      // 로그인 버튼 텍스트 업데이트 및 로그인 페이지로 리디렉션하는 이벤트 연결
      loginButtons.forEach(btn => {
        loginNameText.innerText = 'LOGIN';
        btn.onclick = () => redirectToLogin();
      });
      // 로그아웃 버튼의 텍스트를 '로그인'으로 변경 및 리디렉션 이벤트 연결
      logoutButtons.forEach(btn => {
        btn.innerText = 'LOGIN';
        btn.onclick = redirectToLogin;
      });
      // 마이페이지 숨김
      document.querySelectorAll('.myPage').forEach(page => page.style.display = 'none');
    }

    // 로그인 선택 상자 토글 기능
    function toggleSelectBox() {
      // visible 클래스 존재 여부에 따라 선택 상자의 표시 여부 결정
      const isVisible = selectBoxLogin.classList.contains('visible');
      if (!isVisible) {
        selectBoxLogin.style.display = 'flex';
        requestAnimationFrame(() => selectBoxLogin.classList.add('visible'));
      } else {
        selectBoxLogin.classList.remove('visible');
        setTimeout(() => {
          if (!selectBoxLogin.classList.contains('visible')) {
            selectBoxLogin.style.display = 'none';
          }
        }, 300); // 트랜지션 시간에 맞춤
      }
    }

    // 로그아웃 기능
    function logout() {
      console.log('로그아웃 요청 시작'); // 디버깅

      getSessionData().then(sessionData => {
        const { naverAccessToken, naverEmail } = sessionData;

        fetch('/api_Auth/logout', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ naverAccessToken, naverEmail })
        })
          .then(response => {
            console.log('로그아웃 응답 상태:', response.status); // 디버깅
            return response.json();
          })
          .then(data => {
            console.log('로그아웃 응답 데이터:', data); // 디버깅
            if (data.success) {
              console.log('로그아웃 성공');
              // 서버 세션 삭제 요청
              return fetch('/api_Auth/destroySession', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
              });
            } else {
              console.log('로그아웃 실패');
              throw new Error('로그아웃 실패');
            }
          })
          .then(response => {
            console.log('세션 삭제 응답 상태:', response.status); // 디버깅
            return response.json();
          })
          .then(data => {
            console.log('세션 삭제 응답 데이터:', data); // 디버깅
            if (data.success) {
              console.log('세션 삭제 성공');
              sessionStorage.removeItem('naver_access_token');
              sessionStorage.removeItem('naver_email');
              location.replace('/');
            } else {
              alert('서버 세션 삭제 실패');
            }
          })
          .catch(error => {
            console.error('오류 발생:', error); // 디버깅
          });
      });
    }

    // 로그인 페이지로 리디렉션하는 기능
    function redirectToLogin() {
      location.replace('/api_Auth/login');
    }

    // 사용자의 로그인 상태에 따라 적절한 UI 업데이트 함수 호출
    getSessionData().then(sessionData => {
      if (sessionData && sessionData.naverEmail) {
        const naverId = sessionData.naverEmail.split('@naver.com')[0];
        updateUIForLoggedInUser(naverId);
      } else {
        updateUIForLoggedOutUser();
      }
    });

    // 'myPage' ID를 가진 요소 클릭 시 마이페이지로 리디렉션
    document.body.addEventListener('click', event => {
      if (event.target.id === 'myPage') {
        window.location.href = `/api_Mypage`;
      }
    });

  }

});
