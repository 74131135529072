    // 계좌번호 복사 모듈, 템플릿페이지와 초대장 페이지에서 공통 적용됩니다.
    export function copyAccount(){
        let copyAccounts = document.querySelectorAll('.accodian-btninfo');
        copyAccounts.forEach(function (copyAccount) {
            copyAccount.addEventListener('click', function () {
                let accodianInfo = this.closest('.accodian-inner');
                let bankNameElement = accodianInfo.querySelector('.accountinfo');
                if (bankNameElement) {
                    let bankName = bankNameElement.innerText;
                    // 모든 개행문자를 공백으로 바꾸기
                    bankName = bankName.replace(/\n/g, ' ');
                    let value = bankName;
                    navigator.clipboard.writeText(value).then(function () {
                        alert('계좌번호가 복사되었습니다.');
                    }, function (err) {
                        console.error('Failed to copy: ', err);
                    });
                }
            });
        });
    }