export function fontSizeApply(font_size) {
    // dbInfo 객체에서 폰트 크기 정보를 가져옵니다.
    const fontSizeIncrease = font_size; // 이 예에서는 3이라고 가정합니다.

    // 지정된 셀렉터에 해당하는 모든 요소를 찾아 폰트 크기를 조정합니다.
    document.querySelectorAll('.WeddingBodyText, .WeddingTitleText, .WeddingSubtitleText, .sec_cal .cal_nav .year-month').forEach(elem => {
        // 현재 요소의 폰트 크기를 가져옵니다.
        const currentFontSize = parseFloat(window.getComputedStyle(elem).fontSize);
        // 폰트 크기를 dbInfo.font_size 만큼 증가시킵니다.
        const newFontSize = `${currentFontSize + fontSizeIncrease}px`;
        // 새 폰트 크기를 설정합니다.
        elem.style.fontSize = newFontSize;
    });
}

