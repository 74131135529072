export function effectNoneApply(){
    // 이펙트 적용 처리
    let effectApply = document.querySelectorAll('.effects');

    effectApply.forEach(effect => {
      // Extract filename from currentSrc
      const filename = effect.currentSrc.split('/').pop();
    
      // Check if filename ends with 'effect_none.png'
      if (filename.endsWith('effect_none.png')) {
        effect.style.display = 'none';
      }
    });
}