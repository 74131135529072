export function contacts() {
    const contactsButtonWrap = document.querySelector('.contacts_button_wrap');
    const closeAlertView = document.getElementById('CloseAlert_contact');
    const alertContacts = document.getElementById('alert_contacts');

    function toggleAlert() {
        if (alertContacts) {
            alertContacts.classList.toggle('is-active');

            // 셀렉트 박스 변경 이벤트 트리거
            const selectBoxes = document.querySelectorAll('.selectbox_contact');
            selectBoxes.forEach(selectBox => {
                const event = new Event('change');
                selectBox.dispatchEvent(event);
            });

        } else {
            console.error('alertContacts 요소를 찾을 수 없습니다.');
        }
    }

    if (contactsButtonWrap) {
        contactsButtonWrap.addEventListener('click', toggleAlert);
        console.log('contactsButtonWrap 클릭 이벤트가 추가되었습니다.');
    } else {
        console.error('contactsButtonWrap 요소를 찾을 수 없습니다.');
    }

    if (closeAlertView) {
        closeAlertView.addEventListener('click', toggleAlert);
        console.log('CloseAlert_contact 클릭 이벤트가 추가되었습니다.');
    } else {
        console.error('closeAlertView 요소를 찾을 수 없습니다.');
    }
}
