const fontSizeManager = {
    initialFontSizes: new Map(),

    // 초기 폰트 크기 저장
    storeInitialFontSizes() {
        document.querySelectorAll('.WeddingBodyText, .WeddingTitleText, .WeddingSubtitleText, .sec_cal .cal_nav .year-month').forEach(elem => {
            this.initialFontSizes.set(elem, parseFloat(window.getComputedStyle(elem).fontSize));
        });
    },

    // 폰트 크기 조정 탭에 대한 이벤트 리스너 설정
    setupFontTabs() {
        document.querySelectorAll('ul.TabListFont li').forEach(fontTab => {
            fontTab.addEventListener('click', () => {
                // 기존 Active 클래스 제거
                document.querySelectorAll('ul.TabListFont li').forEach(tab => tab.classList.remove('Active'));
                // 현재 탭에 Active 클래스 추가
                fontTab.classList.add('Active');
                this.adjustFontSize(fontTab.value);
            });
        });
    },

    // 폰트 크기 조절
    adjustFontSize(fontSizeChange) {
        const change = parseInt(fontSizeChange);
        this.initialFontSizes.forEach((initialSize, elem) => {
            const newFontSize = `${initialSize + change}px`;
            elem.style.fontSize = newFontSize;
        });
    },

    // 초기화
    init() {
        this.storeInitialFontSizes();
        this.setupFontTabs();
    }
};

export { fontSizeManager };
