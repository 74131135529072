export function rsvp_alert(templateID, rsvpStatusData) {

    let rsvpSection = document.getElementById('rsvp_section');
    if(rsvpStatusData === 1){
        rsvpSection.style.display = "flex";
    }

    // rsvp_section_graphic을 클릭할 때
    document.querySelectorAll('.rsvp_attend_button').forEach(function(button) {
        button.addEventListener('click', function() {
            document.querySelectorAll('#alert_rsvp').forEach(function(alert) {
                alert.classList.toggle('is-active');
            });
        });
    });
    
    // CloseAlert_rsvp를 클릭할 때
    document.getElementById('CloseAlert_rsvp').addEventListener('click', function () {
        document.getElementById('alert_rsvp').classList.toggle('is-active');
    });


    // 선택지 클릭시 active 클래스 추가
    const addActiveClass = (elements) => {
        elements.forEach(function (element) {
            element.addEventListener('click', function () {
                // 모든 클래스에서 'active' 제거
                elements.forEach(function (el) {
                    el.classList.remove('active');
                });

                // 현재 클릭한 클래스에 'active' 추가
                this.classList.add('active');
            });
        });
    };

    const side_category = document.querySelectorAll('.rsvp_item_contents_item_side_category_title_wrap');
    const attendance_status = document.querySelectorAll('.rsvp_item_contents_item_attend_status_button');
    const dining_plan = document.querySelectorAll('.rsvp_item_contents_item_dining_plan_button');

    addActiveClass(side_category);
    addActiveClass(attendance_status);
    addActiveClass(dining_plan);



    document.querySelectorAll('.rsvp_item_inner_button').forEach(button => {
        button.addEventListener('click', () => {
            // 참석여부(RSVP) 영역 데이터 수집
            let rsvp_items = document.querySelectorAll('.rsvp_item_contents_wrap');
            let rsvp_data = {};
    
            let isValid = true;  // 유효성 검사를 위한 변수 추가
    
            rsvp_items.forEach((item, index) => {
                let activeCategory = item.querySelector('.rsvp_item_contents_item_side_category_title_wrap.active');
                let category_dataId = activeCategory ? activeCategory.getAttribute('data-id') : null;
    
                let name = item.querySelector('.rsvp_item_contents_item_attendance_name_input').value;
    
                // 이름이 빈 문자열일 경우 알림 표시
                if (!name.trim()) {
                    alert('성함은 필수로 입력해주세요.');
                    isValid = false;  // 유효하지 않음으로 설정
                    return;  // 유효하지 않으면 반복문 탈출
                }
    
                let attend_status = item.querySelector('.rsvp_item_contents_item_attend_status_button.active');
                let status = null;
                if (attend_status) {
                    let dataValue = attend_status.getAttribute('data-value');
                    status = dataValue === '불참석' ? 0 : 1;
                }
    
                let phone_number = item.querySelector('.rsvp_item_contents_item_phone_number_input').value;
    
                let activeDiningPlan = item.querySelector('.rsvp_item_contents_item_dining_plan_button.active');
                let dining_plan = null;
                if (activeDiningPlan) {
                    let dataValue = activeDiningPlan.getAttribute('data-value');
                    dining_plan = dataValue === '불참석' ? 0 : 1;
                }
    
                let count = item.querySelector('.rsvp_item_contents_item_attendance_count_input').value;
    
                rsvp_data[`rsvpData`] = {
                    side_category: category_dataId,
                    name: name,
                    status: status,
                    phone_number: phone_number,
                    dining_plan: dining_plan,
                    count: count
                };
            });
    
            // 유효하지 않으면 fetch를 실행하지 않음
            if (!isValid) return;
    
            console.log(templateID);
            console.log(rsvp_data);
    
            fetch(`/api_rsvp/${templateID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rsvp_data)
            })
            .then(response => {
                if (response.ok) {
                    alert('참석정보가 성공적으로 전달되었습니다.');
                    document.getElementById('alert_rsvp').classList.toggle('is-active');
                } else {
                    alert('참석정보 전달이 실패하였습니다. 다시 시도해주세요.');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert('참석정보 전달이 실패하였습니다. 다시 시도해주세요.');
            });
        });
    });
    





}
