
export function locationInfo_map_or_image(dbInfo, dynamicValue) {
    document.querySelectorAll('.locationInfo_item_contents_item_button_type').forEach(button => {
        button.addEventListener('click', function () {
            // 모든 버튼에서 active 클래스 제거
            document.querySelectorAll('.locationInfo_item_contents_item_button_type').forEach(btn => {
                btn.classList.remove('active');
            });

            console.log(dbInfo.location_img_status);

            // 클릭한 버튼에 active 클래스 추가
            this.classList.add('active');

            // data-value 속성 값 가져오기
            const value = this.getAttribute('data-value');

            // map_info_wrap과 map_img_wrap 요소 가져오기
            const mapInfoWrap = document.querySelector('.map_info_wrap');
            const mapImgWrap = document.querySelector('.map_img_wrap');
            const map_area = document.querySelector('.map_area');
            const weddingAddressWrap = document.querySelector('.weddingAddressWrap');
            const location_map_img_item = document.querySelector('.location_map_img_item');

            // data-value에 따른 display 속성 변경
            if (value === 'location_info_map') {
                mapInfoWrap.style.display = 'block';
                mapImgWrap.style.display = 'none';
                weddingAddressWrap.style.display = 'flex';
                map_area.style.display = 'block';
                location_map_img_item.style.display = 'none';

                // 지도가 표시되도록 브라우저의 resize 이벤트 트리거
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 100); // 0.1초 후에 resize 이벤트를 트리거하여 지도가 제대로 렌더링되도록 함
            } else if (value === 'location_info_image') {
                mapInfoWrap.style.display = 'none';
                mapImgWrap.style.display = 'block';
                weddingAddressWrap.style.display = 'none';
                map_area.style.display = 'none';
                location_map_img_item.style.display = 'block';

                // location_map_img_item의 src가 비어있으면 display none
                // location_map_img_item의 src가 비어있거나 설정되지 않았다면 display none
                if (!location_map_img_item.getAttribute('src')) {
                    location_map_img_item.style.display = 'none';
                }

            }

            // highlight 요소 이동 (선택사항)
            const highlight = document.querySelector('.locationInfo_item_contents_item_buttons_wrap_highlight');
            const buttonRect = this.getBoundingClientRect();
            const containerRect = this.parentElement.getBoundingClientRect();

            highlight.style.left = `${buttonRect.left - containerRect.left}px`;
        });
    });


    document.getElementById('categoryWrap_item_weddingInfo').addEventListener('click', function () {

        const highlight = document.querySelector('.locationInfo_item_contents_item_buttons_wrap_highlight');
        const mapButton = document.querySelector('.locationInfo_item_contents_item_button_type[data-value="location_info_map"]');
        const imageButton = document.querySelector('.locationInfo_item_contents_item_button_type[data-value="location_info_image"]');
        // map_info_wrap과 map_img_wrap 요소 가져오기
        const mapInfoWrap = document.querySelector('.map_info_wrap');
        const mapImgWrap = document.querySelector('.map_img_wrap');
        const map_area = document.querySelector('.map_area');
        const weddingAddressWrap = document.querySelector('.weddingAddressWrap');
        const location_map_img_item = document.querySelector('.location_map_img_item');


        // 모든 버튼에서 active 클래스 제거
        document.querySelectorAll('.locationInfo_item_contents_item_button_type').forEach(btn => {
            btn.classList.remove('active');
        });


        let selectedButton;
        if (dbInfo.location_img_status === 1) {
            selectedButton = mapButton;
            mapInfoWrap.style.display = 'block';
            mapImgWrap.style.display = 'none';
            weddingAddressWrap.style.display = 'flex';
            map_area.style.display = 'block';
            location_map_img_item.style.display = 'none';
        } else if (dbInfo.location_img_status === 0) {
            selectedButton = imageButton;
            mapInfoWrap.style.display = 'none';
            mapImgWrap.style.display = 'block';
            weddingAddressWrap.style.display = 'none';
            map_area.style.display = 'none';
            location_map_img_item.style.display = 'block';
            // location_map_img_item의 src가 비어있으면 display none
            // location_map_img_item의 src가 비어있거나 설정되지 않았다면 display none
            if (!location_map_img_item.getAttribute('src')) {
                location_map_img_item.style.display = 'none';
            }
        }

        if (selectedButton) {
            selectedButton.classList.add('active');
            const buttonRect = selectedButton.getBoundingClientRect();
            const containerRect = selectedButton.parentElement.getBoundingClientRect();

            highlight.style.left = `${buttonRect.left - containerRect.left}px`;
        }
    });





    function upload_video_thumbnail(dynamicValue) {
        const originalThumbnailUrl = "../../../Resource/templates/controls/weddingInfo/placeholder_img_th_upload.svg"; // 원래 썸네일 URL

        // 썸네일 업로드 이벤트 처리
        const thumbnailInput = document.getElementById('locationImg_upload_btn');
        thumbnailInput.addEventListener('change', function (event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('thumbnail', file);

            fetch(`/api_location_img_upload/${dynamicValue}`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        console.log(data);
                        const thumbnailUrl = data.fileUrls;
                        const thumbnailImage = document.querySelector('.locationImg_upload_placeholder_img');
                        const location_map_img_item = document.querySelector('.location_map_img_item');
                        if (thumbnailImage) {
                            thumbnailImage.src = thumbnailUrl;
                            location_map_img_item.src = thumbnailUrl;
                            location_map_img_item.style.display = 'block';
                        }

                        // 다른 관련된 이미지들도 업데이트할 필요가 있다면 이곳에서 처리할 수 있습니다.
                    } else {
                        console.error('Thumbnail upload failed:', data.error);
                    }
                })
                .catch(error => {
                    console.error('Error uploading thumbnail:', error);
                });
        });

        // 썸네일 삭제 이벤트 처리
        const deleteThumbnailButton = document.querySelector('.delete_location_img_wrap');
        if (deleteThumbnailButton) {
            deleteThumbnailButton.addEventListener('click', function () {
                fetch(`/api_location_img_upload/${dynamicValue}`, {
                    method: 'DELETE'
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            const thumbnailImage = document.querySelector('.locationImg_upload_placeholder_img');
                            const location_map_img_item = document.querySelector('.location_map_img_item');
                            if (thumbnailImage) {
                                thumbnailImage.src = originalThumbnailUrl;
                                location_map_img_item.src = '';
                            }

                            // 썸네일 입력 필드 초기화
                            thumbnailInput.value = '';
                            console.log('Thumbnail input reset.'); // 디버깅 로그 추가
                        } else {
                            console.error('Thumbnail deletion failed:', data.message);
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting thumbnail:', error);
                    });
            });
        }
    }

    upload_video_thumbnail(dynamicValue);



}