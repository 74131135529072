import { coachmarkData } from '../template_common_modules/coachmarkModules.js';

export function categorySelect() {
    let categoryItems = document.querySelectorAll('.categoryWrap_item');

    categoryItems.forEach(function (item) {
        item.addEventListener('click', function () {
            categoryItems.forEach(function (element) {
                element.classList.remove('active');
                let img = element.querySelector('.category_img');
                img.src = img.src.replace('/on.svg', '/off.svg');
                
                if (element.classList.contains('active')) {
                    img.style.backgroundColor = "#E9ECEF";
                    img.style.borderRadius = "8px";
                } else {
                    img.style.backgroundColor = "";
                    img.style.borderRadius = "";
                }
            });

            this.classList.add('active');
            this.scrollIntoView({ behavior: "smooth", block: "start" }); // 카테고리 항목 스크롤

            let img = this.querySelector('.category_img');
            img.src = img.src.replace('/off.svg', '/on.svg');
            img.style.backgroundColor = "#E9ECEF";
            img.style.borderRadius = "8px";

            let itemId = this.id;
            let sectionId = itemId.replace('categoryWrap_item_', '');
            let section = document.getElementById('contents_' + sectionId);
            if (section) {
                let allSections = document.querySelectorAll('.DesignSection');
                allSections.forEach(function (sec) {
                    sec.classList.remove('active');
                });
                section.classList.add('active');
                section.scrollIntoView({ behavior: "smooth", block: "start" }); // DesignSection 스크롤
            }
        });

        let timeoutId;

        categoryItems.forEach(function (item) {
            item.addEventListener('mouseenter', function () {
                // 화면 너비가 1279 이하일 경우 코치마크 기능 비활성화
                if (window.innerWidth <= 1279) return;

                if (!coachmarkData) return;

                clearTimeout(timeoutId);

                const categoryId = this.id.replace('categoryWrap_item_', '');
                const coachmark = coachmarkData[categoryId];
                const coachmarkWrap = document.querySelector(`#coachmark_${categoryId}`);

                timeoutId = setTimeout(() => {
                    document.querySelectorAll('.coachmarkWrap').forEach(wrap => {
                        wrap.classList.remove('active');
                        wrap.querySelector('.coachmark_img').src = '';
                        wrap.querySelector('.coachmark_description_title').innerText = '';
                        wrap.querySelector('.coachmark_description_decription').innerText = '';
                    });

                    if (coachmark && coachmarkWrap) {
                        coachmarkWrap.classList.add('active');
                        coachmarkWrap.querySelector('.coachmark_img').src = coachmark.imageSrc;
                        coachmarkWrap.querySelector('.coachmark_description_title').innerText = coachmark.title;
                        coachmarkWrap.querySelector('.coachmark_description_decription').innerText = coachmark.description;
                    }
                }, 400);
            });

            item.addEventListener('mouseleave', function () {
                clearTimeout(timeoutId);

                document.querySelectorAll('.coachmarkWrap').forEach(wrap => {
                    wrap.classList.remove('active');
                    wrap.querySelector('.coachmark_img').src = '';
                    wrap.querySelector('.coachmark_description_title').innerText = '';
                    wrap.querySelector('.coachmark_description_decription').innerText = '';
                });
            });
        });
    });
}
