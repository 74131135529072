export function tooltip_box_appear() {
    console.log('tooltip_box_appear 함수가 호출되었습니다.');
    const tooltipBox = document.querySelector('.tooltip_box');
  
    // localStorage에서 툴팁 표시 여부를 확인
    const isTooltipShown = localStorage.getItem('tooltipShown');
    console.log('툴팁 표시 여부:', isTooltipShown);
  
    // 툴팁이 이미 표시된 경우 함수 종료
    if (isTooltipShown) {
      console.log('툴팁이 이미 표시되었으므로 함수를 종료합니다.');
      tooltipBox.style.display = "none";
      return;
    }
  
    // 현재 화면 너비 확인
    const windowWidth = window.innerWidth;
    console.log('현재 화면 너비:', windowWidth);
  
    // 화면 너비가 1200px 이하인 경우 함수 종료
    if (windowWidth <= 1200) {
      console.log('해상도가 1200px 이하입니다. 툴팁 박스를 표시하지 않습니다.');
      return;
    }
  
    // 1200px 초과일 때만 툴팁 박스 표시
    if (!tooltipBox.classList.contains('visible')) {
      tooltipBox.classList.add('visible');
      tooltipBox.style.display = ""; // 최초 표시 시 display 스타일 초기화 (필요한 경우에만 추가)
      console.log('툴팁 박스를 표시합니다.');
    }
  
    // 리사이즈 이벤트 리스너 추가
    window.addEventListener('resize', function() {
      console.log('화면 크기가 변경되었습니다.');
  
      // 리사이즈 후 화면 너비 확인
      const resizedWindowWidth = window.innerWidth;
      console.log('리사이즈 후 화면 너비:', resizedWindowWidth);
  
      // 리사이즈 후에도 1200px 이하인 경우 툴팁 박스 숨김
      if (resizedWindowWidth <= 1200) {
        tooltipBox.classList.remove('visible');
        tooltipBox.style.display = "none";
      }
    });
  
    // '.tooltip_box_inner_buttons_confirm' 클래스를 가진 요소에 대한 클릭 이벤트 리스너 추가
    const confirmButton = document.querySelector('.tooltip_box_inner_buttons_confirm');
    if (confirmButton) {
      console.log('확인 버튼을 찾았습니다.');
      confirmButton.addEventListener('click', function() {
        console.log('확인 버튼이 클릭되었습니다.');
  
        // 'visible' 클래스가 있으면 제거하여 툴팁 박스를 숨김
        tooltipBox.classList.remove('visible');
        tooltipBox.style.display = "none";
  
        // localStorage에 툴팁 표시 여부를 저장하여 다음에는 표시되지 않도록 함
        localStorage.setItem('tooltipShown', 'true');
        console.log('툴팁 표시 여부를 localStorage에 저장했습니다.');
      });
    } else {
      console.log('확인 버튼을 찾을 수 없습니다.');
    }
  }
  