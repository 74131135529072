export function updateDateFormat_template(element, weddingDate) {
    const dateDisplayMonth = document.querySelector('#textDate_calendar_month');
    const dateDisplayDay = document.querySelector('#textDate_calendar_day');
    const dateDisplayLine = document.querySelector('#cal_line_deco');
    console.log('클래스명 체크: ' + dateDisplayLine.className);
    console.log('클래스명 체크: ' + dateDisplayDay.className);

    const dateFormat = element;
    console.log(weddingDate);

    const rawDate = document.querySelector('#date').value;
    const match = rawDate.match(/(\d{4})-(\d{2})-(\d{2})/);
    let year, month, day;
    if (match) {
        year = match[1].trim();
        month = match[2].replace(/^0/, ''); // 앞에 0 제거
        day = match[3].replace(/^0/, ''); // 앞에 0 제거
    } else {
        console.error('Invalid date format in data-value attribute');
        return;
    }


    const formatDate = (year, month, day, format) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const koreanMonthNames = ["1월", "2월", "3월", "4월", "5월", "6월",
            "7월", "8월", "9월", "10월", "11월", "12월"];

        switch (format) {
            case 'justDate':
                return { month: `${month}`, day: `${day}` };
            case 'koreanDate':
                return { month: `${year}년 ${koreanMonthNames[parseInt(month) - 1]}`, day: `${day}일` };
            case 'fullDate':
                return { month: `${year}. ${month}.`, day: `${day}` };
            case 'englishDate':
                return { month: `${monthNames[parseInt(month) - 1]}`, day: `${day}, ${year}` };
            default:
                return { month: `${month}`, day: `${day}` };
        }
    };

    const formattedDate = formatDate(year, month, day, dateFormat);

    if (dateDisplayMonth && dateDisplayDay && dateDisplayLine) {
        dateDisplayMonth.textContent = formattedDate.month;
        dateDisplayDay.textContent = formattedDate.day;

        // 이전 클래스와 이후 클래스 비교를 위한 디버깅 로그 추가
        console.log('Before class change:', dateDisplayLine.className);

        // 기존 클래스 제거하고 새로운 클래스 추가
        dateDisplayMonth.className = `month date-format-${dateFormat}`;
        dateDisplayDay.className = `day date-format-${dateFormat}`;
        dateDisplayLine.className = `line date-format-${dateFormat}`;

        console.log('After class change:', dateDisplayLine.className);

        // 디버깅 문을 추가하여 클래스 이름 확인
        console.log('Month classes:', dateDisplayMonth.className);
        console.log('Day classes:', dateDisplayDay.className);
        console.log('Line classes:', dateDisplayLine.className);
    } else {
        console.error('One or more elements not found');
    }
}
