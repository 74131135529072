// 전화 및 메시지 전송 버튼 초기화 함수
export function initializeContactButtons() {
    const allCallButtons = document.querySelectorAll('.contact_item_info_button_call_wrap');
    const allMessageButtons = document.querySelectorAll('.contact_item_info_button_message_wrap');

    allCallButtons.forEach(button => {
        button.addEventListener('click', function () {
            const parentWrap = button.closest('.contact_item_wrap');
            if (parentWrap) {
                var phoneNumber = parentWrap.getAttribute('data-value');
                if (phoneNumber) {
                    phoneNumber = phoneNumber.replace(/\D/g, ''); // 숫자만 추출
                    var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
                    var telLink = isIOS ? `tel://${phoneNumber}` : `tel:${phoneNumber}`;
                    console.log(`전화 걸기: ${telLink}`);
                    window.location.href = telLink;
                } else {
                    alert('전화 번호가 입력되지 않았습니다.');
                }
            }
        });
    });

    allMessageButtons.forEach(button => {
        button.addEventListener('click', function () {
            const parentWrap = button.closest('.contact_item_wrap');
            if (parentWrap) {
                var phoneNumber = parentWrap.getAttribute('data-value');
                if (phoneNumber) {
                    phoneNumber = phoneNumber.replace(/\D/g, ''); // 숫자만 추출
                    var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
                    var smsLink = isIOS ? `sms://${phoneNumber}` : `sms:${phoneNumber}`;
                    console.log(`메시지 보내기: ${smsLink}`);
                    window.location.href = smsLink;
                } else {
                    alert('전화 번호가 입력되지 않았습니다.');
                }
            }
        });
    });
}