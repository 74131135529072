// 캘린더 업데이트 처리
import {updateCalendar} from './updateCalendar.js';

export function calendarInit() {
    var date = new Date();
    var utc = date.getTime() + (date.getTimezoneOffset() * 60 * 1000);
    var kstGap = 9 * 60 * 60 * 1000;
    var today = new Date(utc + kstGap);

    var thisMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    // updateCalendar 함수를 이용하여 달력 초기화
    updateCalendar(thisMonth);
}