export function rsvp_toggle_dimmed(rsvpPopupData) {
    // rsvp_wrapper_dimmed 요소를 선택합니다.
    const dimmedElement = document.querySelector('.rsvp_wrapper_dimmed');
    // rsvp_wrapper_contents_wrap_control_closeView 요소를 선택합니다.
    const toggleButton = document.querySelector('.rsvp_wrapper_contents_wrap_control_closeView');
    // side_contents 요소를 선택합니다.
    const sideContents = document.querySelector('.side_contents');
    const invitationbody = document.querySelector('.invitation_body');

    if(rsvpPopupData === 1){
        dimmedElement.classList.toggle('is-active');
        invitationbody.style.overflow = 'hidden'
    }

    // 초기 상태에서 is-active 클래스가 있는지 확인하고 side_contents 스크롤 설정
    if (dimmedElement.classList.contains('is-active')) {
        sideContents.style.overflow = 'hidden';
        if(invitationbody){
            invitationbody.style.overflow = 'hidden'
        }
    } else {
        sideContents.style.overflow = 'auto';
        if(invitationbody){
            invitationbody.style.overflow = 'auto'
        }
    }

    // 버튼 클릭 시 클래스 토글
    toggleButton.addEventListener('click', function() {
        dimmedElement.classList.toggle('is-active');

        // is-active 클래스가 추가되면 side_contents 스크롤을 hidden으로 설정하고, 제거되면 auto로 설정
        if (dimmedElement.classList.contains('is-active')) {
            sideContents.style.overflow = 'hidden';
            if(invitationbody){
                invitationbody.style.overflow = 'hidden'
            }
        } else {
            sideContents.style.overflow = 'auto';
            if(invitationbody){
                invitationbody.style.overflow = 'auto'
            }
        }
    });
}
