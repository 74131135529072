// 초대장 관련 문구 선택 기능을 위한 함수
function template_invitationWords() {
    // 필요한 HTML 요소 선택
    const selectInvite = document.querySelector('#custom-select-invite');
    const optionsContainerInvite = document.querySelector('#custom-options-invite');
    const quillEditorElement = document.getElementById("invite_quill_editor");
    const inviteTitle = document.getElementById("InviteTitle");
    const inviteBody = document.getElementById("InviteBodyText");
    const inviteTitleInput = document.getElementById('InviteTitleInput');

    // 필수 요소가 없을 경우 함수 종료
    if (!selectInvite || !optionsContainerInvite || !quillEditorElement) {
        return;
    }

    // Quill 인스턴스 가져오기 (초기화된 상태라고 가정)
    const quill = Quill.find(quillEditorElement);

    // 옵션 요소들에 대한 이벤트 리스너 설정
    const optionsInvite = optionsContainerInvite.querySelectorAll(".custom-option");
    optionsInvite.forEach(option => option.addEventListener('click', selectOption));

    // 셀렉트 박스와 문서 전체에 이벤트 리스너 추가
    selectInvite.addEventListener('click', toggleOptions);
    document.addEventListener('click', hideOptions);

    // 옵션 토글 함수: 드롭다운을 보여주거나 숨깁니다.
    function toggleOptions(event) {
        event.stopPropagation();
        optionsContainerInvite.style.display = optionsContainerInvite.style.display === 'grid' ? 'none' : 'grid';
        optionsContainerInvite.style.animation = optionsContainerInvite.style.display === 'grid' ? 'slideUp 0.2s ease' : 'slideDown 0.2s ease';
    }

    // 옵션 선택 함수: 선택된 옵션에 따라 텍스트와 타이틀을 업데이트합니다.
    function selectOption(event) {
        event.stopPropagation();
        selectInvite.value = this.dataset.value;

        const titleElement = this.querySelector(".OptionTitle");
        const childElement = this.querySelector(".optiondecription");

        // 텍스트를 <p> 태그로 감싸 개행 처리
        const childElementText = childElement.innerHTML.replace(/^\s+|\s+$/g, '').split('\n').map(line => `<p>${line.trim()}</p>`).join('');

        // Quill Editor에 선택된 텍스트를 설정
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(childElementText);
        }

        // 개행 처리가 된 텍스트를 다른 요소에 설정
        inviteBody.innerHTML = childElementText;
        inviteTitleInput.value = titleElement.innerText.trim();
        inviteTitle.innerText = inviteTitleInput.value;

        optionsContainerInvite.style.display = 'none';
    }

    // 옵션 컨테이너를 숨기는 함수
    function hideOptions() {
        if (optionsContainerInvite.style.display === 'grid') {
            optionsContainerInvite.style.display = 'none';
        }
    }
}

export default template_invitationWords;
