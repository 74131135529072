export function toggleVideo(dynamicValue) {
    // 디데이 토글 모듈
    let toggleVideo = document.getElementById('toggleSwitch_video_upload');
    if (toggleVideo) {
        // 새로운 코드 추가
        if (toggleVideo.classList.contains('active')) {
            let videoClass = document.querySelectorAll(".video_section");
            let videoControl = document.getElementById('section_video_contents_wrap');
            let placeholder_video = document.querySelector('.placeholder_video');

            videoClass.forEach(videoClass => {
                videoClass.style.display = "flex";
            });
            videoControl.style.display = "flex";
            placeholder_video.style.display = "none";
        }

        toggleVideo.onclick = () => {
            toggleVideo.classList.toggle('active');
            let videoClass = document.querySelectorAll(".video_section");
            let videoControl = document.getElementById('section_video_contents_wrap');
            let placeholder_video = document.querySelector('.placeholder_video');

            videoClass.forEach(videoClass => {
                if (videoClass.style.display === "") {
                    videoClass.style.display = "flex";
                    videoControl.style.display = "flex";
                    placeholder_video.style.display = "none";
                } else if (videoClass.style.display === "none") {
                    videoClass.style.display = "flex";
                    videoControl.style.display = "flex";
                    placeholder_video.style.display = "none";
                }
                else {
                    videoClass.style.display = "none";
                    videoControl.style.display = "none";
                    placeholder_video.style.display = "flex";
                }
            });
        }
    }
}
