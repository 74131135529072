export function colorSelect() {
    // color_selector의 값이 실시간으로 변경될 때 이벤트 리스너 설정
    document.getElementById('color_selector').addEventListener('input', function () {
        const selectedColor = this.value; // 실시간으로 선택된 색상 값

        // 모든 클래스명을 순회하며 graphic_img_로 시작하는 요소 찾기
        document.querySelectorAll('[class^="graphic_img_"]').forEach((elem) => {
            elem.style.fill = selectedColor; // fill 속성을 선택된 색상으로 설정
        });

        // 모든 클래스명을 순회하며 graphic_img_로 시작하는 요소 찾기
        document.querySelectorAll('.design_graphic_des_line').forEach((elem) => {
            elem.style.background = selectedColor; // fill 속성을 선택된 색상으로 설정
        });

        // GroomName과 BrideName 클래스를 가진 요소의 CSS color 속성을 실시간으로 업데이트
        document.querySelectorAll('.GroomName, .BrideName, .design_simple_line_graphic_img_inner').forEach((elem) => {
            elem.style.color = selectedColor; // color 속성을 선택된 색상으로 설정
        });

        // 콘솔에 실시간으로 선택된 색상 출력
        console.log('선택된 색상:', selectedColor);
    });
}
