import { getInvitation } from "../api_getInvitation.js";

export function handleSaveResponse(response, dynamicValue) {
    console.log(response);

    const naverEmail = sessionStorage.getItem("naver_email");
    let myPageURL = `/api_Mypage`;

    let previewButton = document.getElementById('previewInvitation');
    let savedViewButton = document.getElementById('SavedView');
    savedViewButton.addEventListener('click', () => {
        if (myPageURL) {
            window.open(`/api_Mypage`, '_blank');
        } else {
            console.log('실패');
        }
    });

    previewButton.addEventListener('click', () => {
        getInvitation(dynamicValue)
        .then(html => {
            // 새 창으로 경로 이동
            window.open(`/api_GetInvitation/${dynamicValue}`, '_blank');
        })
        .catch(error => {
            console.error('Error:', error);
        });
    });

    if (dimmed) {
        dimmed.addEventListener('click', toggleElements);
        scrollPreventEvent.style.overflowY = 'hidden';
    }
    if (deleteBtn) {
        deleteBtn.addEventListener('click', toggleElements);
        scrollPreventEvent.style.overflowY = 'hidden';
    }
}
