export function checkContactButton(wedding_contact) {
    // JSON 문자열을 객체로 변환
    const wedding_contact_data = JSON.parse(wedding_contact);

    console.log(wedding_contact_data);

    const contactsButtonWrap = document.querySelector('.contacts_button_wrap');

    if (wedding_contact_data === null ||
        (Array.isArray(wedding_contact_data.brideContacts) && wedding_contact_data.brideContacts.length === 0) &&
        (Array.isArray(wedding_contact_data.groomContacts) && wedding_contact_data.groomContacts.length === 0)) {
        contactsButtonWrap.style.display = 'none';
    } else {
        contactsButtonWrap.style.display = 'flex';
    }
}
