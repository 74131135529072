    // 서버에서 이미지를 삭제하는 API 요청을 처리하는 함수
    function deleteImageFromServer(templateID, deleteImgURL) {
        const data = {
            urls: [deleteImgURL]
        };

        return fetch(`/api_MultiImgUpload/${templateID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json());
    }

    export default deleteImageFromServer;