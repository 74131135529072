import { QNASlide } from '../components/QNASlide.js';
import { select_coupon } from './checkout_modules/select_coupon.js';

document.addEventListener("DOMContentLoaded", function () {
    const currentUrl = window.location.href;
    const pathArray = currentUrl.split('/');
    const dynamicValue = pathArray[pathArray.length - 1];
    const disableMainPageUrl = `${pathArray[0]}//${pathArray[2]}/`;
    const disableCreateInvitationUrl = `${pathArray[0]}//${pathArray[2]}/api_CreateTemplate/${dynamicValue}`;
    const disableEditInvitationUrl = `${pathArray[0]}//${pathArray[2]}/api_EditInvitation/${dynamicValue}`;
    const disableAuth = `${pathArray[0]}//${pathArray[2]}/api_Auth/login`;
    const disableUrl = `${pathArray[0]}//${pathArray[2]}/api_GetInvitation/${dynamicValue}`;
    const disableMyPageUrl = `${pathArray[0]}//${pathArray[2]}/api_Mypage`;

    let originalAmount = 15900; // 전역 변수로 originalAmount 정의
    let finalAmount = 15900; // 전역 변수로 finalAmount 정의

    if (currentUrl === disableMyPageUrl || currentUrl === disableMainPageUrl || currentUrl === disableCreateInvitationUrl || currentUrl === disableEditInvitationUrl || currentUrl === disableAuth || currentUrl === disableUrl) {
        return;
    } else {
        const checkOutUrl = `${pathArray[0]}//${pathArray[2]}/api_getCheckOut/${pathArray[4]}`;
        console.log(checkOutUrl);

        if (currentUrl === checkOutUrl) {
            const order_stages = document.querySelectorAll('.order_stage');
            console.log(order_stages);

            order_stages.forEach((stage, index) => {
                if (index === 1) {
                    stage.style.color = "var(--ColorTextPrimary)";
                } else {
                    stage.style.color = "var(--ColorTextTertiary)";
                }
            });
        }

        select_coupon(); // 쿠폰번호 입력시 쿠폰 노출 로직

        const folder = 'product_price'; // 폴더 이름
        const filename = 'mobile_invitation_price'; // 파일 이름 (확장자 제외)

        // Fetch JSON data from server
        fetch(`/api_GetJsonData?folder=${folder}&filename=${filename}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(jsonData => {
                // Add event listeners only once using event delegation
                const checkItemsContainer = document.querySelector('.checkoutSection_bills_price_wrap_checkItems_wrap');

                checkItemsContainer.addEventListener('click', function (event) {
                    const label = event.target.closest('.checkoutSection_bills_price_wrap_checkItem');
                    if (label) {
                        document.querySelectorAll('.checkoutSection_bills_price_wrap_checkItem').forEach(item => {
                            item.classList.remove('selected');
                            item.querySelector('input').checked = false;
                        });
                        label.classList.add('selected');
                        label.querySelector('input').checked = true;

                        // Get data-type attribute
                        const selectedType = label.getAttribute('data-type');

                        // Find the matching JSON data
                        const selectedData = jsonData.find(item => item.type === selectedType);
                        if (selectedData) {
                            console.log('Selected Item:', selectedData);

                            // Update originalAmount with the price of the selected item
                            originalAmount = selectedData.price;
                            finalAmount = originalAmount;

                            updateFinalAmount(); // 초기 금액 업데이트
                        }
                    }
                });
            })
            .catch(error => console.error('Error fetching the JSON data:', error));

        // 이벤트 위임을 통해 동적으로 생성된 쿠폰 체크박스에 이벤트 리스너 추가
        document.querySelector('.added_coupon_items').addEventListener('change', function (event) {
            if (event.target && event.target.matches('#coupon-box')) {
                updateFinalAmount();
            }
        });

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        function updateFinalAmount() {
            let discountAmount = 0;
            const couponCheckbox = document.querySelector('#coupon-box');

            if (couponCheckbox && couponCheckbox.checked) {
                const discountType = couponCheckbox.getAttribute('data-discount-type');
                const discountPrice = parseInt(couponCheckbox.getAttribute('data-discount-price'), 10);

                console.log('할인 유형:', discountType); // 디버깅 코드
                console.log('할인 금액:', discountPrice); // 디버깅 코드

                if (discountType === "PERCENT") {
                    discountAmount = originalAmount * (discountPrice / 100);
                } else if (discountType === "AMOUNT") {
                    discountAmount = discountPrice;
                }

                console.log('계산된 할인 금액:', discountAmount); // 디버깅 코드
            }

            finalAmount = originalAmount - discountAmount;

            document.getElementById('coupon_amount').innerText = numberWithCommas(discountAmount) + '원';
            document.getElementById('final_amount').textContent = numberWithCommas(finalAmount) + '원';

            console.log('최종 결제 금액:', finalAmount); // 디버깅 코드

            // 결제 금액 업데이트
            if (paymentMethodWidget) {
                paymentMethodWidget.updateAmount(finalAmount);
            }
        }

        let paymentMethodWidget;

        async function fetchSecretKey(widgetClientKey) {
            try {
                const response = await fetch('/api_getSecretKey');
                if (!response.ok) {
                    throw new Error('Failed to fetch secret key');
                }
                const data = await response.json();
                widgetClientKey = data.key;

                let orderID = document.querySelector('.checkoutSection_order_info').getAttribute('data-value');
                let productName = document.querySelector('.checkoutSection_order_info').getAttribute('data-product-value');

                console.log(widgetClientKey);
                console.log(orderID);

                const customerKey = orderID;
                const paymentWidget = PaymentWidget(widgetClientKey, customerKey);

                paymentMethodWidget = paymentWidget.renderPaymentMethods(
                    "#payment-method",
                    { value: originalAmount },
                    { variantKey: "DEFAULT" }
                );

                paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

                let userInfoData;

                const templateURL = checkOutUrl.split('?info=')[1];
                console.log(templateURL);

                const userInfoUrl = `/api_UserInfo/${templateURL}`;

                fetch(userInfoUrl)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Network response was not ok.');
                    })
                    .then(data => {
                        userInfoData = data;

                        function handlePaymentRequest() {
                            const couponCheckbox = document.querySelector('#coupon-box:checked');
                            const couponID = couponCheckbox ? couponCheckbox.getAttribute('data-coupon_ID') : null;

                            // selected 클래스가 추가되어있는 체크 아이템을 찾음
                            let selectedElement = document.querySelector('.checkoutSection_bills_price_wrap_checkItem.selected');

                            // data-type 속성 값을 가져옴
                            let dataType = selectedElement.getAttribute('data-type');

                            // data-type에 따라 invitation_keep_status 값을 설정
                            let invitation_keep_status;
                            if (dataType === 'permanent_keep') {
                                invitation_keep_status = 1;
                            } else if (dataType === 'base_keep') {
                                invitation_keep_status = 0;
                            }

                            console.log('couponID:', couponID); // 디버깅 코드
                            console.log('최종 결제 금액:', finalAmount); // 디버깅 코드

                            fetch('/api_temCheckOut', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    orderId: orderID,
                                    amount: finalAmount,
                                    coupon_ID: couponID,
                                    invitation_keep_status: invitation_keep_status // 설정된 값을 사용
                                })
                            })
                                .then(response => response.json())
                                .then(data => {
                                    if (data.success) {
                                        paymentWidget.requestPayment({
                                            orderId: orderID,
                                            orderName: productName,
                                            successUrl: window.location.origin + "/success",
                                            failUrl: window.location.origin + "/fail",
                                            customerEmail: userInfoData.naver_email,
                                            customerName: userInfoData.user_name,
                                            customerMobilePhone: userInfoData.user_tel,
                                            amount: finalAmount,
                                            templateID: templateURL,
                                            couponID: couponID
                                        }).catch(error => {
                                            alert(`${error.message}`);
                                        });
                                    } else {
                                        throw new Error('Failed to save the order temporarily.');
                                    }
                                })
                                .catch(error => {
                                    console.error('There was a problem with your save order operation:', error);
                                    alert(`${error.message}`);
                                });

                        }

                        // 기존 버튼과 새로 추가된 버튼에 이벤트 리스너 추가
                        const paymentButton = document.getElementById("payment-request-button");
                        const floatingPaymentButton = document.getElementById("payment-request-button-floating");

                        if (paymentButton) {
                            paymentButton.addEventListener("click", handlePaymentRequest);
                        }
                        if (floatingPaymentButton) {
                            floatingPaymentButton.addEventListener("click", handlePaymentRequest);
                        }

                        console.log(data);
                    })
                    .catch(error => {
                        console.error('There has been a problem with your fetch operation:', error);
                    });

                updateFinalAmount();

            } catch (error) {
                console.error('Error fetching secret key:', error);
            }
        }

        fetchSecretKey();

        window.addEventListener('scroll', function () {
            var button = document.getElementById('floating_payment_btn');
            var target = document.querySelector('.checkoutSection_bills').getBoundingClientRect();
            var targetBottomPosition = target.bottom + window.scrollY; // 타겟 요소의 하단부 위치 계산

            if (window.scrollY + window.innerHeight >= targetBottomPosition) {
                button.style.display = 'none';
            } else {
                if (window.innerWidth < 560) { // 해상도가 560px 미만일 때만 버튼 보이기
                    button.style.display = 'block';
                }
            }
        });
    }
});
