function template_styling() {
    (async () => {
        var cropper;
        const overlay = document.getElementById('overlay');
        const photoBtns = {
            title: document.getElementById('photoBtn'),
            kakaoShare: document.getElementById('photoBtn_kakaoShare'),
            linkShare: document.getElementById('photoBtn_linkShare'),
        };
        const photoThemes = {
            title: document.querySelector('.photo_theme_title'),
            kakaoShare: document.querySelector('.photo_theme_kakao'),
            linkShare: document.querySelector('.photo_theme_link'),
        };
        const completeBtn = document.getElementById('complete');
        const cancelBtn = document.getElementById('cancel');
        const cropDimmed = document.getElementById('CropDimmed');
        const themImg = document.querySelector('.them_img');
        const imageElement = document.createElement('img');
        const completeText = document.querySelector('.button-text');
        const loader = document.querySelector('.loader');
        const zoomSlider = document.getElementById('zoomSlider');
        const titleGraphicCropper = document.querySelector('.titleGraphic_cropper');
        imageElement.id = 'image';

        let activeUploadType = 'title'; // 현재 활성화된 업로드 타입을 추적
        let activeTabAspectRatio = 9 / 16; // 탭 비율

        const toggleVisibility = (element, displayStyle) => {
            if (element) {
                element.style.display = displayStyle;
            }
        };

        const readFileAsDataURL = (file) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });

        zoomSlider.addEventListener('input', function (event) {
            const zoomLevel = event.target.value;
            if (cropper) {
                cropper.zoomTo(zoomLevel);
            }
        });

        const resizeAndCompressImage = async (image, maxWidth, maxHeight, quality) => {
            const canvas = document.createElement('canvas');
            let { width, height } = image;

            if (width > maxWidth || height > maxHeight) {
                const ratio = width / height;
                if (ratio > 1) {
                    width = maxWidth;
                    height = maxWidth / ratio;
                } else {
                    height = maxHeight;
                    width = maxHeight * ratio;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            return new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', quality / 100);
            });
        };

        const getCropAspectRatio = (uploadType) => {
            switch (uploadType) {
                case 'title':
                    return getActiveTabAspectRatio(); // title의 경우 현재 탭 비율을 적용
                case 'kakaoShare':
                    return 1 / 1; // 카카오 공유 이미지 1:1 비율
                case 'linkShare':
                    return 2 / 1; // 링크 공유 이미지 2:1 비율
                default:
                    return null; // 기본값
            }
        };

        const getApiEndpoint = (uploadType, templateID) => {
            switch (uploadType) {
                case 'title':
                    return `../api_TitleImgUpload/${templateID}`;
                case 'kakaoShare':
                    return `../api_KakaoImgUpload/${templateID}`;
                case 'linkShare':
                    return `../api_LinkImgUpload/${templateID}`;
                default:
                    throw new Error('Unknown upload type');
            }
        };

        const getActiveTabAspectRatio = () => {
            const activeTab = document.querySelector('.TabItemDesign.Active');
            const activeTabId = activeTab ? activeTab.id : 'none';

            console.log(`getActiveTabAspectRatio called, activeTabId: ${activeTabId}`); // 디버깅 메시지

            switch (activeTabId) {
                case 'DesignArch':
                case 'DesignTrip':
                case 'DesignTogether':
                case 'DesignSimple':
                case 'DesignRadius':
                case 'DesignSimple_line_01':
                case 'DesignSimple_line_02':
                case 'DesignSimple_line_03':
                    return 9 / 16;
                case 'DesignBase':
                    return null; // DesignBase는 원본 비율 유지
                default:
                    return 9 / 16; // 기본값
            }
        };

        const createCropper = (aspectRatio) => {
            if (cropper) {
                cropper.destroy();
            }
            themImg.innerHTML = '';  // 기존 이미지를 제거
            themImg.appendChild(imageElement);  // 새 이미지를 추가

            // 해상도에 따라 minContainerWidth와 minContainerHeight 설정
            let minContainerWidth = 600;
            let minContainerHeight = 600;
            if (window.innerWidth <= 560) {
                minContainerWidth = 300;
                minContainerHeight = 300;
            } else if (window.innerWidth <= 1280) {
                minContainerWidth = 480;
                minContainerHeight = 480;
            }

            console.log(`createCropper called with aspectRatio: ${aspectRatio}`); // 디버깅 메시지

            cropper = new Cropper(imageElement, {
                dragMode: 'move',
                viewMode: 1,
                aspectRatio: aspectRatio,  // aspectRatio 값이 null일 경우 원본 비율 유지
                autoCropArea: 0.9,
                minContainerWidth: minContainerWidth,
                minContainerHeight: minContainerHeight,
                restore: false,
                guides: true,
                center: true,
                highlight: true,
                cropBoxMovable: false,
                cropBoxResizable: false,
                toggleDragModeOnDblclick: false,
                ready: function () {
                    // Cropper 초기화가 완료된 후에 버튼 가시성 설정
                    toggleVisibility(completeBtn, 'flex');
                    toggleVisibility(cancelBtn, 'block');
                    toggleVisibility(overlay, 'none'); // 오버레이 숨기기
                    completeText.style.display = "block";
                    loader.style.display = "none";
                    toggleVisibility(zoomSlider, 'block');

                    // Cropper 초기화가 완료된 후에 크롭 박스 크기를 가져옴
                    const cropBoxData = cropper.getCropBoxData();
                    titleGraphicCropper.style.width = `${cropBoxData.width}px`;
                    titleGraphicCropper.style.height = `${cropBoxData.height}px`;
                    titleGraphicCropper.style.display = 'block';

                    console.log('Cropper initialized with cropBoxData:', cropBoxData); // 디버깅 메시지
                },
            });
        };

        const initializeCropper = (uploadType) => {
            const aspectRatio = getCropAspectRatio(uploadType); // getCropAspectRatio로 비율을 가져옴

            console.log(`initializeCropper called for uploadType: ${uploadType}, aspectRatio: ${aspectRatio}`); // 디버깅 메시지
            createCropper(aspectRatio);
        };

        const cropAndUpload = async () => {
            try {
                completeText.style.display = "none";
                loader.style.display = "block";

                const isMobile = window.innerWidth <= 560;
                const croppedCanvas = cropper.getCroppedCanvas({
                    maxWidth: isMobile ? 2000 : 4000,
                    maxHeight: isMobile ? 2000 : 4000,
                    fillColor: '#fff', // 투명도를 방지하기 위해 배경색 지정
                });

                const compressedBlob = await resizeAndCompressImage(croppedCanvas, croppedCanvas.width, croppedCanvas.height, 80);

                const formData = new FormData();
                formData.append('images', compressedBlob, 'image.jpg');

                let templateID = window.location.pathname.split('/').pop().replace('template_', '').replace('.html', '');
                const apiEndpoint = getApiEndpoint(activeUploadType, templateID);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    if (activeUploadType === 'title') {
                        document.querySelectorAll('#preview-image, #TitleImgUpload, #KaKaosrc').forEach(el => el.src = data.fileUrls);
                    } else if (activeUploadType === 'kakaoShare') {
                        document.getElementById('kakao_upload_src').src = data.fileUrls;
                    } else if (activeUploadType === 'linkShare') {
                        document.getElementById('link_upload_src').src = data.fileUrls;
                    }
                    alert('이미지가 변경되었습니다.');
                    loader.style.display = "none";
                    toggleVisibility(overlay, 'none');
                    themImg.innerHTML = '';
                    cropDimmed.classList.toggle('is-active');
                    toggleVisibility(photoThemes[activeUploadType], 'none');
                    toggleVisibility(completeBtn, 'none');
                    toggleVisibility(cancelBtn, 'none');
                    toggleVisibility(zoomSlider, 'none');
                    titleGraphicCropper.style.display = 'none';
                } else {
                    console.log('Upload error', response.status);
                }
            } catch (error) {
                console.error(error);
            }
        };

        completeBtn.addEventListener('click', async () => await cropAndUpload());

        cancelBtn.addEventListener('click', () => {
            cropDimmed.classList.toggle('is-active');
            toggleVisibility(completeBtn, 'none');
            toggleVisibility(cancelBtn, 'none');
            toggleVisibility(zoomSlider, 'none');
            titleGraphicCropper.style.display = 'none';
        });

        Object.keys(photoBtns).forEach(uploadType => {
            photoBtns[uploadType].addEventListener('change', async (event) => {
                try {
                    const file = event.target.files[0];
                    const fileForm = /\.(jpg|jpeg|png)$/i;

                    if (!fileForm.test(file.name)) {
                        throw new Error('이미지 파일(jpg, jpeg, png 형식의 파일)만 올려주세요.');
                    }
                    activeUploadType = uploadType;
                    toggleVisibility(overlay, 'block');
                    toggleVisibility(photoThemes[uploadType], 'block');
                    themImg.innerHTML = '';
                    themImg.appendChild(imageElement);
                    imageElement.src = await readFileAsDataURL(file);
                    cropDimmed.classList.toggle('is-active');
                    initializeCropper(uploadType);
                    toggleVisibility(overlay, 'none');
                    completeText.style.display = "block";
                    loader.style.display = "none";

                    if (activeUploadType === 'title') {
                        const activeTab = document.querySelector('.TabItemDesign.Active');
                        if (activeTab) {
                            console.log(`Selected tab during upload: ${activeTab.id}`);

                            document.querySelectorAll('.titleGraphic_cropper .graphic').forEach(graphic => {
                                graphic.classList.add('hidden');
                            });
                            const selectedGraphic = document.getElementById(`graphic${activeTab.id}`);
                            if (selectedGraphic) {
                                selectedGraphic.classList.remove('hidden');
                            }
                        }
                    } else {
                        document.querySelectorAll('.titleGraphic_cropper .graphic').forEach(graphic => {
                            graphic.classList.add('hidden');
                        });
                    }

                } catch (error) {
                    alert(error.message);
                    photoBtns[uploadType].focus();
                    toggleVisibility(photoThemes[uploadType], 'none');
                    toggleVisibility(completeBtn, 'none');
                    toggleVisibility(cancelBtn, 'none');
                    toggleVisibility(zoomSlider, 'none');
                    titleGraphicCropper.style.display = 'none';
                }
            });
        });

        // 탭 변경 시 크로퍼 비율 업데이트
        document.querySelectorAll('.TabItemDesign').forEach(tab => {
            tab.addEventListener('click', () => {
                activeTabAspectRatio = getActiveTabAspectRatio();
                console.log(`Tab clicked, new activeTabAspectRatio: ${activeTabAspectRatio}`); // 디버깅 메시지
                if (cropper) {
                    cropper.setAspectRatio(activeTabAspectRatio);
                }
            });
        });

    })();

    function replaceImageSrc(buttonId, targetImgId) {
        document.getElementById(buttonId).addEventListener('click', function () {
            const titleImgSrc = document.getElementById('TitleImgUpload').src;
            document.getElementById(targetImgId).src = titleImgSrc;
        });
    }

    replaceImageSrc('kakaoShareImg_reset', 'kakao_upload_src');
    replaceImageSrc('linkShareImg_reset', 'link_upload_src');

}
export default template_styling;
