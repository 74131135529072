export function select_coupon() {
    document.getElementById('coupon_check_button').addEventListener('click', function () {
        const couponInput = document.querySelector('.checkoutSection_coupon_input').value;

        fetch('/get_coupon_info')
            .then(response => response.json())
            .then(data => {
                const couponData = data.couponData;
                const matchingCoupon = couponData.find(coupon => coupon.code === couponInput);

                if (matchingCoupon) {
                    alert('쿠폰 등록에 성공했습니다.');

                    const couponHTML = `
                        <div class="checkoutSection_coupon_item">
                            <div class="checkoutSection_coupon_item_header">
                                <div class="checkoutSection_coupon_item_header_info">
                                    <span class="checkoutSection_coupon_item_price">${matchingCoupon.discount_price}%</span>
                                    <span class="checkoutSection_coupon_item_title">${matchingCoupon.name}</span>
                                </div>
                                <div>
                                    <input class="checkoutSection_coupon_item_header_checkbox" id="coupon-box" type="checkbox" data-coupon_ID="${matchingCoupon.coupon_id}" data-discount-type="${matchingCoupon.discount_type}" data-discount-price="${matchingCoupon.discount_price}">
                                </div>
                            </div>
                            <div class="checkoutSection_coupon_item_body_info">
                                <div class="checkoutSection_coupon_item_body_info_coupon_title">
                                    ${matchingCoupon.name}
                                </div>
                                <div class="checkoutSection_coupon_item_body_info_coupon_expireDate">
                                    ${matchingCoupon.valid_until}
                                </div>
                                <div class="checkoutSection_coupon_item_body_info_coupon_useGuide">계정당 1회 사용가능(중복사용 불가)</div>
                            </div>
                        </div>`;

                    document.querySelector('.added_coupon_items').innerHTML = couponHTML;
                } else {
                    alert('쿠폰 번호가 일치하지 않습니다.');
                }
            })
            .catch(error => {
                console.error('Error fetching coupon data:', error);
                alert('쿠폰 데이터를 가져오는 중 오류가 발생했습니다.');
            });
    });
}