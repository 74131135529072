export function galleryAppear(){
    // 갤러리 영역과 그리드 컨테이너 선택
    let gallery = document.querySelector('.GalleryArea');
    let gridContainer = document.querySelector('.grid-container');

    // grid-container 내부의 grid-item 개수 확인
    let gridItems = gridContainer.querySelectorAll('.grid-item');

    // grid-item 개수가 1개 이상인지 확인
    if (gridItems.length > 0) {
        // GalleryTitleArea와 grid-container를 block과 grid로 설정
        document.querySelector('.GalleryTitleArea').style.display = "block";
        gridContainer.style.display = "grid";
        // 갤러리 영역 전체를 block으로 설정
        gallery.style.display = "flex";
    } else {
        // grid-item 개수가 0개인 경우 감추거나 다른 조치를 취할 수 있습니다.
        // 예를 들어, 갤러리 영역을 숨기거나, "이미지가 없습니다"와 같은 메시지를 표시할 수 있습니다.
    }
}