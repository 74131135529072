import { GetDBInfo } from '../../api/api_dbInsert.js';
import { updateCalendar } from './updateCalendar.js';
import { createKSTDate } from './createKSTDate.js';
import { startCountdown } from './countdown.js';
import { updateDateFormat } from '../../views/template_modules/template_marriageInfo_modules/updateDateFormat.js';


export function dateChangeInvitation(templateId) {
    GetDBInfo(templateId)
        .then(data => {
            console.log('dday 상태값 체크' + data.dday_status);

            function dday_status_check(){
                let ddaysection = document.querySelector('.ddaysection');
                let WeddingCounterWrap = document.querySelector('.WeddingCounterWrap');

                if (data.dday_status === 1) {
                    ddaysection.style.display = "flex";
                    WeddingCounterWrap.style.display = "flex";
                } else {
                    ddaysection.style.display = "none";
                    WeddingCounterWrap.style.display = "none";
                }
            }

            dday_status_check();

            const weddingDate = data.weddingDate; // 예: "2024년 4\n5 금요일"
            console.log(weddingDate);

            const formattedDate = weddingDate.replace('\n', '월 ').replace(/(\d)(\s\D)/, '$1일$2');
            console.log(formattedDate); // "2024년 4월 5일 금요일" 형식으로 출력됩니다.

            const [, year, month, day] = formattedDate.match(/(\d+)\s*년\s*(\d+)\s*월\s*(\d+)/);

            // 시간과 분 추가
            let hour = parseInt(data.wedding_time, 10); // 시간 파싱
            let minute = parseInt(data.wedding_minute, 10); // 분 파싱
            if (data.wedding_AMPM === '오후' && hour !== 12) hour += 12; // 오후일 경우 12시간 더하기
            if (data.wedding_AMPM === '오전' && hour === 12) hour = 0; // 오전 12시는 0시

            let selectedDate = createKSTDate([year, month.padStart(2, '0'), day.padStart(2, '0')]);
            selectedDate.setHours(hour, minute, 0, 0); // 시간과 분 설정

            // 캘린더 및 카운트다운 업데이트
            updateCalendar(selectedDate);
            startCountdown(selectedDate);

            // 캘린더 날짜 갱신
            let selectedDay = selectedDate.getDate();
            let calendarDays = document.querySelectorAll('.dates .day.current');
            calendarDays.forEach(day => {
                if (parseInt(day.textContent) === selectedDay) {
                    day.classList.add('today');
                } else {
                    day.classList.remove('today');
                }
            });

            let days = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
            let dayName = days[selectedDate.getDay()];
            document.getElementById("textDate_calendar_month").innerText = `${month}`;
            document.getElementById("textDate_calendar_day").innerText = `${day}`;

            let today = new Date();
            today.setHours(0, 0, 0, 0);
            let dDay = Math.floor((selectedDate - today) / (1000 * 60 * 60 * 24));
            document.getElementById('dday').innerText = `${dDay}일`;


            let dataFormatValue = data.weddingDate_format[0]['data-value'];
            console.log('테스트중' + dataFormatValue);
            updateDateFormat(dataFormatValue, data.weddingDate);


        })
        .catch(error => {
            console.error('오류:', error);
        });
}
