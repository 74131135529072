import { contacts } from "../invitation_modules/inviteGreetings/contact";
import { initializeContactButtons } from "./template_coupleInfo_modules/init_contact_button";

function template_coupleInfo() {

    function checkAndHideDot(firstNameId, lastNameId, dotId) {
        let firstName = document.getElementById(firstNameId).innerHTML.trim();
        let lastName = document.getElementById(lastNameId).innerHTML.trim();
        let dotElement = document.getElementById(dotId);
        let bride_parents_wrap = document.querySelector('.bride_parents_wrap');
        let groom_parents_wrap = document.querySelector('.groom_parents_wrap');
    
        if (firstName === '' && lastName === '') {
            if (dotElement) {
                dotElement.style.display = 'none';
            }
            if (bride_parents_wrap) {
                bride_parents_wrap.style.justifyContent = 'center';
            }
            if (groom_parents_wrap) {
                groom_parents_wrap.style.justifyContent = 'center';
            }
        } else {
            if (bride_parents_wrap) {
                bride_parents_wrap.style.justifyContent = 'end';
            }
            if (groom_parents_wrap) {
                groom_parents_wrap.style.justifyContent = 'end';
            }
        }
    }
    
    // 각 가족 구성원에 대해 함수 호출
    checkAndHideDot('GroomMotherFirstName', 'GroomMotherLastName', 'dot_groomFamily');
    checkAndHideDot('GroomFatherFirstName', 'GroomFatherLastName', 'dot_groomFamily');
    checkAndHideDot('BrideFatherFirstName', 'BrideFatherLastName', 'dot_brideFamily');
    checkAndHideDot('BrideMotherFirstName', 'BrideMotherLastName', 'dot_brideFamily');
    



    // 가족관계 상태값 정의

    function handleStatusCheckboxChange(statusCheckbox, statusTypeSelect, statusInfoElement, mumElement) {
        const handleStatusTypeSelectChange = () => {
            const selectedValue = statusTypeSelect.value;
            if (selectedValue === "국화꽃") {
                if (mumElement) mumElement.style.display = "block";
                statusInfoElement.innerText = "";
            } else if (selectedValue === "故") {
                if (mumElement) mumElement.style.display = "none";
                statusInfoElement.innerText = "故";
            }
        };

        const handleStatusCheckboxClick = () => {
            let selectedValue = statusTypeSelect.value;
            if (statusCheckbox.checked) {
                statusTypeSelect.removeAttribute("disabled");
                statusTypeSelect.style.cursor = 'pointer';
                statusTypeSelect.onmouseover = function () {
                    statusTypeSelect.style.border = '1px solid var(--ColorTextPrimary)';
                }
                statusTypeSelect.onmouseout = function () {
                    statusTypeSelect.style.border = '1px solid var(--ColorBorderSolid)';
                }
            } else {
                statusTypeSelect.setAttribute("disabled", "disabled");
                statusTypeSelect.style.cursor = 'not-allowed';
                statusTypeSelect.value = "故";
                selectedValue = "";
                if (mumElement) mumElement.style.display = "none";
            }
            statusInfoElement.innerText = selectedValue;
        };

        statusCheckbox.addEventListener("click", handleStatusCheckboxClick);
        statusTypeSelect.addEventListener("change", handleStatusTypeSelectChange);
    }


    // 신랑 아버지 상태값 체크박스 선택
    const GroomFatherStatusCheckbox = document.getElementById('Groomfatherstatus');
    if (GroomFatherStatusCheckbox) {
        const GroomFatherStatusTypeSelect = document.getElementById("Groomfatherstatustype");
        const GroomFatherStatusInfo = document.querySelector("#GroomFatherStatusInfo");
        const GroomFatherMum = document.getElementById('GroomFatherMum');
        handleStatusCheckboxChange(GroomFatherStatusCheckbox, GroomFatherStatusTypeSelect, GroomFatherStatusInfo, GroomFatherMum);
    }

    // 신랑 어머니 상태값 체크박스 선택
    const GroomMotherStatusCheckbox = document.getElementById('Groommotherstatus');
    if (GroomMotherStatusCheckbox) {
        const GroomMotherStatusTypeSelect = document.getElementById("Groommotherstatustype");
        const GroomMotherStatusInfo = document.querySelector("#GroomMotherStatusInfo");
        const GroomMotherMum = document.getElementById('GroomMotherMum');
        handleStatusCheckboxChange(GroomMotherStatusCheckbox, GroomMotherStatusTypeSelect, GroomMotherStatusInfo, GroomMotherMum);
    }

    // 신부 아버지 상태값 체크박스 선택
    const BrideFatherStatusCheckbox = document.getElementById('Bridefatherstatus');
    if (BrideFatherStatusCheckbox) {
        const BrideFatherStatusTypeSelect = document.getElementById("Bridefatherstatustype");
        const BrideFatherStatusInfo = document.querySelector("#BridefatherStatusInfo");
        const BrideFatherMum = document.getElementById('BrideFatherMum');
        handleStatusCheckboxChange(BrideFatherStatusCheckbox, BrideFatherStatusTypeSelect, BrideFatherStatusInfo, BrideFatherMum);
    }

    // 신부 어머니 상태값 체크박스 선택
    const BrideMotherStatusCheckbox = document.getElementById('Bridemotherstatus');
    if (BrideMotherStatusCheckbox) {
        const BrideMotherStatusTypeSelect = document.getElementById("Bridemotherstatustype");
        const BrideMotherStatusInfo = document.querySelector("#BrideMotherStatusInfo");
        const BrideMotherMum = document.getElementById('BrideMotherMum');
        handleStatusCheckboxChange(BrideMotherStatusCheckbox, BrideMotherStatusTypeSelect, BrideMotherStatusInfo, BrideMotherMum);
    }



    // 연락처 함수 정리

    let brideIndex = document.querySelectorAll('.checkselection_contacts_bride_item_wrap').length + 1;
    let groomIndex = document.querySelectorAll('.checkselection_contacts_groom_item_wrap').length + 1;

    // 신랑&신부 연락처 추가 함수
    document.getElementById('add_contacts_bride').addEventListener('click', function () {
        addContact('bride');
    });

    document.getElementById('add_contacts_groom').addEventListener('click', function () {
        addContact('groom');
    });

    function addContact(type) {
        let newIndex;
        if (type === 'bride') {
            newIndex = brideIndex++;
        } else {
            newIndex = groomIndex++;
        }

        var wrap = document.querySelector(`.checkselection_contacts_${type}_wrap`);
        var newContactItem = document.createElement('div');
        newContactItem.className = `checkselection_contacts_${type}_item_wrap`;
        newContactItem.setAttribute('data-id', `checkselection_contacts_${type}`);
        newContactItem.setAttribute('data-index', newIndex);

        newContactItem.innerHTML = `
    <div class="checkselection_contacts_item" data-id="checkselection_contacts_${type}" data-index="${newIndex}">
        <div class="checkselection_inner">
            <select class="selectbox_contact" id="selectbox_contact_${type}">
                <option value="${type === 'bride' ? '신부' : '신랑'}" data-value="contact_${type}">${type === 'bride' ? '신부' : '신랑'}</option>
                <option value="${type === 'bride' ? '신부 아버지' : '신랑 아버지'}" data-value="contact_${type}_father">${type === 'bride' ? '신부 아버지' : '신랑 아버지'}</option>
                <option value="${type === 'bride' ? '신부 어머니' : '신랑 어머니'}" data-value="contact_${type}_mother">${type === 'bride' ? '신부 어머니' : '신랑 어머니'}</option>
            </select>
            <div class="delete_contact">
                <img class="delete_contact_img" src="../Resource/templates/utility/delete.svg" alt="삭제">
            </div>
        </div>
        <textarea class="TextBox" name="" id="input_contact_${type}" placeholder="연락처를 입력해 주세요(ex.01158052109)"></textarea>
    </div>
    `;

        wrap.insertBefore(newContactItem, document.querySelector(`.add_contacts_${type}`));

        // 연락처 아이템 추가
        var contactItemInner = document.querySelector(`.contact_item_inner_${type}`);
        if (!contactItemInner) {
            contactItemInner = document.createElement('div');
            contactItemInner.className = `contact_item_inner_${type}`;
            if (type === 'groom') {
                document.querySelector('.contact_item_inner').prepend(contactItemInner);
            } else {
                document.querySelector('.contact_item_inner').appendChild(contactItemInner);
            }
        }

        var newContactItemInner = document.createElement('div');
        newContactItemInner.className = 'contact_item_wrap';
        newContactItemInner.setAttribute('data-index', newIndex);
        newContactItemInner.setAttribute('data-name', `contact_${type}_groop`);

        newContactItemInner.innerHTML = `
    <div class="contact_item_info_wrap">
        <div class="contact_item_info_relation">
            ${type === 'bride' ? '❤︎ 신부' : '❤︎ 신랑'}
        </div>
        <div class="contact_item_info_name">
            <!-- 이름은 여기에 입력 -->
        </div>
    </div>
    <div class="contact_item_info_button_groups">
        <div class="contact_item_info_button_call_wrap">
            <div class="contact_item_info_button_call"></div>
        </div>
        <div class="contact_item_info_button_message_wrap">
            <div class="contact_item_info_button_message"></div>
        </div>
    </div>
    `;

        contactItemInner.appendChild(newContactItemInner);

        // 연락처 상태 업데이트
        updateContactButtonVisibility();

        // 삭제 버튼 클릭 이벤트 추가
        newContactItem.querySelector('.delete_contact_img').addEventListener('click', function () {
            newContactItem.remove();
            newContactItemInner.remove();
            if (type === 'bride') {
                brideIndex--;
            } else {
                groomIndex--;
            }
            updateIndexes();
            updateContactButtonVisibility();
        });

        // 셀렉트박스 변경 이벤트 추가
        newContactItem.querySelector('.selectbox_contact').addEventListener('change', function () {
            var selectedValue = this.options[this.selectedIndex].value;
            newContactItemInner.querySelector('.contact_item_info_relation').innerText = selectedValue;
            updateContactName(type, newIndex, selectedValue);
        });

        // 페이지 로드 시 초기 이름 설정
        updateContactName(type, newIndex, type === 'bride' ? '신부' : '신랑');

        // 텍스트박스 입력 이벤트 추가
        newContactItem.querySelector('.TextBox').addEventListener('input', function () {
            var inputValue = this.value;
            var dataIndex = newContactItem.getAttribute('data-index');
            var dataName = `contact_${type}_groop`;
            var correspondingContactItem = document.querySelector(`.contact_item_wrap[data-index="${dataIndex}"][data-name="${dataName}"]`);
            if (correspondingContactItem) {
                correspondingContactItem.setAttribute('data-value', inputValue);
            }
        });

        initializeDeleteButtons();
        initializeContactButtons();
        initializeTextBoxInput();
    }

    function updateContactName(type, index, relation) {
        let firstNameInput = '', lastNameInput = '';

        if (type === 'bride') {
            switch (relation) {
                case '신부':
                    firstNameInput = document.getElementById('BrideFirstNameInput') ? document.getElementById('BrideFirstNameInput').value : '';
                    lastNameInput = document.getElementById('BrideLastNameInput') ? document.getElementById('BrideLastNameInput').value : '';
                    break;
                case '신부 아버지':
                    firstNameInput = document.getElementById('BrideFatherFirstNameInput') ? document.getElementById('BrideFatherFirstNameInput').value : '';
                    lastNameInput = document.getElementById('BrideFatherLastNameInput') ? document.getElementById('BrideFatherLastNameInput').value : '';
                    break;
                case '신부 어머니':
                    firstNameInput = document.getElementById('BrideMotherFirstNameInput') ? document.getElementById('BrideMotherFirstNameInput').value : '';
                    lastNameInput = document.getElementById('BrideMotherLastNameInput') ? document.getElementById('BrideMotherLastNameInput').value : '';
                    break;
            }
        } else {
            switch (relation) {
                case '신랑':
                    firstNameInput = document.getElementById('GroomFirstNameInput') ? document.getElementById('GroomFirstNameInput').value : '';
                    lastNameInput = document.getElementById('GroomLastNameInput') ? document.getElementById('GroomLastNameInput').value : '';
                    break;
                case '신랑 아버지':
                    firstNameInput = document.getElementById('GroomFatherFirstNameInput') ? document.getElementById('GroomFatherFirstNameInput').value : '';
                    lastNameInput = document.getElementById('GroomFatherLastNameInput') ? document.getElementById('GroomFatherLastNameInput').value : '';
                    break;
                case '신랑 어머니':
                    firstNameInput = document.getElementById('GroomMotherFirstNameInput') ? document.getElementById('GroomMotherFirstNameInput').value : '';
                    lastNameInput = document.getElementById('GroomMotherLastNameInput') ? document.getElementById('GroomMotherLastNameInput').value : '';
                    break;
            }
        }

        var contactNameElement = document.querySelector(`.contact_item_wrap[data-index="${index}"][data-name="contact_${type}_groop"] .contact_item_info_name`);
        if (contactNameElement) {
            contactNameElement.innerText = `${firstNameInput} ${lastNameInput}`.trim();
        }
    }

    function updateIndexes() {
        const allBrideItems = document.querySelectorAll('.checkselection_contacts_bride_item_wrap');
        const allGroomItems = document.querySelectorAll('.checkselection_contacts_groom_item_wrap');

        allBrideItems.forEach((item, index) => {
            item.setAttribute('data-index', index + 1);
            item.querySelector('.checkselection_contacts_item').setAttribute('data-index', index + 1);
        });

        allGroomItems.forEach((item, index) => {
            item.setAttribute('data-index', index + 1);
            item.querySelector('.checkselection_contacts_item').setAttribute('data-index', index + 1);
        });

        const brideContactItems = document.querySelectorAll('.contact_item_wrap[data-name="contact_bride_groop"]');
        brideContactItems.forEach((item, index) => {
            item.setAttribute('data-index', index + 1);
        });

        const groomContactItems = document.querySelectorAll('.contact_item_wrap[data-name="contact_groom_groop"]');
        groomContactItems.forEach((item, index) => {
            item.setAttribute('data-index', index + 1);
        });
    }

    // 초기화 함수: 페이지 로드 시 기존 연락처 항목들에 대해 삭제 이벤트 리스너 추가
    function initializeDeleteButtons() {
        const allDeleteButtons = document.querySelectorAll('.delete_contact_img');

        allDeleteButtons.forEach(button => {
            button.addEventListener('click', function () {
                const parentWrap = button.closest('.checkselection_contacts_bride_item_wrap') || button.closest('.checkselection_contacts_groom_item_wrap');
                if (parentWrap) {
                    const dataIndex = parentWrap.getAttribute('data-index');
                    const dataType = parentWrap.classList.contains('checkselection_contacts_bride_item_wrap') ? 'bride' : 'groom';
                    const correspondingContactItem = document.querySelector(`.contact_item_wrap[data-index="${dataIndex}"][data-name="contact_${dataType}_groop"]`);

                    if (correspondingContactItem) {
                        correspondingContactItem.remove();
                    }

                    parentWrap.remove();
                    updateIndexes();
                    updateContactButtonVisibility();
                }
            });
        });
    }

    // 연락처 상태 업데이트 함수
    function updateContactButtonVisibility() {
        const brideItems = document.querySelectorAll('.checkselection_contacts_bride_item_wrap').length;
        const groomItems = document.querySelectorAll('.checkselection_contacts_groom_item_wrap').length;
        const contactsButtonWrap = document.querySelector('.contacts_button_wrap');

        if (brideItems === 0 && groomItems === 0) {
            contactsButtonWrap.style.display = 'none';
        } else {
            contactsButtonWrap.style.display = 'flex';
        }
    }

    // 연락처 클릭시 동작 함수 초기화
    function initializeContactButtons() {
        const allCallButtons = document.querySelectorAll('.contact_item_info_button_call_wrap');
        const allMessageButtons = document.querySelectorAll('.contact_item_info_button_message_wrap');

        allCallButtons.forEach(button => {
            button.addEventListener('click', function () {
                const parentWrap = button.closest('.contact_item_wrap');
                if (parentWrap) {
                    var phoneNumber = parentWrap.getAttribute('data-value');
                    if (phoneNumber) {
                        phoneNumber = phoneNumber.replace(/\D/g, ''); // 숫자만 추출
                        var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
                        var telLink = isIOS ? `tel://${phoneNumber}` : `tel:${phoneNumber}`;
                        console.log(`전화 걸기: ${telLink}`);
                        window.location.href = telLink;
                    } else {
                        alert('전화 번호가 입력되지 않았습니다.');
                    }
                }
            });
        });

        allMessageButtons.forEach(button => {
            button.addEventListener('click', function () {
                const parentWrap = button.closest('.contact_item_wrap');
                if (parentWrap) {
                    var phoneNumber = parentWrap.getAttribute('data-value');
                    if (phoneNumber) {
                        phoneNumber = phoneNumber.replace(/\D/g, ''); // 숫자만 추출
                        var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
                        var smsLink = isIOS ? `sms://${phoneNumber}` : `sms:${phoneNumber}`;
                        console.log(`메시지 보내기: ${smsLink}`);
                        window.location.href = smsLink;
                    } else {
                        alert('전화 번호가 입력되지 않았습니다.');
                    }
                }
            });
        });
    }

    // 텍스트박스 입력 이벤트 초기화 함수
    function initializeTextBoxInput() {
        const allTextBoxes = document.querySelectorAll('.TextBox');
        allTextBoxes.forEach(textBox => {
            textBox.addEventListener('input', function () {
                var inputValue = this.value;
                var parentWrap = this.closest('.checkselection_contacts_bride_item_wrap') || this.closest('.checkselection_contacts_groom_item_wrap');
                if (parentWrap) {
                    var dataIndex = parentWrap.getAttribute('data-index');
                    var dataType = parentWrap.classList.contains('checkselection_contacts_bride_item_wrap') ? 'bride' : 'groom';
                    var correspondingContactItem = document.querySelector(`.contact_item_wrap[data-index="${dataIndex}"][data-name="contact_${dataType}_groop"]`);
                    if (correspondingContactItem) {
                        correspondingContactItem.setAttribute('data-value', inputValue);
                    }
                }
            });
        });
    }

    // 서버에서 추가된 연락처 초기화 함수
    function initializeServerContacts() {
        const serverContacts = document.querySelectorAll('.checkselection_contacts_item');
        serverContacts.forEach(item => {
            const parentWrap = item.closest('.checkselection_contacts_bride_item_wrap') || item.closest('.checkselection_contacts_groom_item_wrap');

            // 삭제 버튼 클릭 이벤트 추가
            item.querySelector('.delete_contact_img').addEventListener('click', function () {
                item.remove();
                const dataIndex = parentWrap.getAttribute('data-index');
                const dataType = parentWrap.classList.contains('checkselection_contacts_bride_item_wrap') ? 'bride' : 'groom';
                const correspondingContactItem = document.querySelector(`.contact_item_wrap[data-index="${dataIndex}"][data-name="contact_${dataType}_groop"]`);
                if (correspondingContactItem) {
                    correspondingContactItem.remove();
                }
                parentWrap.remove();
                updateIndexes();
                updateContactButtonVisibility();
            });

            // 셀렉트박스 변경 이벤트 추가
            item.querySelector('.selectbox_contact').addEventListener('change', function () {
                var selectedValue = this.options[this.selectedIndex].value;
                const dataIndex = parentWrap.getAttribute('data-index');
                const dataType = parentWrap.classList.contains('checkselection_contacts_bride_item_wrap') ? 'bride' : 'groom';
                const correspondingContactItem = document.querySelector(`.contact_item_wrap[data-index="${dataIndex}"][data-name="contact_${dataType}_groop"]`);
                if (correspondingContactItem) {
                    correspondingContactItem.querySelector('.contact_item_info_relation').innerText = selectedValue;
                    updateContactName(dataType, dataIndex, selectedValue);
                }
            });
        });
    }

    initializeDeleteButtons();
    initializeContactButtons();
    initializeTextBoxInput();
    initializeServerContacts();

    // 초대장에 전화번호 클릭시 동작 함수 불러오기
    contacts();



}
export default template_coupleInfo;