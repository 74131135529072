// quillSetup.js

export function initializeQuill(editorId, targetId) {
    const quillEditor = document.getElementById(editorId);
    const targetElement = document.getElementById(targetId);

    if (quillEditor && targetElement) {
        const quill = new Quill(`#${editorId}`, {
            theme: 'snow',
            modules: {
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline', 'strike', 'link'],  // 텍스트 포맷 및 링크 삽입
                        [{ 'color': [] }, { 'background': [] }] // 텍스트 정렬 및 색상 선택
                    ],
                    handlers: {
                        link: function () {
                            const value = prompt('연결하실 링크 주소(URL)를 입력해주세요(ex.www.naver.com)');
                            if (value) {
                                const normalizedLink = value.startsWith('http://') || value.startsWith('https://')
                                    ? value
                                    : `https://${value}`;
                                this.quill.format('link', normalizedLink);
                            }
                        }
                    }
                }
            }
        });

        quill.on('text-change', () => {
            targetElement.innerHTML = quill.root.innerHTML;
        });
    }
}
