export function galleryTypeAdaption(dbInfo) {
    // grid-container 요소를 가져옵니다.
    const gridContainer = document.querySelector('.grid-container');

    setTimeout(() => {
        // grid-item 및 grid-thumb 요소를 현재 시점에 가져옵니다.
        const gridItems = document.querySelectorAll('.grid-item');
        const gridThumbs = document.querySelectorAll('.grid-thumb');

        if (dbInfo.gallery_type === 'MasonryType') {
            gridContainer.classList.add('MasonryType');
            gridItems.forEach(gridItem => gridItem.classList.add('MasonryTypeItem'));
            gridThumbs.forEach(gridThumb => gridThumb.classList.add('MasonryTypeThumb'));
        } else {
            gridContainer.classList.remove('MasonryType');
            gridItems.forEach(gridItem => gridItem.classList.remove('MasonryTypeItem'));
            gridThumbs.forEach(gridThumb => gridThumb.classList.remove('MasonryTypeThumb'));
        }

        // 디버깅용 로그
        console.log('Grid Items Class List after Timeout:', Array.from(gridItems).map(item => item.classList));
        console.log('Grid Thumbs Class List after Timeout:', Array.from(gridThumbs).map(thumb => thumb.classList));
    }, 100); // 100ms 지연 후 실행
}
