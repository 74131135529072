// mapFunctions.js
import { markers } from './marker.js';

export function initializeMap() {
    setupMapDivs();
    setupSearchFunctionality();
    setupNavigationButtons();
}

function setupMapDivs() {
    const mapDivs = document.querySelectorAll('.mapinfo');
    const mapOptions = {
        center: new kakao.maps.LatLng(37.359531, 127.105213),
        level: 3
    };

    mapDivs.forEach(mapDiv => {
        setTimeout(() => {
            const map = new kakao.maps.Map(mapDiv, mapOptions);
            const marker = new kakao.maps.Marker({
                position: map.getCenter(),
                draggable: true
            });
            marker.setMap(map);
            markers.push(marker);

            kakao.maps.event.addListener(marker, 'dragend', () => {
                updateMarkerPosition(marker, map);
            });

        }, 1500);
    });
}




// updateMarkerPosition 함수 export 추가
export function updateMarkerPosition(marker, map) {
    const position = marker.getPosition();
    console.log('updateMarkerPosition:', position); // 디버깅 로그 추가
    const latlng = new kakao.maps.LatLng(position.getLat(), position.getLng());

    // 모든 마커 위치를 새 위치로 업데이트하고, 각 마커에 연결된 지도의 중심도 조정
    markers.forEach(m => {
        const associatedMap = m.getMap();
        m.setPosition(latlng);
        associatedMap.setCenter(latlng);
    });

    // 마커 위치가 업데이트된 후 사용자에게 알림
    alert('마커 위치가 변경되었습니다.');

    // JSON 형식으로 좌표값을 콘솔에 출력
    console.log('좌표값 (JSON):', JSON.stringify({ lat: position.getLat(), lng: position.getLng() }));

    // 주소 검색 서비스를 사용하여 마커의 새 위치의 주소를 검색
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.coord2Address(latlng.getLng(), latlng.getLat(), function(result, status) {
        if (status === kakao.maps.services.Status.OK) {
            const newAddress = result[0].address.address_name;
            console.log('새 주소:', newAddress); // 새 주소 디버깅 로그
            const searchInput = document.getElementById('SearchAddressInput');
            const addressinfo = document.getElementById('WeddingAddress');
            searchInput.value = newAddress; // searchInput에 새 주소를 설정
            addressinfo.innerText = newAddress; // addressinfo 텍스트도 업데이트
        }
    });
}

function setupSearchFunctionality() {
    const searchInput = document.getElementById('SearchAddressInput');
    const searchBtn = document.getElementById('SearchAddressBtn');
    const addressinfo = document.getElementById('WeddingAddress');

    if (searchBtn) {
        searchBtn.addEventListener('click', () => {
            new daum.Postcode({
                oncomplete: function(data) {
                    searchInput.value = data.address;
                    addressinfo.innerText = data.address;
                    searchAddress(data.address);
                }
            }).open();
        });
    }
}

function searchAddress(address) {
    console.log('searchAddress 호출됨, 검색 주소:', address); // 디버깅 로그 추가
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(address, function(result, status) {
        console.log('addressSearch 결과:', status, result); // 디버깅 로그 추가
        if (status === kakao.maps.services.Status.OK) {
            const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
            markers.forEach(marker => {
                const map = marker.getMap();
                marker.setPosition(coords);
                map.setCenter(coords);
            });
        }
    });
}

function setupNavigationButtons() {
    setupNaverNavigation();
    setupKakaoNavigation();
}

function setupNaverNavigation() {
    const naverNav = document.getElementById('NaverNav');
    if (naverNav) {
        naverNav.addEventListener('click', () => {
            openNavigationApp('naver', markers[0]);
        });
    }
}

function setupKakaoNavigation() {
    const kakaoNav = document.getElementById('KakaoNav');
    if (kakaoNav) {
        kakaoNav.addEventListener('click', () => {
            openNavigationApp('kakao', markers[0]);
        });
    }
}

function openNavigationApp(type, marker) {
    console.log(`openNavigationApp 호출됨, 타입: ${type}, 마커:`, marker); // 디버깅 로그 추가
    const geocoder = new kakao.maps.services.Geocoder();
    const position = marker.getPosition();
    console.log('현재 마커 위치:', position); // 디버깅 로그 추가
    const lat = position.getLat();
    const lng = position.getLng();

    geocoder.coord2Address(lng, lat, (result, status) => {
        console.log('coord2Address 결과:', status, result); // 디버깅 로그 추가
        if (status === kakao.maps.services.Status.OK) {
            const address = result[0].address.address_name;
            console.log(`주소 변환 결과: ${address}`); // 디버깅 로그 추가
            let url;
            if (type === 'naver') {
                url = `https://map.naver.com/v5/search/${encodeURIComponent(address)}`;
            } else if (type === 'kakao') {
                url = `https://map.kakao.com/link/search/${encodeURIComponent(address)}`;
            }
            window.open(url);
        } else {
            alert('주소를 찾을 수 없습니다.');
        }
    });
}
