import { toggleVideo } from "./template_video_upload_modules/toggle_video";
import { upload_video_list } from "./template_video_upload_modules/upload_video_list";
import { upload_video_thumbnail } from "./template_video_upload_modules/upload_video_thumbnail";

export function template_video_upload(dynamicValue) {

    toggleVideo(dynamicValue); // 비디오 On/off 토글 모듈
    upload_video_list(dynamicValue); // 비디오 업로드시, 리스트에 추가 모듈
    upload_video_thumbnail(dynamicValue); //비디오 썸네일 등록&삭제 모듈

}

