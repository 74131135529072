// designTabModule.js
function selectDesignTabs() {
    const tabs = document.querySelectorAll('ul.TabListDesign li');
    const sections = document.querySelectorAll('.TitleSection');

    // Active탭을 제외한 모든 섹션 숨기기
    sections.forEach(section => section.style.display = 'none');

    tabs.forEach(tab => {
        tab.addEventListener('click', function() {
            // 모든 탭의 'Active' 클래스 제거
            tabs.forEach(item => item.classList.remove('Active'));

            // 현재 탭에 'Active' 클래스 추가
            this.classList.add('Active');

            // 모든 섹션 숨기기
            sections.forEach(section => section.style.display = 'none');

            // 연관된 섹션 찾아 표시
            const relatedSectionId = `Template_${this.id}`;
            const relatedSection = document.getElementById(relatedSectionId);
            if (relatedSection) {
                relatedSection.style.display = 'block';
            }
        });
    });
}

export { selectDesignTabs };
