export function template_design_data(dynamicValue) {

    // 그래픽 색상 값을 가져오는 부분
    let color_graphic = document.getElementById('color_selector').value;

    let opacity_bg = document.getElementById('range').value;

    let titleImg_frameEffect = document.querySelector('.titleImg_item_contents_item_button_effect.active').dataset.value;

    // 청첩장 DB 저장을 위한 데이터 객체 생성
    let template_design_data = {
        graphic_color: color_graphic,
        bg_opacity: opacity_bg,
        titleImg_frameEffect: titleImg_frameEffect
    }

    // 데이터 객체를 반환
    return template_design_data;
}
